import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import Mobile from "./mobileNav";

export default function MenuComponent() {
  return (
    <Header>
      <Logo to="/">
        <img src="/images/logo.png" alt="로고" />
      </Logo>
      <Menu />
    </Header>
  );
}

function Menu() {
  return (
    <>
      <NavContainer>
        <ul>
          <CustomLink url="/" title="home" />
          <CustomLink url="/introduce" title="컨텐츠소개" />
          <CustomLink url="/shop" title="이용권/교재" />
          <CustomLink url="/qna" title="고객센터" />
        </ul>
      </NavContainer>
      <Mobile />
    </>
  );
}

function CustomLink(props: { url: string; title: string }) {
  const location = useLocation();
  const active = location.pathname === props.url;

  return (
    <li>
      <NavMenu to={props.url} className={active ? "tab-menu active" : "tab-menu"}>
        {props.title}
      </NavMenu>
    </li>
  );
}

const Header = styled.div`
    height: 100px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: 768px) {
      padding: 0;
      width: 100%;
      height: 60px;
      position: relative;
    }
  `,
  Logo = styled(Link)`
    z-index: 999;
    position: absolute;
    left: 0;
    width: 25%;
    height: 100%;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media (max-width: 985px) {
      width: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  NavContainer = styled.nav`
    position: absolute;
    right: 0;
    bottom: 0;
    & > ul > li {
      display: inline-block;
      float: left;
    }
    @media (max-width: 985px) {
      display: none;
    }
  `,
  NavMenu = styled(Link)`
    display: block;
    padding: 15px 15px 35px 15px;
    position: relative;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 50px;
    color: #000;
    &:after {
      content: "";
      width: 0px;
      height: 4px;
      background-color: #5682fe;
      border-radius: 2px;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
    }
    &.active,
    &:hover {
      &:after {
        content: "";
        width: 70px;
      }
      &:first-of-type:after {
        content: "";
        width: 40px;
      }
      &:nth-of-type(3):after {
        content: "";
        width: 75px;
      }
      &:nth-of-type(4):after {
        content: "";
        width: 55px;
      }
    }
  `;
