import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export default function findIdPw() {
  return (
    <Section>
      <Container>
        <Form>
          <Title>아이디/비밀번호 찾기</Title>
          <InnerContainer>
            <Link to={"/findid"}>아이디 찾기</Link>
            <Link to={"/findpassword"}>비밀번호 찾기</Link>
          </InnerContainer>
          <SignUpContainer>
            <p>이미 회원이신가요?</p>
            <Link to="/sign/in">로그인 하기</Link>
          </SignUpContainer>
        </Form>
      </Container>
    </Section>
  );
}

const Section = styled.div`
    width: 100%;
    font-family: S-CoreDream-5Medium, sans-serif;
    min-height: 600px;
    position: relative;
  `,
  Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 1128px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 86px 0;
  `,
  Form = styled.div`
    padding: 46px 60px 36px 61px;
    border-radius: 5px;
    box-shadow: -7px 7px 7px 0 rgb(0 0 0 / 5%);
    border: 1px solid #ededef;
    background-color: #fff;
  `,
  Title = styled.h2`
    color: #303030;
    font-size: 17px;
    text-align: center;
  `,
  InnerContainer = styled.div`
    display: flex;
    & > a {
      margin-top: 10px;
      padding: 20px 40px;
      border: 1px solid black;
      text-align: center;
      background: none;
      color: black;
      &:last-of-type {
        margin-left: 10px;
      }
    }
  `,
  SignUpContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    & > a,
    button {
      font-size: 16px;
      border: none;
      background: none;
      color: #53b7f4;
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }
  `;
