import styled from "@emotion/styled";
import axios from "axios";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { landList, aesopList, dictList } from "./list";
import { CDN } from "../common/functions";

let record: never[] = [];
dayjs.extend(weekOfYear);

export default function MypageComponent() {
  const navigate = useNavigate();

  const [display, setDisplay] = useState("none");
  const [background, setBackground] = useState({});
  const [summary, setSummary] = useState({
    totalTime: 0,
    monthlyTotal: 0,
    weeklyTotal: 0,
  });
  const [content, setContent] = useState({
    title: "전체",
    time: 0,
    image: "https://q5tztmsi917.edge.naverncp.com/images/intro/title/all3.png",
  });

  useEffect(() => {
    axios
      .get("/api/content/record")
      .then((res: any) => {
        record = res.data;
        setContent({
          ...content,
          time: sumArray(record, undefined),
        });
        setSummary({
          ...summary,
          totalTime: sumArray(record, undefined),
          monthlyTotal: sumArray(record, { month: dayjs().month() + 1, year: dayjs().year() }),
          weeklyTotal: sumArray(record, { week: dayjs().week(), year: dayjs().year() }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header>
        <MenuContainer>
          <MenuButton
            onClick={() => {
              navigate("/lms/record");
            }}>
            학습기록
          </MenuButton>
          {/* <MenuButton
            onClick={() => {
              navigate("/lms/ranking");
            }}>
            전체랭킹
          </MenuButton> */}
        </MenuContainer>
        <Close
          onClick={() => {
            navigate("/lms/intro");
          }}>
          <img src={CDN + "/images/intro/assets/close.png"} alt="menu" />
        </Close>
      </Header>
      <Main>
        <MainContainer>
          <ContentComponent>
            <div style={background}>
              <SelectComponent
                onClick={() => {
                  if (display === "none") {
                    setDisplay("block");
                    setBackground({
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    });
                  } else if (display === "block") {
                    setDisplay("none");
                    setBackground({});
                  }
                }}>
                {content.title}
              </SelectComponent>
              <OptionsComponent style={{ display: display }}>
                <div>
                  <div>
                    <OptionTitle>코스</OptionTitle>
                    <Options
                      onClick={() => {
                        setContent({
                          title: "전체",
                          time: sumArray(record, undefined),
                          image:
                            "https://q5tztmsi917.edge.naverncp.com/images/intro/title/all3.png",
                        });
                        if (display === "none") {
                          setDisplay("block");
                          setBackground({
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          });
                        } else if (display === "block") {
                          setDisplay("none");
                          setBackground({});
                        }
                      }}>
                      전체
                    </Options>
                  </div>
                  <div>
                    {optionsList(
                      landList,
                      "랜드시리즈",
                      "land",
                      setContent,
                      display,
                      setDisplay,
                      setBackground
                    )}
                    {optionsList(
                      aesopList,
                      "이솝우화 시리즈",
                      "aesop",
                      setContent,
                      display,
                      setDisplay,
                      setBackground
                    )}
                    {optionsList(
                      dictList,
                      "애니메이션사전 시리즈",
                      "dict",
                      setContent,
                      display,
                      setDisplay,
                      setBackground
                    )}
                  </div>
                </div>
              </OptionsComponent>
            </div>
            <div>
              <ImageComponent src={content.image} />
            </div>
            <div>{content.time}분</div>
          </ContentComponent>
          <ContentComponent>
            <p>총 학습 시간</p>
            <p>{summary.totalTime}분</p>
          </ContentComponent>
          <ContentComponent>
            <div>
              <p>주간 학습 시간</p>
              <p>{summary.weeklyTotal}분</p>
            </div>
            <div>
              <p>월간 학습 시간</p>
              <p>{summary.monthlyTotal}분</p>
            </div>
          </ContentComponent>
        </MainContainer>
      </Main>
    </>
  );
}

function optionsList(
  list: any,
  title: string,
  mode: string,
  setContent: any,
  display: any,
  setDisplay: any,
  setBackground: any
) {
  let index = 0;

  const handleClick = (id: string, image: any) => {
    if (display === "none") {
      setDisplay("block");
      setBackground({
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      });
    } else if (display === "block") {
      setDisplay("none");
      setBackground({});
    }
  };
  const handleClickTitle = (id: string) => {
    const element = document.getElementById(id);
    if (element?.style.display === "none") {
      element.style.display = "block";
    } else if (element?.style.display === "block") {
      element.style.display = "none";
    }
  };

  const Component = [
    <OptionTitle
      key={++index}
      onClick={() => {
        handleClickTitle(mode);
      }}>
      {title}
    </OptionTitle>,
  ];

  Component.push(
    <div key={++index} id={mode} style={{ display: "block" }}>
      {list.map((element: any) => {
        index++;
        return (
          <Options
            onClick={() => {
              handleClick(mode, element);
              const time = sumArray(record, element);
              setContent({
                title: element.title,
                time,
                image: "https://q5tztmsi917.edge.naverncp.com/images/intro/" + element.image,
              });
            }}
            key={index}>
            {element.title}
          </Options>
        );
      })}
    </div>
  );

  return Component;
}

//filter element in array condition with object options
function filterArray(array: any, options: any) {
  let result = array;
  if (options === undefined) {
    //전체합산
    return result;
  } else if (options.week !== undefined && options.year !== undefined) {
    //해당주 합산
    result = result.filter((element: any) => {
      return element.week === options.week && element.year === options.year;
    });
  } else if (options.month !== undefined && options.year !== undefined) {
    //해당월 합산
    result = result.filter((element: any) => {
      return element.month === options.month && element.year === options.year;
    });
  } else if (options.all) {
    //같은 시리즈 합산
    result = result.filter((element: any) => {
      return (
        element.contentId >= options.contents[0] &&
        element.contentId <= options.contents[1] &&
        element.language === options.language
      );
    });
  } else if (!options.all) {
    //해당 컨텐츠만 가져오기
    result = result.filter((element: any) => {
      return element.contentId === options.content && element.language === options.language;
    });
  }
  return result;
}

/**
 * 필터링 된 학습시간을 구하는 함수
 * @param array 원본 배열
 * @param options 조건
 * @returns 학습기간 총합
 */
function sumArray(array: any, options: any) {
  let sum = 0;
  const result = filterArray(array, options);
  result.forEach((element: any) => {
    sum += element.time;
  });
  return sum;
}

const Header = styled.div`
    width: 100%;
    height: calc(0.1625 * var(--app-height));
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  MenuContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin-left: calc(0.0275 * var(--app-height));
    height: 100%;
  `,
  MenuButton = styled.div`
    background: #2581b3;
    color: #fff;
    border-top-left-radius: calc(0.04625 * var(--app-height));
    border-top-right-radius: calc(0.04625 * var(--app-height));
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(0.0925 * var(--app-height));
    padding: calc(0.0375 * var(--app-height));
    font-size: calc(0.02963 * var(--app-height));
    margin-right: calc(0.0125 * var(--app-height));
    font-family: NanumSquareRound;
    font-weight: 800;
    cursor: pointer;
  `,
  Close = styled.button`
    width: calc(0.11875 * var(--app-height));
    height: calc(0.11875 * var(--app-height));
    margin-right: calc(0.025 * var(--app-height));
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 2;
    display: flex;
    flex-flow: column;
    font-size: calc(0.02375 * var(--app-height));
    font-family: Nanumsquare;
    background: none;
    color: var(--color-text2);
    align-items: center;
    text-transform: uppercase;
  `,
  Main = styled.div`
    height: calc(0.8375 * var(--app-height));
    background: #2581b3;
  `,
  MainContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
  ContentComponent = styled.div`
    width: calc(0.54583 * var(--app-height));
    display: flex;
    flex-direction: column;
    border-radius: calc(0.05556 * var(--app-height));
    padding: calc(0.03125 * var(--app-height));
    height: calc(0.68542 * var(--app-height));
    align-self: center;
    margin-right: calc(0.02292 * var(--app-height));
    &:first-of-type {
      background-color: #275c87;
      & > div {
        &:first-of-type {
          z-index: 1;
          position: relative;
          display: inline-block;
          cursor: default;
          background: #fff;
          border-radius: calc(0.03472 * var(--app-height));
        }
        &:nth-of-type(2) {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          flex: 1;
          & > img {
            width: calc(0.27917 * var(--app-height));
          }
        }
        &:last-of-type {
          position: relative;
          width: 100%;
          height: calc(0.06875 * var(--app-height));
          line-height: 2;
          border-radius: calc(0.03472 * var(--app-height));
          background-color: #0c9aa1;
          overflow: hidden;
          text-align: center;
          color: #fff;
          font-family: NanumSquare;
          font-size: calc(0.03125 * var(--app-height));
          font-weight: 800;
        }
      }
    }
    &:nth-of-type(2) {
      display: inline-flex;
      flex-direction: column;
      width: calc(0.51319 * var(--app-height));
      border-radius: calc(0.05556 * var(--app-height));
      color: #0c9aa1;
      background-color: #dbffec;
      text-align: center;
      & > p:first-of-type {
        font-family: NanumSquareRound;
        font-weight: 800;
        font-size: calc(0.03819 * var(--app-height));
        margin-top: calc(0.04653 * var(--app-height));
        margin-bottom: calc(0.04306 * var(--app-height));
      }
      & > p:last-of-type {
        font-family: Recipekorea;
        font-size: calc(0.05903 * var(--app-height));
        margin-bottom: calc(0.04861 * var(--app-height));
      }
    }
    &:nth-of-type(3) {
      display: inline-flex;
      flex-direction: column;
      width: calc(0.47639 * var(--app-height));
      margin-right: 0;
      background-color: #fcffa1;
      color: #b57448;
      text-align: center;
      & > div {
        & > p:first-of-type {
          font-family: NanumSquareRound;
          font-weight: 800;
          font-size: calc(0.03819 * var(--app-height));
          margin-top: calc(0.04653 * var(--app-height));
          margin-bottom: calc(0.04306 * var(--app-height));
        }
        & > p:last-of-type {
          font-family: Recipekorea;
          font-size: calc(0.05903 * var(--app-height));
          margin-bottom: calc(0.04861 * var(--app-height));
        }
      }
    }
  `,
  SelectComponent = styled.div`
    font-family: NanumSquare;
    font-size: calc(0.03125 * var(--app-height));
    font-weight: 800;
    position: relative;
    z-index: 2;
    height: calc(0.06875 * var(--app-height));
    background-color: #0acfb5;
    border-radius: calc(0.03472 * var(--app-height));
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: calc(0.02778 * var(--app-height));
    &:after {
      content: "";
      cursor: pointer;
      position: absolute;
      width: calc(0.03194 * var(--app-height));
      height: calc(0.02083 * var(--app-height));
      right: calc(0.02604 * var(--app-height));
      background: url("../images/ic_arrow_down.svg") 50% no-repeat;
      background-size: contain;
      transition: transform 0.3s;
    }
  `,
  OptionsComponent = styled.div`
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(0.55556 * var(--app-height));
    text-align: left;
    border-radius: 0 0 calc(0.03472 * var(--app-height)) calc(0.03472 * var(--app-height));
    background-color: #fff;
    padding: calc(0.01806 * var(--app-height)) calc(0.01111 * var(--app-height))
      calc(0.01806 * var(--app-height)) 0;
    z-index: 1;
    font-weight: 800;
    & > div {
      overflow-y: auto;
      height: 100%;
      padding: 0 calc(0.01528 * var(--app-height));
      font-family: NanumsquareRound;
      font-weight: 700;
    }
  `,
  OptionTitle = styled.div`
    font-size: calc(0.03472 * var(--app-height));
    color: #275c87;
    border-bottom: calc(0.00264 * var(--app-height)) solid #eaf0f4;
    margin-bottom: calc(0.00833 * var(--app-height));
    position: relative;
    &:after {
      content: "";
      cursor: pointer;
      position: absolute;
      width: calc(0.03194 * var(--app-height));
      height: calc(0.02083 * var(--app-height));
      right: calc(0.02604 * var(--app-height));
      top: calc(0.01604 * var(--app-height));
      background: url("../images/ic_arrow_down_list.svg") 50% no-repeat;
      background-size: contain;
      transition: transform 0.3s;
    }
  `,
  Options = styled.div`
    font-size: calc(0.02778 * var(--app-height));
    color: #95b1c9;
    padding: calc(0.00833 * var(--app-height)) calc(0.01528 * var(--app-height));
    cursor: pointer;
    &:hover {
      background-color: #275c87;
      border-radius: calc(0.02083 * var(--app-height));
    }
  `,
  ImageComponent = styled.img`
    border-radius: 50%;
  `;
