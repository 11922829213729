import styled from "@emotion/styled";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";

export default function Navbar() {
  const [isClicked, setIsClicked] = useState([true, false, false, false]);
  const [content, setContent] = useState([]);
  useEffect(() => {
    let index: number = isClicked.findIndex((element: boolean) => element === true);
    setContent(list[index]);
  }, [isClicked]);

  const component = (
    <>
      <Menu>
        <IconContainer isClicked={isClicked} setIsClicked={setIsClicked}></IconContainer>
      </Menu>
      <Section>
        <ListContainer content={content} />
      </Section>
    </>
  );
  return component;
}

function IconContainer(props: any) {
  const icons = [<VideoIcon />, <CardIcon />, <UserIcon />];
  const texts = ["학습컨텐츠", "결제", "계정"];

  const handleClick = (index: number) => {
    const temp = [false, false, false];
    temp[index] = true;
    props.setIsClicked(temp);
  };

  return (
    <>
      {icons.map((icon, index) => {
        return (
          <IconButton
            key={index}
            onClick={() => {
              handleClick(index);
            }}>
            <IconComponent
              style={{
                border: props.isClicked[index] ? "2px solid var(--color-hover)" : "",
              }}>
              {icon}
            </IconComponent>
            <TextContainer
              style={{
                color: props.isClicked[index] ? "var(--color-hover)" : "",
              }}>
              {texts[index]}
            </TextContainer>
          </IconButton>
        );
      })}
    </>
  );
}

function ListContainer(props: any) {
  return (
    <List>
      {props.content.map((item: any, index: number) => {
        return Content({ index, question: item.question, answer: item.answer });
      })}
    </List>
  );
}

const list: any = [
  [
    {
      question: <h4>학습 연령은 어떻게 되나요?</h4>,
      answer: (
        <p>
          애프터스쿨 적정 학습 연령은 유치원부터 초등학교 6학년까지이지만, 언어 수준에
          따라학습하고자 하는 누구나 자유롭게 학습할 수 있습니다.
        </p>
      ),
    },
    {
      question: <h4>자료실은 어디있나요?</h4>,
      answer: <p>자료실은 학습컨텐츠 우측 상단 메뉴에서 확인하실 수 있습니다.</p>,
    },
    {
      question: <h4>학습기록은 어디서 볼 수 있나요?</h4>,
      answer: <p>학습기록은 학습컨텐츠 우측 상단 메뉴에서 확인하실 수 있습니다.</p>,
    },
  ],
  [
    {
      question: <h4>결제는 어떻게 하나요?</h4>,
      answer: <p>결제는 카드결제/계좌이체만 가능합니다.</p>,
    },
    {
      question: <h4>환불은 어떻게 하나요?</h4>,
      answer: <p>환불신청은 카카오톡 채널을 통해 가능합니다.</p>,
    },
    {
      question: <h4>교재,교구 환불/교환 안내</h4>,
      answer: (
        <p>
          단순 변심의 경우 수령일부터 7일 이내까지 교환/환불이 가능합니다,
          <br />
          사용 한 경우 교환/환불이 불가능합니다.
        </p>
      ),
    },
    {
      question: <h4>회원권 환불/교환 안내</h4>,
      answer: (
        <p>
          잔여 이용료는 할인전 전체 이용료에서 개월수로 나누고, 하루라도 이용한 월을 제외하고 남은
          금액을 환불합니다.
          <br />
          예: 4개월 이용권 구매후 2개월 환불시, 120,000원-80,000원(2개월 할인전 이용금액) = 40,000원
          환불
        </p>
      ),
    },
  ],
  [
    {
      question: <h4>자녀프로필은 몇개까지 만들 수 있나요?</h4>,
      answer: <p>자녀프로필은 총 3개까지 만들수있습니다.</p>,
    },
    {
      question: <h4>학원이나 공부방에서는 어떻게 가입하나요?</h4>,
      answer: <p>단체회원은 카카오톡으로 문의하시면 됩니다.</p>,
    },
  ],
];

function Content(props: any) {
  const handleClick = () => {
    if (props.index === 0) {
      document.getElementById(`answer${props.index}`)?.classList.toggle("active");
    } else {
      document.getElementById(`answer${props.index}`)?.classList.toggle("active");
    }
  };

  return (
    <ListComponent key={props.index} onClick={handleClick}>
      {props.question}
      <DownIcon />
      <AnswerContainer id={"answer" + props.index}>{props.answer}</AnswerContainer>
    </ListComponent>
  );
}

const Menu = styled.div`
    justify-content: space-evenly;
    width: 100%;
    height: 360px;
    display: flex;
    justify-items: center;
    align-items: center;
  `,
  IconButton = styled.button`
    width: 104px;
    height: 146px;
    display: grid;
    grid-template-rows: 3fr 1fr;
    grid-template-columns: 1fr;
    cursor: pointer;
    background: #fff;
    &:hover {
      & > div {
        &:first-of-type {
          border: 2px solid var(--color-hover);
        }
        &:last-of-type {
          color: var(--color-hover);
        }
      }
    }
    @media (max-width: 768px) {
      width: 80px;
      height: 110px;
      & > div {
        font-size: 16px;
      }
    }
  `,
  IconComponent = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    border: 1px solid #d4d4d4;
    background-color: #fff;
    color: var(--color-hover);
  `,
  TextContainer = styled.div`
    font-family: NanumSquare;
    font-size: 20px;
    font-weight: 800;
    line-height: 2.1;
    text-align: center;
    color: #797979;
  `,
  VideoIcon = styled(OndemandVideoIcon)`
    width: 80%;
    height: 80%;
    margin-top: 12px;
  `,
  CardIcon = styled(CreditCardIcon)`
    width: 80%;
    height: 80%;
    margin-top: 10px;
  `,
  UserIcon = styled(PersonIcon)`
    width: 80%;
    height: 80%;
    margin-top: 5px;
  `,
  Section = styled.div`
    justify-content: space-evenly;
    width: 100%;
    justify-items: center;
    align-items: center;
  `,
  List = styled.ul`
    max-width: 975px;
    width: 100%;
    margin: 0 auto;
    border-top: 0.5px solid #e6e6e6;
    border-bottom: 0.5px solid #e6e6e6;
  `,
  ListComponent = styled.li`
    cursor: pointer;
    border-top: 0.5px solid #e6e6e6;
    border-bottom: 0.5px solid #e6e6e6;
    width: 975px;
    height: auto;
    justify-items: center;
    align-items: center;
    padding: 30px 0;
    position: relative;
    @media (max-width: 975px) {
      width: 100%;
      & > h4 {
        font-size: 20px;
      }
    }
  `,
  DownIcon = styled(ExpandMoreIcon)`
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 50px;
  `,
  AnswerContainer = styled.div`
    min-width: 100%;
    border-radius: 12px;
    margin-top: 23px;
    padding: 10px 20px;
    background-color: #f7f7f7;
    display: none;
    &.active {
      display: block;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  `;
