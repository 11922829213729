import { useState } from "react";
import styled from "@emotion/styled";

export default function HowComponent(props: any) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div
      className={
        props.index % 2 === 0
          ? `${clicked ? "on" : ""} left`
          : `${clicked ? "on" : ""} right
  `
      }
      key={props.index}
      onClick={handleClick}>
      <p>{props.index + 1}단계</p>
      <img src={props.item.img} alt="캐릭터" />
      <TextComponent>{props.item.content}</TextComponent>
    </div>
  );
}

const TextComponent = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  display: none;
  & > p {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }
`;
