import styled from "@emotion/styled";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { CDN } from "./functions";

export default function HeaderComponent() {
  const [menuState, setMenuState] = useState(false);
  const [title, setTitle] = useState("홈");
  const path = window.location.pathname;
  const [studentName, setStudentName] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const cookies = new Cookies();
  const navigate = useNavigate();
  let aside: any, dim: any, backbtn: any;

  if (!window.location.pathname.includes("intro")) {
    backbtn = (
      <BackButton
        onClick={function () {
          navigate(-1);
        }}></BackButton>
    );
  }

  useEffect(() => {
    if (path.includes("intro")) {
      setTitle("전체컨텐츠");
    } else if (Number(searchParams.get("sid")) === 1) {
      setTitle("씨랜드");
    } else if (Number(searchParams.get("sid")) === 2) {
      setTitle("스윗랜드");
    } else if (Number(searchParams.get("sid")) === 3) {
      setTitle("리빙랜드");
    } else if (Number(searchParams.get("mid")) === 2) {
      setTitle("랜드시리즈");
    } else if (Number(searchParams.get("mid")) === 3) {
      setTitle("이솝우화");
    } else if (Number(searchParams.get("mid")) === 4) {
      setTitle("애니메이션 사전");
    } else if (Number(searchParams.get("mid")) === 5) {
      setTitle("짱구한자");
    } else if (Number(searchParams.get("mid")) === 6) {
      setTitle("고고의 신나는 세계여행");
    } else if (Number(searchParams.get("mid")) === 7) {
      setTitle("뽀뽀와 구루몽의 신나는 중국어");
    } else if (Number(searchParams.get("mid")) === 8) {
      setTitle("노래");
    }
  }, []);

  useEffect(() => {
    if (!document.cookie) {
      axios
        .get("/api/lms/student/check")
        .then((res) => {
          if (res.data) {
            axios.get("/api/lms/student/get").then((res) => {
              setStudentName(res.data.name);
              cookies.set("studentName", res.data.name);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setStudentName(cookies.get("studentName"));
    }
  }, []);

  if (menuState) {
    aside = (
      <Aside>
        <MenuButton
          onClick={function () {
            setMenuState(!menuState);
          }}>
          <img src={CDN + "/images/intro/assets/close.png"} alt="menu" />
        </MenuButton>
        <PageLink to="/lms/record">
          <MenuButton>
            <img src={CDN + "/images/intro/assets/record.png"} alt="menu" />
            학습기록
          </MenuButton>
        </PageLink>
        <PageLink to="/lms/reference">
          <MenuButton>
            <img src={CDN + "/images/intro/assets/download.png"} alt="menu" />
            자료실
          </MenuButton>
        </PageLink>
        <PageLink to="/lms/intro">
          <MenuButton>
            <img src={CDN + "/images/intro/assets/home.png"} alt="menu" />
            처음으로
          </MenuButton>
        </PageLink>
        <PageLink to="/">
          <MenuButton>
            <img src={CDN + "/images/intro/assets/signout.png"} alt="menu" />
            나가기
          </MenuButton>
        </PageLink>
      </Aside>
    );
    dim = (
      <Dim
        onClick={function () {
          setMenuState(!menuState);
        }}></Dim>
    );
  }

  return (
    <Container>
      <Header>
        <UserContainer>
          {backbtn}
          <UserButton
            onClick={() => {
              navigate("/lms/mypage");
            }}>
            <UserImg src={CDN + "/images/intro/assets/user.png"}></UserImg>
            <UserTextContainer>
              <UserText>{studentName}</UserText>
              <UserText>마이페이지</UserText>
            </UserTextContainer>
          </UserButton>
        </UserContainer>
        <CurrentModeContainer>
          <p>{title}</p>
        </CurrentModeContainer>
        <MenuContainer>
          <MenuOpenButton
            onClick={function () {
              setMenuState(!menuState);
            }}>
            <img src={CDN + "/images/intro/assets/menu.png"} alt="menu" />
          </MenuOpenButton>
        </MenuContainer>
      </Header>
      {aside}
      {dim}
    </Container>
  );
}

const Container = styled.div`
    padding: calc(0.02625 * var(--app-height)) calc(0.0275 * var(--app-height))
      calc(0.02625 * var(--app-height)) calc(0.02625 * var(--app-height));
    flex: 1;
    z-index: 2;
    transition: z-index 0.25s ease-out;
  `,
  Header = styled.header`
    display: flex;
    justify-content: space-between;
    user-select: none;
    box-shadow: none;
    background: none;
  `,
  UserContainer = styled.div`
    display: flex;
  `,
  UserButton = styled.button`
    min-width: calc(0.30125 * var(--app-height));
    border-radius: calc(0.0475 * var(--app-height));
    padding: calc(0.01375 * var(--app-height));
    height: calc(0.11875 * var(--app-height));
    font-family: Recipekorea;
    background: var(--color-white);
    display: flex;
    align-items: center;
    color: var(--color-white);
    position: relative;
    box-align: center;
    &:nth-of-type(n + 2) {
      margin-left: calc(0.02625 * var(--app-height));
    }
  `,
  UserImg = styled.img`
    width: calc(0.0925 * var(--app-height));
    height: calc(0.0925 * var(--app-height));
  `,
  UserTextContainer = styled.div`
    margin: calc(0.0025 * var(--app-height)) 0 calc(0.0025 * var(--app-height))
      calc(0.01625 * var(--app-height));
    height: calc(0.0875 * var(--app-height));
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
  `,
  UserText = styled.p`
    margin: calc(0.0125 * var(--app-height)) 0 calc(0.01075 * var(--app-height));
    font-size: calc(0.01875 * var(--app-height));
    height: calc(0.02175 * var(--app-height));
    font-weight: 900;
    color: var(--color-text1);
    text-align: left;
    font-family: NanumSquare;
  `,
  CurrentModeContainer = styled.div`
    min-width: calc(0.255 * var(--app-height));
    height: calc(0.07 * var(--app-height));
    padding: calc(0.0075 * var(--app-height)) calc(0.0375 * var(--app-height));
    padding-bottom: calc(0.01875 * var(--app-height));
    border-bottom-left-radius: calc(0.04 * var(--app-height));
    border-bottom-right-radius: calc(0.04 * var(--app-height));
    font-size: calc(0.02875 * var(--app-height));
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background-color: var(--color-content2bg);
    box-sizing: border-box;
    font-family: Recipekorea;
    color: var(--color-white);
    text-transform: uppercase;
  `,
  MenuContainer = styled.div`
    display: flex;
  `,
  MenuOpenButton = styled.button`
    width: calc(0.11875 * var(--app-height));
    height: calc(0.11875 * var(--app-height));
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 2;
  `,
  Aside = styled.aside`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background: var(--color-content3bg);
    z-index: 2;
    padding: calc(0.026 * var(--app-height)) calc(0.0275 * var(--app-height))
      calc(0.03875 * var(--app-height));
    display: grid;
    -webkit-box-align: start;
    align-items: start;
    grid-template-columns: auto;
  `,
  MenuButton = styled.button`
    width: calc(0.11875 * var(--app-height));
    height: calc(0.11875 * var(--app-height));
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 2;
    display: flex;
    flex-flow: column;
    font-size: calc(0.02375 * var(--app-height));
    font-family: Nanumsquare;
    background: none;
    color: var(--color-text2);
    align-items: center;
    text-transform: uppercase;
  `,
  PageLink = styled(Link)``,
  Dim = styled.div`
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
  BackButton = styled.button`
    width: calc(0.09625 * var(--app-height));
    height: calc(0.11875 * var(--app-height));
    background-image: url("https://q5tztmsi917.edge.naverncp.com/images/intro/assets/back.png");
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
  `;
