import styled from "@emotion/styled";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DecoratePrice } from "../common/functions";
import nicepayStart from "../common/nicepay";
import receipt from "./receipt";

export default function TotalContainer(cart: any) {
  const navigate = useNavigate();
  const [form, setForm] = useState(<></>);

  const HandleClick = (e: any) => {
    e.preventDefault();
    setForm(receipt(cart));
  };

  useEffect(() => {
    const payForm: any = document.forms[0];

    if (!form.props.children) return;
    if (cart.length === 0) {
      return navigate("/shop");
    } else if (countMembership > 1) {
      alert("이용권은 1개만 구매가능합니다");
    } else if (payForm.PayMethod.value === "CARD") {
      axios
        .post("/api/shop/saveAddress", {
          userZipcode: payForm.postcode.value,
          userAddress1: payForm.roadAddress.value,
          userAddress2: payForm.address.value,
        })
        .then((res) => {
          if (res.data === "success") nicepayStart();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Bank(payForm);
    }
  }, [form]);

  const Bank = (payForm: any) => {
    axios
      .post("/api/shop/create-order", {
        payment: "bank",
        status: 1,
        total: payForm.Amt.value,
        depositor: payForm.depositor.value,
        userName: payForm.BuyerName.value,
        userPhone: payForm.BuyerTel.value,
        userZipcode: payForm.postcode.value,
        userAddress1: payForm.roadAddress.value,
        userAddress2: payForm.address.value,
      })
      .then((res) => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const countMembership = cart.filter((product: any) => product.days > 100).length;

  let button = "";
  if (cart.length > 0) {
    button = "결제하기";
  } else if (countMembership > 1) {
    button = "이용권은 1개만 구매가능합니다";
  } else {
    button = "장바구니가 비어있습니다, 상품을 추가해주세요";
  }

  const onChangePayMethod = (e: any) => {
    const account = document.getElementById("account") as HTMLElement;
    const depositor = document.getElementById("depositor") as HTMLInputElement;
    if (e.target.value === "bank") {
      account.style.display = "block";
      depositor.required = true;
    } else {
      account.style.display = "none";
      depositor.required = false;
    }
  };

  const Amt = cart
    .map((product: any) => {
      return product.price;
    })
    .reduce((previousValue: number, currentValue: number) => previousValue + currentValue, 0);

  return (
    <CartContainer>
      <TotalComponent>
        <div>
          <span>상품: {cart.length}개</span>
        </div>
        <div>
          <span>
            총금액:
            {DecoratePrice(Amt)}원
          </span>
        </div>

        <Payment>
          <div>
            <input
              type="radio"
              id="CARD"
              name="PayMethod"
              value="CARD"
              defaultChecked
              onClick={onChangePayMethod}
            />
            <label htmlFor="CARD">신용카드결제</label>
          </div>
          <div>
            <input
              type="radio"
              id="bank"
              name="PayMethod"
              value="bank"
              onClick={onChangePayMethod}
            />
            <label htmlFor="bank">무통장입금</label>
          </div>
        </Payment>
        <BankComponent id="account">
          <span>신한은행 100-024-616921</span>
          <input placeholder="입금자명" name="depositor" id="depositor"></input>
        </BankComponent>
      </TotalComponent>
      <Container>
        <Pay className="pay" onClick={HandleClick}>
          {button}
        </Pay>
      </Container>
      {form}
    </CartContainer>
  );
}

const CartContainer = styled.div`
    justify-content: space-around;
    margin-top: 20px;
    display: block;
  `,
  TotalComponent = styled.div`
    width: 100%;
    max-width: 1024px;
    text-align: center;
    position: relative;
    padding: 20px;
    background: #fff;
    margin: 0 auto;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: 0px 3px 9px rgb(0 0 0 / 5%);
  `,
  Container = styled.div`
    margin-top: 20px;
    text-align: center;
    position: relative;
    border-color: #cacbd4;
  `,
  Payment = styled.div`
    display: flex;
    justify-content: center;
  `,
  Pay = styled.button`
    border-radius: 20px;
    font-size: 1.5rem;
    color: #fff;
    padding: 20px 20px;
    &.cart {
      background: var(--color-danger);
    }
    &.pay {
      background: var(--color-main);
    }
  `,
  BankComponent = styled.div`
    margin-top: 15px;
    display: none;
    & > input {
      margin-left: 10px;
      border: 1px solid #cacbd4;
      font-size: 15px;
    }
  `;
