import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ProfileComponent() {
  const Start = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 0;
    border-radius: 30px;
    background: #91d8e4;
    color: black;
    font-size: 5rem;
    width: 50%;
    text-align: center;
    cursor: pointer;
    font-family: "Cafe24Dongdong";
  `;

  const [studentComponent, setStudentComponent] = useState(<></>);
  const navigate = useNavigate();

  const startLMS = () => {
    navigate("/lms/intro");
  };
  useEffect(() => {
    axios
      .get("/api/lms/student/check")
      .then((res) => {
        const student = res.data;
        console.log(student);
        if (student) {
          // setStudentComponent(<Navigate to={"/lms/intro"} />);
          setStudentComponent(<Start onClick={startLMS}>학습시작하기</Start>);
        } else {
          return axios.get("/api/lms/student/all").then((res) => {
            if (res.data.length === 0) {
              setStudentComponent(<CreateStudentComponent></CreateStudentComponent>);
            } else {
              setStudentComponent(<ChoiceComponent list={res.data}></ChoiceComponent>);
            }
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return <>{studentComponent}</>;
}

/**
 * 프로필 생성 컴포넌트
 * @returns
 */
function CreateStudentComponent() {
  const Container = styled.div`
      width: 100vw;
      height: 100vh;
      position: absolute;
      background-color: rgba(82, 82, 82, 0.7);
      z-index: 999;
    `,
    SubContainer = styled.div`
      width: 100%;
      height: 100%;
      position: relative;
    `,
    Component = styled.div`
      width: 50%;
      height: 50%;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 15px;
    `,
    TitleText = styled.h3`
      margin-top: 3vh;
      text-align: center;
    `,
    InputComponent = styled.div`
      width: 100%;
      margin: auto;
      margin-top: 30px;
      text-align: center;
    `,
    InputBox = styled.input`
      margin-left: 10px;
      border: 1px solid black;
      text-align: center;
      font-size: 20px;
    `,
    Button = styled.button`
      padding: 15px;
      background-color: var(--color-content1bg);
      border-radius: 15px;
      margin-top: 10px;
      font-size: 20px;
    `;

  const navigate = useNavigate();

  const handleClickAdd = (e: any) => {
    const name = e.target.name.value;
    axios
      .post("/api/lms/student", {
        name,
      })
      .then((res) => {
        if (res.data) {
          navigate("/lms/intro");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    e.preventDefault();
  };

  return (
    <Container>
      <SubContainer>
        <Component>
          <TitleText>생성된 프로필이 없습니다. 프로필을 만들어주세요.</TitleText>
          <InputComponent>
            <form onSubmit={handleClickAdd}>
              <label htmlFor="name">계정 이름</label>
              <InputBox type="text" required placeholder="이름" id="name" name="name" />
              <div>
                <Button type="submit">만들기</Button>
              </div>
            </form>
          </InputComponent>
        </Component>
      </SubContainer>
    </Container>
  );
}

/**
 * 프로필 선택 컴포넌트
 * @returns
 */
function ChoiceComponent(props: any) {
  const Container = styled.div`
      width: 100vw;
      height: 100vh;
      position: absolute;
      background-color: rgba(82, 82, 82, 0.7);
      z-index: 999;
    `,
    SubContainer = styled.div`
      width: 100%;
      height: 100%;
      position: relative;
    `,
    Component = styled.div`
      width: 50%;
      height: 50%;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 15px;
    `,
    TitleText = styled.h3`
      margin-top: 3vh;
      text-align: center;
    `,
    InputComponent = styled.div`
      width: 100%;
      margin: auto;
      margin-top: 30px;
      text-align: center;
    `,
    Button = styled.button`
      width: 95%;
      height: 70px;
      background-color: var(--color-content1bg);
      border-radius: 15px;
      margin-top: 10px;
      font-size: 20px;
    `;

  const navigate = useNavigate();

  return (
    <Container>
      <SubContainer>
        <Component>
          <TitleText>프로필을 선택해주세요.</TitleText>
          <InputComponent>
            <Grid container spacing={1}>
              {props.list.map((element: any, index: number) => (
                <Grid item xs={4} key={index}>
                  <div>
                    <Button
                      id={element.id}
                      onClick={(e: any) => {
                        axios
                          .post("/api/lms/student/choice", {
                            id: e.target.id,
                          })
                          .then((res) => {
                            if (res.data) {
                              navigate("../intro");
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}>
                      {element.name}
                    </Button>
                  </div>
                </Grid>
              ))}
            </Grid>
          </InputComponent>
        </Component>
      </SubContainer>
    </Container>
  );
}
