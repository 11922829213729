import styled from "@emotion/styled";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const [userId, setuserId] = useState("");
  const [password, setpassword] = useState("");
  const [warning, setwarning] = useState("");
  const navigate = useNavigate();

  return (
    <div>
      <Section>
        <Container>
          <Form>
            <Title>로그인</Title>
            <WelComeText>애프터스쿨에 오신 것을 환영 합니다.</WelComeText>
            <SignFormContanier>
              <label>아이디</label>
              <input
                name="name"
                type="text"
                required
                placeholder="아이디를 입력해 주세요."
                autoComplete="off"
                onChange={(e) => {
                  setuserId(e.target.value);
                  setwarning("");
                }}
              />
              <div></div>
              <label>비밀번호</label>
              <input
                name="password"
                type="password"
                required
                placeholder="비밀번호를 입력해 주세요."
                onChange={(e) => {
                  setpassword(e.target.value);
                  setwarning("");
                }}
              />
              <div></div>
            </SignFormContanier>
            <div>
              <Warning>{warning}</Warning>
            </div>
            <SignBtn
              type="submit"
              className="submit"
              onClick={() => {
                if (userId === "") {
                  return setwarning("아이디를 입력하세요.");
                }
                if (password === "") {
                  return setwarning("비밀번호를 입력하세요.");
                }
                if (userId !== "" && password !== "") {
                  return signin(userId, password, setwarning, navigate);
                }
              }}>
              로그인 하기
            </SignBtn>
            <SignUpContainer>
              <Link to="/signup">회원가입</Link>
              <Link to="/find">아이디/비밀번호 찾기</Link>
            </SignUpContainer>
          </Form>
        </Container>
      </Section>
    </div>
  );
}

const Section = styled.div`
    width: 100%;
    font-family: S-CoreDream-5Medium, sans-serif;
    min-height: 793px;
    position: relative;
  `,
  Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 1128px;
    margin: 0 auto;
    margin-top: 8.5vh;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 86px 0;
  `,
  Form = styled.div`
    padding: 46px 60px 36px 61px;
    border-radius: 5px;
    -webkit-box-shadow: -7px 7px 7px 0 rgb(0 0 0 / 5%);
    box-shadow: -7px 7px 7px 0 rgb(0 0 0 / 5%);
    border: 1px solid #ededef;
    background-color: #fff;
  `,
  Title = styled.h2`
    color: #303030;
    font-size: 17px;
    text-align: center;
  `,
  WelComeText = styled.p`
    margin-bottom: 15px;
  `,
  SignFormContanier = styled.div`
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-gap: 5px;
    grid-template-columns: 3fr 2fr;
    grid-auto-rows: minmax(0, auto);
    grid-template-rows: repeat(2, 23px 46px 0);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.76px;
    color: #303030;
    grid-template-areas:
      "a a"
      "b b"
      "c c"
      "d d"
      "e e"
      "f f";
    & > label {
      &:first-of-type {
        grid-area: a;
      }
      &:last-of-type {
        grid-area: d;
      }
    }
    & > div {
      &:first-of-type {
        grid-area: c;
      }
      &:last-of-type {
        grid-area: f;
      }
    }
    & > input {
      border-radius: 2px;
      border: 1px solid #d9dbe2;
      padding: 9px;
      letter-spacing: -0.88px;
      &:first-of-type {
        grid-area: b;
      }
      &:last-of-type {
        grid-area: e;
      }
    }
  `,
  SignBtn = styled.button`
    margin-top: 30px;
    width: 100%;
    max-width: 400px;
    border-radius: 30px;
    background-color: #89ccff;
    color: #fff;
    font-weight: 700;
    letter-spacing: -1.03px;
    transition: transform 0.1s ease-in, box-shadow 0.25s ease-in, -webkit-transform 0.1s ease-in,
      -webkit-box-shadow 0.25s ease-in;
    margin: 0 auto 13px auto;
    padding: 21px 0 18px 0;
    font-size: 18px;
    display: block;
  `,
  SignUpContainer = styled.div`
    display: flex;
    justify-content: space-around;
    & > a,
    button {
      font-size: 16px;
      border: none;
      background: none;
      color: #53b7f4;
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }
  `,
  Warning = styled.p`
    color: var(--color-danger);
    margin-bottom: 5px;
  `;

async function signin(id: String, password: String, warning: any, navigate: any) {
  const url = "/api/auth/login";
  const form = {
    id: id,
    password: password,
  };
  await axios
    .post(url, form)
    .then((res) => {
      if (res.data) {
        localStorage.setItem("userIndex", res.data.id);
        localStorage.setItem("userName", res.data.name);
        window.location.href = "/";
      } else {
        warning("아이디 혹은 비밀번호가 틀렸습니다.");
      }
    })
    .catch();
}
