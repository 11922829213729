import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";

export default function Advantage() {
  return (
    <Component className="fourth">
      <FourthTextComponent className="left">
        <img src="../images/home/image1.jpg" alt="after_school" />
      </FourthTextComponent>
      <FourthTextComponent className="right">
        {[
          {
            title: "동기유발 면에서 효과가 있다.",
            content:
              "역할극을 통해서 외국어로 말하는 실생활에서의 상황을 접하게 함으로 어린이들은 그 상황 속에서 지정한 의사소통에 대한 필요성을 느끼게 된다.",
          },
          {
            title: "자신감에서 효과가 있다.",
            content:
              "역할극은 즉흥적인 상황적용을 가능하게 함으로 어린이들에게 자신감을 심어주며, 실제적으로 자신감 있는 학생은 외국어 활용에 있어서 더 우수하다.",
          },
          {
            title: "소극적 수업태도를 개선한다.",
            content:
              "과묵한 학생은 그들 자신에 관한 대화나 활동에 참여하는데 소극적이나 역할극은 이러한 어려움을 해소하는데 도움이 된다.",
          },
          {
            title: "실수에 대한 두려움 극복에 효과가 있다.",
            content:
              "역할극을 통해서 어린이들은 자신이 맡은 역할을 표현하면서 덜 긴장하게 되고 언어사용에 대하여 덜 의식적이 된다.",
          },
          {
            title: "다른 사람의 감정을 이해하는데 효과가 있다.",
            content:
              "자신이 아닌 다른 인물의 역할을 맡아 봄으로써 자아의 테두리를 벗어나 다른 사람들의 감정을 이해하는데 도움이 된다.",
          },
          {
            title: "상황에 따른 언어적 적응 능력을 높인다.",
            content:
              "역할극을 통해서 사회적 관계를 활성화하는데 필요한 어휘나 언어기능, 의사 소통 능력 등의 활동한계를 넓힐 수 있다.",
          },
        ].map((item: any, index) => (
          <article key={index}>
            <Addition />
            <h5>{item.title}</h5>
            <TextComponent>{item.content}</TextComponent>
          </article>
        ))}
      </FourthTextComponent>
    </Component>
  );
}

const Component = styled.div`
    width: 100%;
    box-sizing: border-box;
    &.fourth {
      display: grid;
      grid-template-columns: 30% 70%;
      width: 70%;
      margin: 30px auto;
      @media (max-width: 1024px) {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
    &.fifth {
      position: relative;
      width: 70%;
      flex-direction: column;
      padding-top: 150px;
      padding-bottom: 150px;
      align-items: flex-start;
      overflow: hidden;
      margin: 0 auto;
      height: auto;
      @media (max-width: 768px) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  `,
  FourthTextComponent = styled.div`
    &.left {
      width: 90%;
      & > img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      @media (max-width: 1024px) {
        width: 100%;
        & > img {
          width: 35%;
          margin: 0 auto;
          top: 0;
          transform: translateY(0);
        }
      }
    }
    &.right {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.2rem;
      & > article {
        padding: 2rem;
        border-radius: 15px;
        position: relative;
        &:nth-of-type(-n + 3) {
          background: var(--color-main);
        }
        &:nth-of-type(n + 4) {
          background: #f49d1a;
        }
        & > h5 {
          font-size: 2rem;
        }
        &:hover {
          box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.3);
          & > h5 {
            font-size: 1.7rem;
            color: white;
            transition: all 0.3s ease 0s;
          }
          & > svg {
            display: none;
            transition: all 0.3s ease 0s;
          }
          & > p {
            font-size: 1.2rem;
            font-weight: 600;
            transition: all 0.3s ease 0s;
            opacity: 1;
            visibility: visible;
          }
        }
      }
      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 0.5rem;
        width: 80%;
        margin: auto;
        & > article {
          padding: 1rem;
          margin-top: 10px;
          & > h5 {
            font-size: 1.5rem;
          }
        }
      }
    }
  `,
  Addition = styled(AddIcon)`
    position: absolute;
    right: 10px;
    top: 10px;
  `,
  TextComponent = styled.p`
    margin-top: 10px;
    font-size: 0.9rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;
  `;
