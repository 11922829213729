import styled from "@emotion/styled";
import axios from "axios";
import { useState } from "react";

export default function FindId() {
  const [Id, setId] = useState(<></>);

  const handleClick = (e: any) => {
    e.preventDefault();
    const [name, tel] = [e.target.name.value, e.target.tel.value];
    axios
      .post("/api/auth/findid", {
        name,
        tel,
      })
      .then((res) => {
        if (res) {
          setId(<IdComponent>아이디:{res.data}</IdComponent>);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Section>
      <Container>
        <form onSubmit={handleClick}>
          <Form>
            <Title>아이디 찾기</Title>
            <InnerContainer>
              <div>
                <label>이름</label>
                <input name="name" id="name" required />
              </div>
              <div>
                <label>전화번호</label>
                <input name="tel" id="tel" required />
              </div>
            </InnerContainer>
            <button type="submit">아이디 찾기</button>
            {Id}
          </Form>
        </form>
      </Container>
    </Section>
  );
}

const Section = styled.div`
    width: 100%;
    font-family: S-CoreDream-5Medium, sans-serif;
    min-height: 600px;
    position: relative;
  `,
  Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 1128px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 86px 0;
  `,
  Form = styled.div`
    padding: 46px 60px 36px 61px;
    border-radius: 5px;
    box-shadow: -7px 7px 7px 0 rgb(0 0 0 / 5%);
    border: 1px solid #ededef;
    background-color: #fff;
    text-align: center;
    & > button {
      margin-top: 10px;
      padding: 10px 20px;
      border-radius: 15px;
      font-size: 15px;
      font-weight: 800;
      background: var(--color-main);
      color: #fff;
    }
  `,
  Title = styled.h2`
    color: #303030;
    font-size: 17px;
    text-align: center;
  `,
  InnerContainer = styled.div`
    display: flex;
    & > div {
      margin-top: 8px;
    }
    & label {
      font-size: 20px;
      &:last-of-type {
        margin-left: 10px;
      }
    }
    & input {
      margin-left: 8px;
      font-size: 20px;
      border: 1px solid black;
    }
  `,
  IdComponent = styled.div`
    margin-top: 10px;
  `;
