import styled from "@emotion/styled";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CDN } from "../common/functions";
import Menu from "../common/mypagemenu";

export default function MypageComponent() {
  const navigate = useNavigate();

  const handleClickEdit = (e: any) => {
    const [oldpw, newpw, newpwCheck] = [
      e.target.oldpw.value,
      e.target.newpw.value,
      e.target.newpwCheck.value,
    ];
    if (oldpw === newpw) {
      alert("현재 비밀번호랑 같습니다.");
    } else if (newpwCheck !== newpw) {
      alert("새 비밀번호가 같지 않습니다.");
    } else if (newpw === newpwCheck) {
      axios
        .put("/api/user/updatePassword", {
          oldpw,
          newpw,
        })
        .then((res) => {
          if (res.data) {
            navigate("/lms/mypage");
          } else {
            alert("현재 비밀번호가 틀렸습니다.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    e.preventDefault();
  };

  return (
    <Background>
      <Header>
        <Menu />
        <Close
          onClick={() => {
            navigate("/lms/intro");
          }}>
          <img src={CDN + "/images/intro/assets/close.png"} alt="menu" />
        </Close>
      </Header>
      <Main>
        <MainContainer>
          <form onSubmit={handleClickEdit}>
            <InputContainer>
              <div>
                <label htmlFor="oldpw">현재 비밀번호</label>
                <input id="oldpw" type="password" name="oldpw" />
              </div>
              <div>
                <label htmlFor="newpw">새로운 비밀번호</label>
                <input id="newpw" type="password" name="newpw" />
              </div>
              <div>
                <label htmlFor="newpwCheck">비밀번호 확인</label>
                <input name="newpwCheck" type="password" id="newpwCheck" />
              </div>
              <div>
                <button type="submit">수정</button>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/lms/mypage");
                  }}>
                  취소
                </button>
              </div>
            </InputContainer>
          </form>
        </MainContainer>
      </Main>
    </Background>
  );
}

const Background = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background: var(--color-content1bg);
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  `,
  Header = styled.div`
    width: 100%;
    height: calc(0.1625 * var(--app-height));
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Close = styled.button`
    width: calc(0.11875 * var(--app-height));
    height: calc(0.11875 * var(--app-height));
    margin-right: calc(0.025 * var(--app-height));
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 2;
    display: flex;
    flex-flow: column;
    font-size: calc(0.02375 * var(--app-height));
    font-family: Nanumsquare;
    background: none;
    color: var(--color-text2);
    align-items: center;
    text-transform: uppercase;
  `,
  Main = styled.div`
    height: calc(0.8375 * var(--app-height));
    background: #2581b3;
  `,
  MainContainer = styled.div`
    display: flex;
    justify-content: center;
  `,
  InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: calc(0.10063 * var(--app-height));
    width: calc(0.8 * var(--app-height));
    & > div {
      display: flex;
      font-family: Nanumsquare;
      align-items: center;
      justify-content: space-between;
      margin-top: calc(0.02604 * var(--app-height));
      & label {
        font-size: calc(0.02604 * var(--app-height));
        font-weight: 700;
        background: #ffe59d;
        color: #155175;
        border-radius: 23px;
        padding: 0 calc(0.02734 * var(--app-height)) 0 calc(0.02734 * var(--app-height));
      }
      & input {
        font-family: Nanumsquare;
        font-weight: 800;
        border-radius: 23px;
        width: calc(0.45833 * var(--app-height));
        height: calc(0.0612 * var(--app-height));
        padding: 0 calc(0.02734 * var(--app-height)) 0 calc(0.02734 * var(--app-height));
        font-size: calc(0.02604 * var(--app-height));
      }
    }
    & > :first-of-type {
      margin-top: 0;
      & > input {
        background: #e1eef3;
      }
    }
    & > :last-of-type {
      & > button {
        width: 40%;
        font-size: calc(0.02604 * var(--app-height));
        background: var(--color-danger);
        color: #fff;
        border-radius: 23px;
        padding: 10px 0px;
      }
    }
  `;
