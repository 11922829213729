import styled from "@emotion/styled";
import post from "../common/post";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Privacy, Service } from "./agree";

export default function SignUp(this: any) {
  const [userId, setuserId] = useState("");
  const [Idcheck, setIdcheck] = useState(false);
  const [IdcheckText, setIdcheckText] = useState("");
  const [password, setpassword] = useState("");
  const [passwordCondition, setpasswordCondition] = useState(
    "비밀번호는 4 ~ 15자 영문, 숫자를 사용하세요."
  );
  const [passwordCheck, setpasswordCheck] = useState("");
  const [mismatchError, setMismatchError] = useState(false);
  const [mismatchText, setMismatchText] = useState("");
  const [name, setname] = useState("");
  const [nickname, setnickname] = useState("");
  const [tel, setTel] = useState("");
  const [mail, setmail] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [postcode, setpostcode] = useState("");
  const [recommanderList, setrecommanderList] = useState("");
  const [recommander, setrecommander] = useState("");
  const [checkAgree, setcheckAgree] = useState(false);
  const [ad_mail, setAd_mail] = useState(false);
  const [ad_sns, setAd_sns] = useState(false);
  const navigate = useNavigate();

  const onChangePassword = useCallback(
    (e: any) => {
      setpasswordCondition(chkPW(e.target.value));
      setpassword(e.target.value);
      if (e.target.value !== passwordCheck) {
        setMismatchError(e.target.value !== passwordCheck);
        setMismatchText("비밀번호가 서로 다릅니다.");
      } else {
        setMismatchText("");
      }
    },
    [passwordCheck]
  );

  const onChangePasswordCheck = useCallback(
    (e: any) => {
      setpasswordCheck(e.target.value);
      if (e.target.value !== password) {
        setMismatchError(e.target.value !== password);
        setMismatchText("비밀번호가 서로 다릅니다.");
      } else {
        setMismatchText("");
      }
    },
    [password]
  );

  const handleClick = () => {
    if (!Idcheck) {
      return alert("아이디 중복확인을 하세요.");
    }
    if (!mismatchError) {
      return alert("비밀번호가 같지 않습니다.");
    }
    if (name === null || name === "") {
      return alert("이름을 입력해주세요.");
    }
    if (tel === null || tel === "") {
      return alert("전화번호를 입력해주세요.");
    }
    if (recommander === "") {
      return alert("추천인을 선택해주세요.");
    }
    if (!checkAgree) {
      return alert("서비스 이용약관에 동의해주세요.");
    }
    axios
      .post("/api/auth/signup", {
        userId,
        password,
        name,
        nickname,
        tel,
        mail,
        address1,
        address2,
        postcode,
        recommander,
        ad_mail,
        ad_sns,
      })
      .then((response) => {
        navigate("/login");
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get("/api/admin/recommander/getAll")
      .then((res) => {
        let list: any = res.data.map((element: any) => {
          return (
            <RecommanderComponent key={element.id} value={element.name}>
              {element.name}
            </RecommanderComponent>
          );
        });
        setrecommanderList(list);
      })
      .catch();
  }, []);

  const chkId = (id: string) => {
    let regExp = /^[a-zA-z0-9]{4,15}$/;
    return regExp.test(id);
  };

  return (
    <Section>
      <Container>
        <FormContainer>
          <Title>회원가입</Title>
          <SignFormContanier>
            <LabelContainer>
              <label>
                아이디<span> *</span>
              </label>
              <SignInput
                name="id"
                type="text"
                required
                placeholder="아이디를 입력해 주세요."
                autoComplete="off"
                id="id"
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  let text = (document.getElementById("id") as HTMLInputElement) || null;
                  if (text.value === "") return alert("아이디를 입력하세요.");
                  //id 공백확인
                  if (text.value.indexOf(" ") >= 0)
                    return alert("아이디에 공백을 사용할 수 없습니다.");
                  //id 정규식확인
                  if (!chkId(text.value))
                    return alert("아이디는 4 ~ 15자 영문, 숫자를 사용하세요.");
                  setuserId(text.value);
                  axios
                    .post("/api/auth/check", { userId: text.value })
                    .then((res) => {
                      if (res.data) {
                        setIdcheckText("사용불가능한 아이디입니다.");
                        setIdcheck(false);
                      } else {
                        setIdcheckText("사용가능한 아이디입니다.");
                        setIdcheck(true);
                      }
                    })
                    .catch(function (err) {
                      console.log(err);
                    });
                }}>
                중복확인
              </button>
              <div>
                <p>{IdcheckText}</p>
              </div>
            </LabelContainer>
            <LabelContainer>
              <label>
                비밀번호<span> *</span>
              </label>
              <SignInput
                name="password"
                type="password"
                required
                placeholder="비밀번호를 입력해 주세요."
                onChange={onChangePassword}
              />
              <div>
                <WarningText>{passwordCondition}</WarningText>
              </div>
            </LabelContainer>
            <LabelContainer>
              <label>
                비밀번호 확인<span> *</span>
              </label>
              <SignInput
                name="password"
                type="password"
                required
                placeholder="비밀번호를 다시 한번 입력해 주세요."
                autoComplete="off"
                onChange={onChangePasswordCheck}
              />
              <div>
                <WarningText>{mismatchText}</WarningText>
              </div>
            </LabelContainer>
            <LabelContainer>
              <label>
                성함<span> *</span>
              </label>
              <SignInput
                name="name"
                type="text"
                required
                placeholder="성함을 입력해 주세요."
                autoComplete="off"
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            </LabelContainer>
            <LabelContainer>
              <label>닉네임</label>
              <SignInput
                name="name"
                type="text"
                required
                placeholder="닉네임을 입력해 주세요."
                autoComplete="off"
                onChange={(e) => {
                  setnickname(e.target.value);
                }}
              />
            </LabelContainer>
            <LabelContainer>
              <label>
                전화번호<span> *</span>
              </label>
              <SignInput
                name="tel"
                type="tel"
                required
                placeholder="전화번호를 입력해 주세요."
                autoComplete="off"
                onChange={(e) => {
                  setTel(e.target.value);
                }}
              />
            </LabelContainer>
            <LabelContainer>
              <label>이메일</label>
              <SignInput
                name="mail"
                type="email"
                placeholder="이메일을 입력해 주세요."
                autoComplete="off"
                onChange={(e) => {
                  setmail(e.target.value);
                }}
              />
            </LabelContainer>
            <LabelContainer>
              <label>주소</label>
              <SignInput name="address" type="text" disabled id="postcode" />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  post();
                }}>
                주소찾기
              </button>
              <input
                name="address"
                type="text"
                placeholder="도로명 주소"
                disabled
                id="roadAddress"
              />
              <input
                name="address"
                type="text"
                placeholder="나머지 주소를 입력해 주세요."
                onChange={(e) => {
                  let address1 =
                    (document.getElementById("roadAddress") as HTMLInputElement) || null;
                  let postcode = (document.getElementById("postcode") as HTMLInputElement) || null;
                  setpostcode(postcode.value);
                  setaddress1(address1.value);
                  setaddress2(e.target.value);
                }}
              />
              <div></div>
            </LabelContainer>
            <LabelContainer>
              <label>
                추천인<span> *</span>
              </label>
              <select
                name="recommander"
                onChange={(e) => {
                  setrecommander(e.target.value);
                }}>
                <option value="">추천인 입력해 주세요.</option>
                {recommanderList}
              </select>
              <div>
                <WarningText>있을 시 선택해주세요</WarningText>
              </div>
            </LabelContainer>
            <AgreeContainer>
              <span>마케팅 수신정보</span>
              <input
                id="chckBox1"
                type="checkbox"
                name="chckBox1"
                onClick={() => {
                  const check = (document.getElementById("chckBox1") as HTMLInputElement) || null;
                  setAd_mail(check.checked);
                }}
              />
              <label htmlFor="chckBox1">이메일</label>
              <input
                id="chckBox2"
                type="checkbox"
                name="chckBox2"
                onClick={() => {
                  let check = (document.getElementById("chckBox2") as HTMLInputElement) || null;
                  setAd_sns(check.checked);
                }}
              />
              <label htmlFor="chckBox2">sns</label>
            </AgreeContainer>
            <div>이용약관</div>
            <ServiceComponent>
              <Service />
            </ServiceComponent>
            <div>개인정보취급방침</div>
            <ServiceComponent>
              <Privacy />
            </ServiceComponent>
            <AgreeContainer>
              <label htmlFor="chckBox">
                <SpanBlue>서비스 이용약관</SpanBlue>
                <span> 및 </span>
                <SpanBlue>개인정보 제공</SpanBlue>
                <span>에 동의해주세요.</span>
              </label>
              <input
                id="chckBox"
                type="checkbox"
                name="chckBox"
                required
                onClick={() => {
                  let check = (document.getElementById("chckBox") as HTMLInputElement) || null;
                  setcheckAgree(check.checked);
                }}
              />
            </AgreeContainer>
          </SignFormContanier>
          <SignBtn type="submit" className="submit" onClick={handleClick}>
            회원가입 하기
          </SignBtn>
          <SignUpContainer>
            <p>이미 회원이신가요?</p>
            <Link to="/sign/in">로그인 하기</Link>
          </SignUpContainer>
        </FormContainer>
      </Container>
    </Section>
  );
}

const Section = styled.div`
    width: 100%;
    font-family: S-CoreDream-5Medium, sans-serif;
    min-height: 793px;
    position: relative;
  `,
  Container = styled.div`
    position: relative;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    flex-wrap: wrap;
    padding: 86px 0;
  `,
  FormContainer = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 900px;
    padding: 46px 60px 36px 61px;
    border-radius: 5px;
    box-shadow: -7px 7px 7px 0 rgb(0 0 0 / 5%);
    border: 1px solid #ededef;
    background-color: #fff;
  `,
  Title = styled.h2`
    color: #303030;
    font-size: 17px;
    text-align: center;
  `,
  SignFormContanier = styled.div`
    display: block;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.76px;
    color: #303030;
  `,
  LabelContainer = styled.div`
    position: relative;
    margin: 10px auto;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 3fr 1fr 2fr;
    grid-auto-rows: minmax(0, auto);
    grid-template-rows: 23px 46px;
    grid-template-areas:
      "a a a"
      "b b c";
    & > button {
      background: var(--color-main);
      color: white;
      border-radius: 20px;
      font-size: 18px;
      width: 70%;
      margin: 0 auto;
    }
    &:first-of-type {
      grid-template-areas:
        "a a a"
        "b b c"
        "d d d";
      margin: 15px 0;
      & > div {
        margin: 8px 0;
        grid-area: d;
        color: var(--color-danger);
      }
    }
    &:nth-of-type(8) {
      grid-template-areas:
        "a a a"
        "b b c"
        "d d d"
        "f f f";
      & > button {
        grid-area: c;
      }
      & > input {
        border-radius: 2px;
        border: 1px solid #d9dbe2;
        padding: 9px;
        letter-spacing: -0.88px;
      }
      & > input:first-of-type {
        grid-area: b;
      }
      & > input:nth-of-type(2) {
        grid-area: d;
      }
      & > input:last-of-type {
        grid-area: f;
      }
    }
    & > label {
      margin: auto 0;
      grid-area: a;
      & > span {
        color: var(--color-danger);
      }
      cursor: default;
    }
    & > div {
      margin-left: 10px;
      grid-area: c;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    & > select {
      grid-area: b;
      border-radius: 2px;
      border: 1px solid #d9dbe2;
      padding: 9px;
      letter-spacing: -0.88px;
      z-index: 2;
    }
  `,
  WarningText = styled.p`
    text-align: left;
    font-size: 12px;
    color: var(--color-danger);
  `,
  SignInput = styled.input`
    border-radius: 2px;
    border: 1px solid #d9dbe2;
    padding: 9px;
    letter-spacing: -0.88px;
    grid-area: b;
  `,
  SignBtn = styled.button`
    margin-top: 30px;
    width: 100%;
    max-width: 400px;
    border-radius: 30px;
    background-color: #89ccff;
    color: #fff;
    font-weight: 700;
    letter-spacing: -1.03px;
    transition: transform 0.1s ease-in, box-shadow 0.25s ease-in;
    margin: 0 auto 13px auto;
    padding: 21px 0 18px 0;
    font-size: 18px;
    display: block;
  `,
  AgreeContainer = styled.div`
    width: 50%;
    margin: 10px auto;
    & > label > button {
      color: #53b7f4;
      background: white;
    }
    & > input {
      margin-left: 10px;
      zoom: 1.5;
      vertical-align: text-bottom;
    }
  `,
  SignUpContainer = styled.div`
    display: flex;
    justify-content: center;
    & > a {
      margin-left: 10px;
      font-size: 16px;
      border: none;
      background: none;
      color: #53b7f4;
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }
  `,
  RecommanderComponent = styled.option``,
  SpanBlue = styled.span`
    color: #53b7f4;
  `,
  ServiceComponent = styled.div`
    position: relative;
    overflow: scroll;
    height: 220px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #dddddd;
    font-size: 15px;
    color: #9ca6aa;
    line-height: 22px;
  `;

function chkPW(pw: String) {
  let num = pw.search(/[0-9]/g);
  let eng = pw.search(/[a-z]/gi);

  if (pw.length < 8 || pw.length > 15) {
    return "4자리 ~ 15자리 이내로 입력해주세요.";
  } else if (pw.search(/\s/) !== -1) {
    return "비밀번호는 공백 없이 입력해주세요.";
  } else if (num < 0 || eng < 0) {
    return "영문,숫자를 혼합하여 입력해주세요.";
  } else {
    return "";
  }
}
