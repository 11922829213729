import { Routes, Route, useMatch } from "react-router-dom";
import { useEffect, lazy } from "react";
import { useSetRecoilState } from "recoil";
import authState from "./state/auth";
import axios from "axios";
import "./App.css";

import PrivateRoute from "./learn/utils/PrivateRoutes";

///////////////////////////메인페이지부분///////////////////////////////

import NavbarComponent from "./common/Navbar";
import LoginComponent from "./auth/login";
import SignUpComponent from "./auth/signup";
import FooterComponent from "./common/Footer";
import Upbtn from "./common/Upbtn";
import HomeComponent from "./home/index";
import ShopComponent from "./shop";
import ProductComponent from "./shop/product";
import QNAComponent from "./qna";
import IntroduceComponent from "./introduce";
import CartComponent from "./cart/index";
import FindIdPwComponent from "./auth/find";
import FindId from "./auth/findId";
import Findpw from "./auth/findpw";

///////////////////////////LMS부분///////////////////////////////
//컨텐츠부분
import LmsPrivateRoutes from "./lms/auth/PrivateRoute";
import LmsComponent from "./lms/intro";
import LmsSeriesComponent from "./lms/series";
import LmsReferenceComponent from "./lms/reference";
import LmsContentComponent from "./lms/content";
import LmsProfileComponent from "./lms/profile";
import LmsProfileEditComponent from "./lms/profile/edit";
import LmsMypageComponent from "./lms/mypage";
import LmsRecordComponent from "./lms/record";
import LmsPasswordComponent from "./lms/mypage/pw";
import LmsMembershipcomponent from "./lms/membership";

// //강사부분
// import TeacherPrivateRoutes from "./teacher/auth/PrivateRoute";
// import TeacherDashboard from "./teacher/dashboard";
// import TeacherLoginComponent from "./teacher/login";
// import TeacherGroupSetting from "./teacher/group/setting";
// import TeacherStudentList from "./teacher/student/StudentList";
// import TeacherStudentDetail from "./teacher/student/MemberView";
// import TeacherTest from "./teacher/test/index";
// import TeacherPayment from "./teacher/payment/PaymentPage";
///////////////////////////////////////////////////////////////

///////////////////////////관리자부분///////////////////////////////
const AdminLoginComponent = lazy(() => import("./admin/login"));
const PrivateRoutes = lazy(() => import("./admin/auth/PrivateRoute"));
const UserComponent = lazy(() => import("./admin/user"));
const GroupUserComponent = lazy(() => import("./admin/group"));
const UserEditComponent = lazy(() => import("./admin/user/edit"));
const TeacherComponent = lazy(() => import("./admin/teacher"));
const RecommanderComponent = lazy(() => import("./admin/recommander"));
const RecommanderAddComponent = lazy(() => import("./admin/recommander/add"));
const RecommanderEditComponent = lazy(() => import("./admin/recommander/edit"));

const AdminShopComponent = lazy(() => import("./admin/shop"));
const AdminShopAddComponent = lazy(() => import("./admin/shop/add"));
const AdminShopEditComponent = lazy(() => import("./admin/shop/edit"));

const AdminCategoryComponent = lazy(() => import("./admin/category"));
const AdminCategoryAddComponent = lazy(() => import("./admin/category/add"));
const AdminCategoryEditComponent = lazy(() => import("./admin/category/edit"));

const AdminContentComponent = lazy(() => import("./admin/content"));
const AdminContentAddComponent = lazy(() => import("./admin/content/add"));
const AdminContentEditComponent = lazy(() => import("./admin/content/edit"));

const AdminReferenceComponent = lazy(() => import("./admin/reference"));
const AdminReferenceAddComponent = lazy(() => import("./admin/reference/add"));
const AdminReferenceEditComponent = lazy(() => import("./admin/reference/edit"));

const AdminOrderComponent = lazy(() => import("./admin/order"));
const AdminOrderViewComponent = lazy(() => import("./admin/order/view"));

const AdminExcelComponent = lazy(() => import("./admin/excel"));
const AdminVideoComponent = lazy(() => import("./admin/video"));

// 학습 부분 (Study)
// const StudyComponent = lazy(() => import("./study/home/index"));
const ContentComponent = lazy(() => import("./study/content/index"));
const ContentMidComponent = lazy(() => import("./study/content/mid"));
const CourseComponent = lazy(() => import("./study/course/index"));
const MypageComponent = lazy(() => import("./study/mypage/index"));
const DubbingComponent = lazy(() => import("./study/dubbing/index"));
const DialogueComponent = lazy(() => import("./learn/dialogue/index"));
const PrologueComponent = lazy(() => import("./learn/prologue/index"));
const DictComponent = lazy(() => import("./learn/dict/index"));
const PracticeComponent = lazy(() => import("./learn/practice/index"));
// const ChallengeComponent = lazy(() => import("./challenge/index"));
// const ChallengeTestComponent = lazy(() => import("./challenge/test"));
// const ChallengeRecordComponent = lazy(() => import("./challenge/record/index"));
const ExamComponent = lazy(() => import("./learn/exam/index"));

// 기록 부분
const DubbingVideoComponent = lazy(() => import("./dubbing"));

const StudyRecord = lazy(() => import("./record/study"));
////////////////////////

function App() {
  const pathAdmin = useMatch("/admin/*");
  const pathLms = useMatch("/lms/*");
  // const pathTeacher = useMatch("/teacher/*");
  const pathStudy = useMatch("/study/*");
  const pathLearn = useMatch("/learn/*");
  const pathDubbing = useMatch("/dubbing/*");
  const pathResult = useMatch("/record/*");

  const setIsLoggedIn = useSetRecoilState(authState);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = () => {
    axios.get("/api/auth/login").then((res) => {
      if (res.data.state) {
        setIsLoggedIn({
          id: res.data.id,
          state: true,
        });
      } else {
        setIsLoggedIn({
          id: "",
          state: false,
        });
      }
    });
  };

  const renderAdminRoutes = () => {
    return (
      <Routes>
        <Route path="/admin" element={<PrivateRoutes />}>
          {/* 회원관리 */}
          <Route path="user" element={<UserComponent />} />
          <Route path="group" element={<GroupUserComponent />} />
          <Route path="user/edit" element={<UserEditComponent />} />
          <Route path="group/edit" element={<UserEditComponent />} />
          {/* 매니저관리 */}
          <Route path="teacher" element={<TeacherComponent />} />
          <Route path="teacher/edit" element={<UserEditComponent />} />
          {/* 상품관리 */}
          <Route path="shop" element={<AdminShopComponent />} />
          <Route path="shop/add" element={<AdminShopAddComponent />} />
          <Route path="shop/edit" element={<AdminShopEditComponent />} />
          <Route path="category" element={<AdminCategoryComponent />} />
          <Route path="content" element={<AdminContentComponent />} />
          <Route path="reference" element={<AdminReferenceComponent />} />
          <Route path="category/add" element={<AdminCategoryAddComponent />} />
          <Route path="category/edit" element={<AdminCategoryEditComponent />} />
          <Route path="content/add" element={<AdminContentAddComponent />} />
          <Route path="content/edit" element={<AdminContentEditComponent />} />
          <Route path="reference/add" element={<AdminReferenceAddComponent />} />
          <Route path="reference/edit" element={<AdminReferenceEditComponent />} />
          {/* 주문관리 */}
          <Route path="order" element={<AdminOrderComponent />} />
          <Route path="order/view" element={<AdminOrderViewComponent />} />
          {/* 기타관리 */}
          <Route path="recommander" element={<RecommanderComponent />} />
          <Route path="recommander/add" element={<RecommanderAddComponent />} />
          <Route path="recommander/edit" element={<RecommanderEditComponent />} />
          <Route path="excel" element={<AdminExcelComponent />} />
          <Route path="video" element={<AdminVideoComponent />} />
        </Route>
        <Route path="/admin/login" element={<AdminLoginComponent />} />
      </Routes>
    );
  };

  // const renderTeacherRoutes = () => {
  //   return (
  //     <Routes>
  //       {/* 홈 */}
  //       <Route path="/teacher" element={<TeacherPrivateRoutes />}>
  //         <Route path="dashboard" element={<TeacherDashboard />} />
  //         <Route path="group" element={<TeacherGroupSetting />} />
  //         <Route path="students" element={<TeacherStudentList />} />
  //         <Route path="students/:id" element={<TeacherStudentDetail />} />
  //         <Route path="payment/membership" element={<TeacherPayment />} />
  //         <Route path="test" element={<TeacherTest />} />
  //         {/* <Route path="student/:id" element={<StudentDetail />} /> */}
  //       </Route>
  //       <Route path="/teacher/login" element={<TeacherLoginComponent />} />
  //     </Routes>
  //   );
  // };

  const renderLmsRoutes = () => {
    return (
      <Routes>
        <Route path="/lms" element={<LmsPrivateRoutes />}>
          {/* 홈 */}
          <Route path="intro" element={<LmsComponent />} />
          <Route path="series" element={<LmsSeriesComponent />} />
          <Route path="contents" element={<LmsContentComponent />} />
          {/* 마이페이지 */}
          <Route path="mypage" element={<LmsMypageComponent />} />
          <Route path="membership" element={<LmsMembershipcomponent />} />
          <Route path="pw" element={<LmsPasswordComponent />} />
          <Route path="profile" element={<LmsProfileComponent />} />
          <Route path="profile/edit" element={<LmsProfileEditComponent />} />
          <Route path="record" element={<LmsRecordComponent />} />
          <Route path="reference" element={<LmsReferenceComponent />} />
          {/* 주문관리 */}
        </Route>
      </Routes>
    );
  };

  const renderLearnRoutes = () => {
    return (
      <main>
        <Routes>
          <Route path="/learn" element={<PrivateRoute />}>
            <Route path="/learn/prologue/:id" element={<PrologueComponent />} />
            <Route path="/learn/dialogue/:id" element={<DialogueComponent />} />
            <Route path="/learn/record/:id" element={<DialogueComponent />} />
            <Route path="/learn/dict/:id" element={<DictComponent />} />
            <Route path="/learn/practice/:id" element={<PracticeComponent />} />
            <Route path="/learn/exam/:id" element={<ExamComponent />} />
            {/* <Route path="/learn/challenge/" element={<ChallengeComponent />} />
            <Route path="/learn/challenge/test" element={<ChallengeTestComponent />} />
            <Route path="/learn/challenge/record" element={<ChallengeRecordComponent />} /> */}
          </Route>
        </Routes>
      </main>
    );
  };

  const renderResultRoutes = () => {
    return (
      <main>
        <Routes>
          <Route path="/record/study" element={<StudyRecord />} />
        </Routes>
      </main>
    );
  };

  const renderDubbingRoutes = () => {
    return (
      <main>
        <Routes>
          <Route path="/dubbing/:video" element={<DubbingVideoComponent />} />
        </Routes>
      </main>
    );
  };

  const renderStudyRoutes = () => {
    return (
      <main>
        <Routes>
          <Route path="/study" element={<PrivateRoute />}>
            {/* <Route path="/study/home" element={<StudyComponent />} /> */}
            <Route path="/study/content/home" element={<ContentComponent />} />
            <Route path="/study/content/mid" element={<ContentMidComponent />} />
            <Route path="/study/course" element={<CourseComponent />} />
            <Route path="/study/mypage/" element={<MypageComponent />} />
            <Route path="/study/dubbing" element={<DubbingComponent />} />
          </Route>
        </Routes>
      </main>
    );
  };

  const renderDefaultRoutes = () => {
    return (
      <>
        <NavbarComponent />
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/introduce" element={<IntroduceComponent />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/signup" element={<SignUpComponent />} />
          <Route path="/find" element={<FindIdPwComponent />} />
          <Route path="/findid" element={<FindId />} />
          <Route path="/findpassword" element={<Findpw />} />
          <Route path="/shop" element={<ShopComponent />} />
          <Route path="/shop/product" element={<ProductComponent />} />
          <Route path="/qna" element={<QNAComponent />} />
          <Route path="/cart" element={<CartComponent />} />
          <Route path="*" element={<HomeComponent />} />
        </Routes>
        <Upbtn />
        <FooterComponent />
      </>
    );
  };

  if (pathAdmin) {
    return renderAdminRoutes();
  } else if (pathLms) {
    return renderLmsRoutes();
    // } else if (pathTeacher) {
    //   return renderTeacherRoutes();
  } else if (pathStudy) {
    return renderStudyRoutes();
  } else if (pathLearn) {
    return renderLearnRoutes();
  } else if (pathDubbing) {
    return renderDubbingRoutes();
  } else if (pathResult) {
    return renderResultRoutes();
  } else {
    return renderDefaultRoutes();
  }
}

export default App;
