import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";

export default function With() {
  return (
    <Component className="fifth">
      <FifthTextComponent>
        <Article>
          <img src="../images/home/who.jpg" alt="" />
          <YellowBox>
            <h1>누구랑 같이 하나요?</h1>
          </YellowBox>
        </Article>
        <Article className="able">
          <Addition />
          <img src="../images/home/who1.jpg" alt="" />
          <FifthTextBox>
            <h5>엄마/선생님이랑 하는</h5>
          </FifthTextBox>
        </Article>
      </FifthTextComponent>
      <FifthTextComponent>
        <Article className="able">
          <Addition />
          <img src="../images/home/who3.jpg" alt="" />
          <FifthTextBox>
            <h5>친구랑 하는</h5>
          </FifthTextBox>
        </Article>
        <Article className="able">
          <Addition />
          <img src="../images/home/who2.jpg" alt="" />
          <FifthTextBox>
            <h5>혼자 하는</h5>
          </FifthTextBox>
        </Article>
      </FifthTextComponent>
    </Component>
  );
}

const Component = styled.div`
    width: 100%;
    height: 70vh;
    box-sizing: border-box;
    &.fifth {
      position: relative;
      width: 70%;
      flex-direction: column;
      padding-top: 150px;
      padding-bottom: 150px;
      align-items: flex-start;
      overflow: hidden;
      margin: 0 auto;
      height: auto;
      & > h1 {
        text-align: center;
      }
      @media (max-width: 768px) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  `,
  Addition = styled(AddIcon)`
    position: absolute;
    right: 10px;
    top: 10px;
  `,
  FifthTextComponent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 50px;
    position: relative;
  `,
  Article = styled.article`
    width: 45%;
    height: 300px;
    position: relative;
    z-index: 1;
    border-radius: 30px;
    & > svg {
      font-size: 2rem;
      top: 20px;
      right: 20px;
      z-index: 3;
    }
    & > img {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
    &.able:hover {
      & > div {
        & > h5 {
          font-size: 1.7rem;
          transition: all 0.3s ease 0s;
        }
      }
      & > img {
        opacity: 1;
        transition: filter 0.3s ease 0s;
        filter: blur(5px);
      }
    }
  `,
  FifthTextBox = styled.div`
    padding: 30px 20px;
    & > h5 {
      font-size: 1.5rem;
      z-index: 3;
    }
    & > p {
      margin-top: 20px;
      z-index: 3;
    }
  `,
  YellowBox = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40%;
    border-radius: 30px;
    background-color: #f9d71c;
    & > h1 {
      text-align: center;
      width: 100%;
      font-size: 1.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `;
