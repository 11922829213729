import styled from "@emotion/styled";

export default function SideBar() {
  const component = (
    <Container>
      <a href="https://pf.kakao.com/_nxbxkKxl" target="_blank" rel="noreferrer">
        <ImgContainer>
          <Image src="../images/kakaotalk.png"></Image>
          <TextContainer>
            <TextComponent>{"카톡상담"}</TextComponent>
          </TextContainer>
        </ImgContainer>
      </a>
    </Container>
  );
  return component;
}

const Container = styled.div`
    justify-items: center;
    position: fixed;
    top: 200px;
    right: 0;
    width: 120px;
    height: 120px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    z-index: 999;
    @media (max-width: 1024px) {
      width: 85px;
      height: 95px;
    }
    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  `,
  ImgContainer = styled.div`
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
  `,
  Image = styled.img`
    width: auto;
    height: 10vh;
    display: block;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 1024px) {
      height: 7vh;
    }
  `,
  TextContainer = styled.div`
    text-align: center;
    align-items: center;
  `,
  TextComponent = styled.p`
    position: relative;
    font-size: 1.3rem;
    line-height: 1.2;
    color: #000;
    font-weight: 600;
    @media (max-width: 1024px) {
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      display: none;
    }
  `;
