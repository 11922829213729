import styled from "@emotion/styled";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Menu from "../common/mypagemenu";
import { ChangeDateformat } from "../common/functions";
import dayjs from "dayjs";

export default function MypageComponent() {
  const [membershipText, setMembership] = useState("");
  const [dateText, setDate] = useState("");
  const today = dayjs().format("YYYY-MM-DD");

  useEffect(() => {
    axios
      .get("/api/user/membership/get")
      .then((res) => {
        const user = res.data;
        const MembershipObject = user.Membership;
        const membership = MembershipObject.membership;
        if (dayjs(MembershipObject.endAt).format("YYYY-MM-DD") <= today) {
          setDate("만료됨");
        } else {
          setDate(
            ChangeDateformat(MembershipObject.endAt) +
              "까지" +
              " (" +
              dayjs(MembershipObject.endAt).diff(today, "day") +
              "일 남음)"
          );
        }
        if (membership === 100) {
          setMembership("전체 이용권");
        } else if (membership === 101) {
          setMembership("전체 이용권(화상영어)");
        } else if (membership === 10) {
          setMembership("랜드시리즈 이용권");
        } else if (membership === 20) {
          setMembership("이솝우화 이용권");
        } else if (membership === 30) {
          setMembership("애니사전 이용권");
        } else {
          setMembership("이용권없음");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Menu />
      <Main>
        <MainContainer>
          <div>
            <TextComponent>현재 사용중인 이용권</TextComponent>
          </div>
          <Container>
            <TextComponent>{membershipText}</TextComponent>
            <TextComponent>{dateText}</TextComponent>
          </Container>
          <Link to="/shop">이용권 결제/연장</Link>
        </MainContainer>
      </Main>
    </>
  );
}

const Main = styled.div`
    height: calc(0.8375 * var(--app-height));
    background: #2581b3;
  `,
  MainContainer = styled.div`
    text-align: center;
    margin-top: 50px;
    & > a {
      font-size: 20px;
      font-weight: bold;
      padding: 10px;
      background: #fff;
      color: #2581b3;
      border-radius: 10px;
    }
  `,
  Container = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    margin: auto;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    & > div {
      margin-right: 10px;
    }
  `,
  TextComponent = styled.div`
    font-size: 20px;
    font-weight: bold;
  `;
