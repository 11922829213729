import axios from "axios";

//숫자->금액
export function DecoratePrice(number: number) {
  if (number === 0) return "";
  return number.toLocaleString("ko-KR");
}

export function openWindowPop(url: string, name: string) {
  var options = "width=auto, height=auto, status=no, menubar=no, toolbar=no, resizable=no";
  window.open(url, name, options);
}

/**
 * 전화번호 형식으로 출력
 * @param phoneNumber 전화번호
 * @returns 전화번호 형식으로 출력된 문자열
 */
export function formatKoreanPhoneNumber(phoneNumber: string): string {
  // 숫자를 제외한 모든 문자 제거
  const cleaned = phoneNumber.replace(/\D/g, "");
  console.log(cleaned);

  // 전화번호 길이가 10자리 미만인 경우 유효하지 않음
  if (cleaned.length < 10) {
    return "유효하지 않은 전화번호입니다.";
  }

  // 전화번호를 국가 코드와 구분하여 출력
  const formatted = `${cleaned.substring(0, 3)}-${cleaned.substring(3, 7)}-${cleaned.substring(7)}`;

  return formatted;
}

export function getPresignedUrlAndUpload(file: any, name: string) {
  axios
    .get("/api/upload/admin", {
      params: {
        filename: name,
      },
    })
    .then((response) => {
      const presignedUrl = response.data.presignedUrl;
      uploadFileUsingPresignedUrl(presignedUrl, file);
    })
    .catch((error) => {
      console.error("Presigned URL 가져오기 실패:", error);
    });
}

export async function getPresignedUrlAudio(file: any, name: string) {
  try {
    const response = await axios.get("/api/upload/user", {
      params: {
        filename: name,
      },
    });
    const presignedUrl = response.data.presignedUrl;
    await uploadFileUsingPresignedUrl(presignedUrl, file);
    return true;
  } catch (error) {
    console.error("Presigned URL 가져오기 실패:", error);
    return false;
  }
}

async function uploadFileUsingPresignedUrl(presignedUrl: string, file: any) {
  try {
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": "",
      },
    });
  } catch (error) {
    console.error("파일 업로드 실패:", error);
  }
}

export function generateUniqueFileName(originalName: string) {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 8); // Generate random string of length 6
  const extension = originalName.split(".").pop();
  return `${timestamp}_${randomString}.${extension}`;
}
