import dayjs from "dayjs";
import { sha256 } from "js-sha256";

export default function Receipt(cart: any) {
  // 상점정보 수정하지 마세요
  const now = dayjs();
  const EdiDate = now.format("YYYYMMDDHHmmss");
  //실제
  const MID = "eplyier01m";
  const MerchantKey =
    "iF9jVrppfITqrSFcvfDdBmIH00XPxBaQGG1bcb4P/t4z+CvAhK9P/hq9Wgfz5dZsk/0MYrNLMOGFfElH6UdR5Q==";
  const Moid = now.format("YYYYMMDDHHmmss");
  const Amt = cart
    .map((product: any) => {
      return product.price;
    })
    .reduce((previousValue: number, currentValue: number) => previousValue + currentValue, 0);

  //create cyrpto sha 256
  const SignData = sha256
    .create()
    .update(EdiDate + MID + String(Amt) + MerchantKey)
    .hex();

  const goods = () => {
    if (cart.length === 0) {
      return "장바구니에 담긴 상품이 없습니다.";
    } else if (cart.length === 1) {
      return cart[0].name;
    } else {
      return cart[0].name + " 외 " + (cart.length - 1) + "개";
    }
  };

  return (
    <>
      <input hidden id="GoodsName" name="GoodsName" defaultValue={goods()} />
      <input hidden id="Amt" name="Amt" defaultValue={Amt} />
      <input hidden name="MID" defaultValue={MID} />
      <input hidden id="EdiDate" name="EdiDate" defaultValue={EdiDate} />
      <input hidden id="Moid" name="Moid" defaultValue={Moid} />
      <input hidden id="SignData" name="SignData" defaultValue={SignData} />
      <input hidden name="CharSet" defaultValue="utf-8" />
      <input hidden name="GoodsCl" defaultValue="1" />
      <input hidden name="ReturnURL" defaultValue={"https://www.ieafter.com/api/shop/authReq"} />
    </>
  );
}
