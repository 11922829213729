import HeaderComponent from "../common/header";
import IntroComponent from "./component";

export default function LmsComponent() {
  return (
    <>
      <HeaderComponent />
      <IntroComponent />
    </>
  );
}
