import styled from "@emotion/styled";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import cartStatus from "../state/cart";

export default function SideBar() {
  const [cart, setCart] = useRecoilState(cartStatus);

  useEffect(() => {
    axios.get("/api/shop/cart/get").then((res: any) => {
      setCart(res.data.products); //장바구니 목록가져오기
    });
  }, []);

  const component = (
    <Container>
      <Link to="/cart">
        <ImgContainer>
          <IconSVG></IconSVG>
        </ImgContainer>
        <TextContainer>
          <TextComponent>{cart.length}</TextComponent>
        </TextContainer>
      </Link>
    </Container>
  );
  return component;
}

const Container = styled.div`
    justify-items: center;
    position: fixed;
    top: 200px;
    right: 0;
    width: 160px;
    height: 160px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    z-index: 999;
    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  `,
  ImgContainer = styled.div`
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
  `,
  IconSVG = styled(ShoppingCartIcon)`
    font-size: 10vh;
    display: block;
    margin: 0 auto;
    text-align: center;
    color: var(--color-hover);
    @media (max-width: 768px) {
      font-size: 6vh;
    }
  `,
  TextContainer = styled.div`
    position: absolute;
    top: 2vh;
    right: 2vw;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: var(--color-danger);
    text-align: center;
    align-items: center;
    @media (max-width: 768px) {
      top: 1vh;
      right: 1vw;
      height: 20px;
      width: 20px;
    }
  `,
  TextComponent = styled.span`
    position: relative;
    font-size: 30px;
    line-height: 1.2;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  `;
