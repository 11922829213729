import styled from "@emotion/styled";
import { DecoratePrice } from "../common/functions";

/**
 * 상품 옵션 컴포넌트
 * @param option 상품 옵션
 * @param fetchCart 선택된 상품
 * @param setFetchCart 선택된 상품 변경 함수
 * @returns
 */
export default function OptionComponent(option: [], fetchCart: any[], setFetchCart: any) {
  const handleChange = (e: any) => {
    const value = e.target.value;
    if (fetchCart.indexOf(value) === -1) {
      setFetchCart((prev: any) => [...prev, value]);
    }
  };

  return (
    <SelectContainer>
      <select onChange={handleChange}>
        <option value="">선택</option>
        {option.map((item: any) => {
          return (
            <option value={item.id} key={item.id}>
              {item.name + " / " + DecoratePrice(item.price) + "원"}
            </option>
          );
        })}
      </select>
    </SelectContainer>
  );
}

const SelectContainer = styled.div`
  margin: 16px 0;
  & > select {
    margin-bottom: 10px;
    font-size: 13px;
    width: 100%;
    padding: 0 35px 0 15px;
    display: block;
    text-overflow: ellipsis;
    line-height: 36px;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    height: 34px;
    border: 1px solid #d2d6dd;
    color: #333333;
  }
`;
