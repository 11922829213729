export const landList = [
  { title: "전체", all: true, contents: [1, 48], image: "title/land.png" },
  {
    title: "씨랜드 영어",
    language: "english",
    all: true,
    contents: [1, 16],
    image: "sealand/title.png",
  },
  { title: "- unit 1", language: "english", content: 1, image: "sealand/1.png" },
  { title: "- unit 2", language: "english", content: 2, image: "sealand/2.png" },
  { title: "- unit 3", language: "english", content: 3, image: "sealand/3.png" },
  { title: "- unit 4", language: "english", content: 4, image: "sealand/4.png" },
  { title: "- unit 5", language: "english", content: 5, image: "sealand/5.png" },
  { title: "- unit 6", language: "english", content: 6, image: "sealand/6.png" },
  { title: "- unit 7", language: "english", content: 7, image: "sealand/7.png" },
  { title: "- unit 8", language: "english", content: 8, image: "sealand/8.png" },
  { title: "- unit 9", language: "english", content: 9, image: "sealand/9.png" },
  { title: "- unit 10", language: "english", content: 10, image: "sealand/10.png" },
  { title: "- unit 11", language: "english", content: 11, image: "sealand/11.png" },
  { title: "- unit 12", language: "english", content: 12, image: "sealand/12.png" },
  { title: "- unit 13", language: "english", content: 13, image: "sealand/13.png" },
  { title: "- unit 14", language: "english", content: 14, image: "sealand/14.png" },
  { title: "- unit 15", language: "english", content: 15, image: "sealand/15.png" },
  { title: "- unit 16", language: "english", content: 16, image: "sealand/16.png" },
  {
    title: "씨랜드 중국어",
    language: "chinese",
    all: true,
    contents: [1, 16],
    image: "sealand/title.png",
  },
  { title: "- unit 1", language: "chinese", content: 1, image: "sealand/1.png" },
  { title: "- unit 2", language: "chinese", content: 2, image: "sealand/2.png" },
  { title: "- unit 3", language: "chinese", content: 3, image: "sealand/3.png" },
  { title: "- unit 4", language: "chinese", content: 4, image: "sealand/4.png" },
  { title: "- unit 5", language: "chinese", content: 5, image: "sealand/5.png" },
  { title: "- unit 6", language: "chinese", content: 6, image: "sealand/6.png" },
  { title: "- unit 7", language: "chinese", content: 7, image: "sealand/7.png" },
  { title: "- unit 8", language: "chinese", content: 8, image: "sealand/8.png" },
  { title: "- unit 9", language: "chinese", content: 9, image: "sealand/9.png" },
  { title: "- unit 10", language: "chinese", content: 10, image: "sealand/10.png" },
  { title: "- unit 11", language: "chinese", content: 11, image: "sealand/11.png" },
  { title: "- unit 12", language: "chinese", content: 12, image: "sealand/12.png" },
  { title: "- unit 13", language: "chinese", content: 13, image: "sealand/13.png" },
  { title: "- unit 14", language: "chinese", content: 14, image: "sealand/14.png" },
  { title: "- unit 15", language: "chinese", content: 15, image: "sealand/15.png" },
  { title: "- unit 16", language: "chinese", content: 16, image: "sealand/16.png" },
  {
    title: "씨랜드 일본어",
    language: "japanese",
    all: true,
    contents: [1, 16],
    image: "sealand/title.png",
  },
  { title: "- unit 1", language: "japanese", content: 1, image: "sealand/1.png" },
  { title: "- unit 2", language: "japanese", content: 2, image: "sealand/2.png" },
  { title: "- unit 3", language: "japanese", content: 3, image: "sealand/3.png" },
  { title: "- unit 4", language: "japanese", content: 4, image: "sealand/4.png" },
  { title: "- unit 5", language: "japanese", content: 5, image: "sealand/5.png" },
  { title: "- unit 6", language: "japanese", content: 6, image: "sealand/6.png" },
  { title: "- unit 7", language: "japanese", content: 7, image: "sealand/7.png" },
  { title: "- unit 8", language: "japanese", content: 8, image: "sealand/8.png" },
  { title: "- unit 9", language: "japanese", content: 9, image: "sealand/9.png" },
  { title: "- unit 10", language: "japanese", content: 10, image: "sealand/10.png" },
  { title: "- unit 11", language: "japanese", content: 11, image: "sealand/11.png" },
  { title: "- unit 12", language: "japanese", content: 12, image: "sealand/12.png" },
  { title: "- unit 13", language: "japanese", content: 13, image: "sealand/13.png" },
  { title: "- unit 14", language: "japanese", content: 14, image: "sealand/14.png" },
  { title: "- unit 15", language: "japanese", content: 15, image: "sealand/15.png" },
  { title: "- unit 16", language: "japanese", content: 16, image: "sealand/16.png" },
  {
    title: "스윗랜드 영어",
    language: "english",
    all: true,
    contents: [17, 32],
    image: "sweetland/title.png",
  },
  { title: "- unit 1", language: "english", content: 17, image: "sweetland/1.png" },
  { title: "- unit 2", language: "english", content: 18, image: "sweetland/2.png" },
  { title: "- unit 3", language: "english", content: 19, image: "sweetland/3.png" },
  { title: "- unit 4", language: "english", content: 20, image: "sweetland/4.png" },
  { title: "- unit 5", language: "english", content: 21, image: "sweetland/5.png" },
  { title: "- unit 6", language: "english", content: 22, image: "sweetland/6.png" },
  { title: "- unit 7", language: "english", content: 23, image: "sweetland/7.png" },
  { title: "- unit 8", language: "english", content: 24, image: "sweetland/8.png" },
  { title: "- unit 9", language: "english", content: 25, image: "sweetland/9.png" },
  { title: "- unit 10", language: "english", content: 26, image: "sweetland/10.png" },
  { title: "- unit 11", language: "english", content: 27, image: "sweetland/11.png" },
  { title: "- unit 12", language: "english", content: 28, image: "sweetland/12.png" },
  { title: "- unit 13", language: "english", content: 29, image: "sweetland/13.png" },
  { title: "- unit 14", language: "english", content: 30, image: "sweetland/14.png" },
  { title: "- unit 15", language: "english", content: 31, image: "sweetland/15.png" },
  { title: "- unit 16", language: "english", content: 32, image: "sweetland/16.png" },
  {
    title: "스윗랜드 중국어",
    language: "chinese",
    all: true,
    contents: [17, 32],
    image: "sweetland/title.png",
  },
  { title: "- unit 1", language: "chinese", content: 17, image: "sweetland/1.png" },
  { title: "- unit 2", language: "chinese", content: 18, image: "sweetland/2.png" },
  { title: "- unit 3", language: "chinese", content: 19, image: "sweetland/3.png" },
  { title: "- unit 4", language: "chinese", content: 20, image: "sweetland/4.png" },
  { title: "- unit 5", language: "chinese", content: 21, image: "sweetland/5.png" },
  { title: "- unit 6", language: "chinese", content: 22, image: "sweetland/6.png" },
  { title: "- unit 7", language: "chinese", content: 23, image: "sweetland/7.png" },
  { title: "- unit 8", language: "chinese", content: 24, image: "sweetland/8.png" },
  { title: "- unit 9", language: "chinese", content: 25, image: "sweetland/9.png" },
  { title: "- unit 10", language: "chinese", content: 26, image: "sweetland/10.png" },
  { title: "- unit 11", language: "chinese", content: 27, image: "sweetland/11.png" },
  { title: "- unit 12", language: "chinese", content: 28, image: "sweetland/12.png" },
  { title: "- unit 13", language: "chinese", content: 29, image: "sweetland/13.png" },
  { title: "- unit 14", language: "chinese", content: 30, image: "sweetland/14.png" },
  { title: "- unit 15", language: "chinese", content: 31, image: "sweetland/15.png" },
  { title: "- unit 16", language: "chinese", content: 32, image: "sweetland/16.png" },
  {
    title: "스윗랜드 일본어",
    language: "japanese",
    all: true,
    contents: [17, 32],
    image: "sweetland/title.png",
  },
  { title: "- unit 1", language: "japanese", content: 17, image: "sweetland/1.png" },
  { title: "- unit 2", language: "japanese", content: 18, image: "sweetland/2.png" },
  { title: "- unit 3", language: "japanese", content: 19, image: "sweetland/3.png" },
  { title: "- unit 4", language: "japanese", content: 20, image: "sweetland/4.png" },
  { title: "- unit 5", language: "japanese", content: 21, image: "sweetland/5.png" },
  { title: "- unit 6", language: "japanese", content: 22, image: "sweetland/6.png" },
  { title: "- unit 7", language: "japanese", content: 23, image: "sweetland/7.png" },
  { title: "- unit 8", language: "japanese", content: 24, image: "sweetland/8.png" },
  { title: "- unit 9", language: "japanese", content: 25, image: "sweetland/9.png" },
  { title: "- unit 10", language: "japanese", content: 26, image: "sweetland/10.png" },
  { title: "- unit 11", language: "japanese", content: 27, image: "sweetland/11.png" },
  { title: "- unit 12", language: "japanese", content: 28, image: "sweetland/12.png" },
  { title: "- unit 13", language: "japanese", content: 29, image: "sweetland/13.png" },
  { title: "- unit 14", language: "japanese", content: 30, image: "sweetland/14.png" },
  { title: "- unit 15", language: "japanese", content: 31, image: "sweetland/15.png" },
  { title: "- unit 16", language: "japanese", content: 32, image: "sweetland/16.png" },
  {
    title: "리빙랜드 영어",
    language: "english",
    all: true,
    contents: [33, 48],
    image: "livingland/title.png",
  },
  { title: "- unit 1", language: "english", content: 33, image: "livingland/1.png" },
  { title: "- unit 2", language: "english", content: 34, image: "livingland/2.png" },
  { title: "- unit 3", language: "english", content: 35, image: "livingland/3.png" },
  { title: "- unit 4", language: "english", content: 36, image: "livingland/4.png" },
  { title: "- unit 5", language: "english", content: 37, image: "livingland/5.png" },
  { title: "- unit 6", language: "english", content: 38, image: "livingland/6.png" },
  { title: "- unit 7", language: "english", content: 39, image: "livingland/7.png" },
  { title: "- unit 8", language: "english", content: 40, image: "livingland/8.png" },
  { title: "- unit 9", language: "english", content: 41, image: "livingland/9.png" },
  { title: "- unit 10", language: "english", content: 42, image: "livingland/10.png" },
  { title: "- unit 11", language: "english", content: 43, image: "livingland/11.png" },
  { title: "- unit 12", language: "english", content: 44, image: "livingland/12.png" },
  { title: "- unit 13", language: "english", content: 45, image: "livingland/13.png" },
  { title: "- unit 14", language: "english", content: 46, image: "livingland/14.png" },
  { title: "- unit 15", language: "english", content: 47, image: "livingland/15.png" },
  { title: "- unit 16", language: "english", content: 48, image: "livingland/16.png" },
  {
    title: "리빙랜드 중국어",
    language: "chinese",
    all: true,
    contents: [33, 48],
    image: "livingland/title.png",
  },
  { title: "- unit 1", language: "chinese", content: 33, image: "livingland/1.png" },
  { title: "- unit 2", language: "chinese", content: 34, image: "livingland/2.png" },
  { title: "- unit 3", language: "chinese", content: 35, image: "livingland/3.png" },
  { title: "- unit 4", language: "chinese", content: 36, image: "livingland/4.png" },
  { title: "- unit 5", language: "chinese", content: 37, image: "livingland/5.png" },
  { title: "- unit 6", language: "chinese", content: 38, image: "livingland/6.png" },
  { title: "- unit 7", language: "chinese", content: 39, image: "livingland/7.png" },
  { title: "- unit 8", language: "chinese", content: 40, image: "livingland/8.png" },
  { title: "- unit 9", language: "chinese", content: 41, image: "livingland/9.png" },
  { title: "- unit 10", language: "chinese", content: 42, image: "livingland/10.png" },
  { title: "- unit 11", language: "chinese", content: 43, image: "livingland/11.png" },
  { title: "- unit 12", language: "chinese", content: 44, image: "livingland/12.png" },
  { title: "- unit 13", language: "chinese", content: 45, image: "livingland/13.png" },
  { title: "- unit 14", language: "chinese", content: 46, image: "livingland/14.png" },
  { title: "- unit 15", language: "chinese", content: 47, image: "livingland/15.png" },
  { title: "- unit 16", language: "chinese", content: 48, image: "livingland/16.png" },
];
export const aesopList = [
  { title: "전체", all: true, contents: [49, 88], image: "title/aesop.jpg" },
  {
    title: "이솝우화 시즌1 영어",
    language: "english",
    all: true,
    contents: [49, 58],
    image: "aesop/title1.png",
  },
  { title: "- unit 1", language: "english", content: 49, image: "aesop/1.png" },
  { title: "- unit 2", language: "english", content: 50, image: "aesop/2.png" },
  { title: "- unit 3", language: "english", content: 51, image: "aesop/3.png" },
  { title: "- unit 4", language: "english", content: 52, image: "aesop/4.png" },
  { title: "- unit 5", language: "english", content: 53, image: "aesop/5.png" },
  { title: "- unit 6", language: "english", content: 54, image: "aesop/6.png" },
  { title: "- unit 7", language: "english", content: 55, image: "aesop/7.png" },
  { title: "- unit 8", language: "english", content: 56, image: "aesop/8.png" },
  { title: "- unit 9", language: "english", content: 57, image: "aesop/9.png" },
  { title: "- unit 10", language: "english", content: 58, image: "aesop/10.png" },
  {
    title: "이솝우화 시즌1 중국어",
    language: "chinese",
    all: true,
    contents: [49, 58],
    image: "aesop/title1.png",
  },
  { title: "- unit 1", language: "chinese", content: 49, image: "aesop/1.png" },
  { title: "- unit 2", language: "chinese", content: 50, image: "aesop/2.png" },
  { title: "- unit 3", language: "chinese", content: 51, image: "aesop/3.png" },
  { title: "- unit 4", language: "chinese", content: 52, image: "aesop/4.png" },
  { title: "- unit 5", language: "chinese", content: 53, image: "aesop/5.png" },
  { title: "- unit 6", language: "chinese", content: 54, image: "aesop/6.png" },
  { title: "- unit 7", language: "chinese", content: 55, image: "aesop/7.png" },
  { title: "- unit 8", language: "chinese", content: 56, image: "aesop/8.png" },
  { title: "- unit 9", language: "chinese", content: 57, image: "aesop/9.png" },
  { title: "- unit 10", language: "chinese", content: 58, image: "aesop/10.png" },
  {
    title: "이솝우화 시즌2 영어",
    language: "english",
    all: true,
    contents: [59, 68],
    image: "aesop/title2.png",
  },
  { title: "- unit 1", language: "english", content: 59, image: "aesop/11.png" },
  { title: "- unit 2", language: "english", content: 60, image: "aesop/12.png" },
  { title: "- unit 3", language: "english", content: 61, image: "aesop/13.png" },
  { title: "- unit 4", language: "english", content: 62, image: "aesop/14.png" },
  { title: "- unit 5", language: "english", content: 63, image: "aesop/15.png" },
  { title: "- unit 6", language: "english", content: 64, image: "aesop/16.png" },
  { title: "- unit 7", language: "english", content: 65, image: "aesop/17.png" },
  { title: "- unit 8", language: "english", content: 66, image: "aesop/18.png" },
  { title: "- unit 9", language: "english", content: 67, image: "aesop/19.png" },
  { title: "- unit 10", language: "english", content: 68, image: "aesop/20.png" },
  {
    title: "이솝우화 시즌2 중국어",
    language: "chinese",
    all: true,
    contents: [59, 68],
    image: "aesop/title2.png",
  },
  { title: "- unit 1", language: "chinese", content: 59, image: "aesop/11.png" },
  { title: "- unit 2", language: "chinese", content: 60, image: "aesop/12.png" },
  { title: "- unit 3", language: "chinese", content: 61, image: "aesop/13.png" },
  { title: "- unit 4", language: "chinese", content: 62, image: "aesop/14.png" },
  { title: "- unit 5", language: "chinese", content: 63, image: "aesop/15.png" },
  { title: "- unit 6", language: "chinese", content: 64, image: "aesop/16.png" },
  { title: "- unit 7", language: "chinese", content: 65, image: "aesop/17.png" },
  { title: "- unit 8", language: "chinese", content: 66, image: "aesop/18.png" },
  { title: "- unit 9", language: "chinese", content: 67, image: "aesop/19.png" },
  { title: "- unit 10", language: "chinese", content: 68, image: "aesop/20.png" },
  {
    title: "이솝우화 시즌3 영어",
    language: "english",
    all: true,
    contents: [69, 78],
    image: "aesop/title3.png",
  },
  { title: "- unit 1", language: "english", content: 69, image: "aesop/21.jpg" },
  { title: "- unit 2", language: "english", content: 70, image: "aesop/22.jpg" },
  { title: "- unit 3", language: "english", content: 71, image: "aesop/23.jpg" },
  { title: "- unit 4", language: "english", content: 72, image: "aesop/24.jpg" },
  { title: "- unit 5", language: "english", content: 73, image: "aesop/25.jpg" },
  { title: "- unit 6", language: "english", content: 74, image: "aesop/26.jpg" },
  { title: "- unit 7", language: "english", content: 75, image: "aesop/27.jpg" },
  { title: "- unit 8", language: "english", content: 76, image: "aesop/28.jpg" },
  { title: "- unit 9", language: "english", content: 77, image: "aesop/29.jpg" },
  { title: "- unit 10", language: "english", content: 78, image: "aesop/30.jpg" },
  {
    title: "이솝우화 시즌3 중국어",
    language: "chinese",
    all: true,
    contents: [69, 78],
    image: "aesop/title3.png",
  },
  { title: "- unit 1", language: "chinese", content: 69, image: "aesop/21.jpg" },
  { title: "- unit 2", language: "chinese", content: 70, image: "aesop/22.jpg" },
  { title: "- unit 3", language: "chinese", content: 71, image: "aesop/23.jpg" },
  { title: "- unit 4", language: "chinese", content: 72, image: "aesop/24.jpg" },
  { title: "- unit 5", language: "chinese", content: 73, image: "aesop/25.jpg" },
  { title: "- unit 6", language: "chinese", content: 74, image: "aesop/26.jpg" },
  { title: "- unit 7", language: "chinese", content: 75, image: "aesop/27.jpg" },
  { title: "- unit 8", language: "chinese", content: 76, image: "aesop/28.jpg" },
  { title: "- unit 9", language: "chinese", content: 77, image: "aesop/29.jpg" },
  { title: "- unit 10", language: "chinese", content: 78, image: "aesop/30.jpg" },
  {
    title: "이솝우화 시즌4 영어",
    language: "english",
    all: true,
    contents: [79, 88],
    image: "aesop/title4.png",
  },
  { title: "- unit 1", language: "english", content: 79, image: "aesop/31.png" },
  { title: "- unit 2", language: "english", content: 80, image: "aesop/32.png" },
  { title: "- unit 3", language: "english", content: 81, image: "aesop/33.png" },
  { title: "- unit 4", language: "english", content: 82, image: "aesop/34.png" },
  { title: "- unit 5", language: "english", content: 83, image: "aesop/35.png" },
  { title: "- unit 6", language: "english", content: 84, image: "aesop/36.png" },
  { title: "- unit 7", language: "english", content: 85, image: "aesop/37.png" },
  { title: "- unit 8", language: "english", content: 86, image: "aesop/38.png" },
  { title: "- unit 9", language: "english", content: 87, image: "aesop/39.png" },
  { title: "- unit 10", language: "english", content: 88, image: "aesop/40.png" },
  {
    title: "이솝우화 시즌4 중국어",
    language: "chinese",
    all: true,
    contents: [79, 88],
    image: "aesop/title4.png",
  },
  { title: "- unit 1", language: "chinese", content: 79, image: "aesop/31.png" },
  { title: "- unit 2", language: "chinese", content: 80, image: "aesop/32.png" },
  { title: "- unit 3", language: "chinese", content: 81, image: "aesop/33.png" },
  { title: "- unit 4", language: "chinese", content: 82, image: "aesop/34.png" },
  { title: "- unit 5", language: "chinese", content: 83, image: "aesop/35.png" },
  { title: "- unit 6", language: "chinese", content: 84, image: "aesop/36.png" },
  { title: "- unit 7", language: "chinese", content: 85, image: "aesop/37.png" },
  { title: "- unit 8", language: "chinese", content: 86, image: "aesop/38.png" },
  { title: "- unit 9", language: "chinese", content: 87, image: "aesop/39.png" },
  { title: "- unit 10", language: "chinese", content: 88, image: "aesop/40.png" },
];
export const dictList = [
  { title: "전체", all: true, contents: [89, 127], image: "title/dict.jpg" },
  {
    title: "애니메이션 사전 시즌1 영어",
    language: "english",
    all: true,
    contents: [89, 96],
    image: "dict/title1.png",
  },
  { title: "- unit 1", language: "english", content: 89, image: "dict/1.png" },
  { title: "- unit 2", language: "english", content: 90, image: "dict/2.png" },
  { title: "- unit 3", language: "english", content: 91, image: "dict/3.png" },
  { title: "- unit 4", language: "english", content: 92, image: "dict/4.png" },
  { title: "- unit 5", language: "english", content: 93, image: "dict/5.png" },
  { title: "- unit 6", language: "english", content: 94, image: "dict/6.png" },
  { title: "- unit 7", language: "english", content: 95, image: "dict/7.png" },
  { title: "- unit 8", language: "english", content: 96, image: "dict/8.png" },
  {
    title: "애니메이션 사전 시즌1 중국어",
    language: "chinese",
    all: true,
    contents: [89, 96],
    image: "dict/title1.png",
  },
  { title: "- unit 1", language: "chinese", content: 89, image: "dict/1.png" },
  { title: "- unit 2", language: "chinese", content: 90, image: "dict/2.png" },
  { title: "- unit 3", language: "chinese", content: 91, image: "dict/3.png" },
  { title: "- unit 4", language: "chinese", content: 92, image: "dict/4.png" },
  { title: "- unit 5", language: "chinese", content: 93, image: "dict/5.png" },
  { title: "- unit 6", language: "chinese", content: 94, image: "dict/6.png" },
  { title: "- unit 7", language: "chinese", content: 95, image: "dict/7.png" },
  { title: "- unit 8", language: "chinese", content: 96, image: "dict/8.png" },
  {
    title: "애니메이션 사전 시즌1 일본어",
    language: "japanese",
    all: true,
    contents: [89, 96],
    image: "dict/title1.png",
  },
  { title: "- unit 1", language: "japanese", content: 89, image: "dict/1.png" },
  { title: "- unit 2", language: "japanese", content: 90, image: "dict/2.png" },
  { title: "- unit 3", language: "japanese", content: 91, image: "dict/3.png" },
  { title: "- unit 4", language: "japanese", content: 92, image: "dict/4.png" },
  { title: "- unit 5", language: "japanese", content: 93, image: "dict/5.png" },
  { title: "- unit 6", language: "japanese", content: 94, image: "dict/6.png" },
  { title: "- unit 7", language: "japanese", content: 95, image: "dict/7.png" },
  { title: "- unit 8", language: "japanese", content: 96, image: "dict/8.png" },
  {
    title: "애니메이션 사전 시즌2 영어",
    language: "english",
    all: true,
    contents: [97, 103],
    image: "dict/title2.png",
  },
  { title: "- unit 1", language: "english", content: 97, image: "dict/9.png" },
  { title: "- unit 2", language: "english", content: 98, image: "dict/10.png" },
  { title: "- unit 3", language: "english", content: 99, image: "dict/11.png" },
  { title: "- unit 4", language: "english", content: 100, image: "dict/12.png" },
  { title: "- unit 5", language: "english", content: 101, image: "dict/13.png" },
  { title: "- unit 6", language: "english", content: 102, image: "dict/14.png" },
  { title: "- unit 7", language: "english", content: 103, image: "dict/15.png" },
  {
    title: "애니메이션 사전 시즌2 중국어",
    language: "chinese",
    all: true,
    contents: [97, 103],
    image: "dict/title2.png",
  },
  { title: "- unit 1", language: "chinese", content: 97, image: "dict/9.png" },
  { title: "- unit 2", language: "chinese", content: 98, image: "dict/10.png" },
  { title: "- unit 3", language: "chinese", content: 99, image: "dict/11.png" },
  { title: "- unit 4", language: "chinese", content: 100, image: "dict/12.png" },
  { title: "- unit 5", language: "chinese", content: 101, image: "dict/13.png" },
  { title: "- unit 6", language: "chinese", content: 102, image: "dict/14.png" },
  { title: "- unit 7", language: "chinese", content: 103, image: "dict/15.png" },
  {
    title: "애니메이션 사전 시즌2 일본어",
    language: "japanese",
    all: true,
    contents: [97, 103],
    image: "dict/title2.png",
  },
  { title: "- unit 1", language: "japanese", content: 97, image: "dict/9.png" },
  { title: "- unit 2", language: "japanese", content: 98, image: "dict/10.png" },
  { title: "- unit 3", language: "japanese", content: 99, image: "dict/11.png" },
  { title: "- unit 4", language: "japanese", content: 100, image: "dict/12.png" },
  { title: "- unit 5", language: "japanese", content: 101, image: "dict/13.png" },
  { title: "- unit 6", language: "japanese", content: 102, image: "dict/14.png" },
  { title: "- unit 7", language: "japanese", content: 103, image: "dict/15.png" },
  {
    title: "애니메이션 사전 시즌3 영어",
    language: "english",
    all: true,
    contents: [104, 109],
    image: "dict/title3.png",
  },
  { title: "- unit 1", language: "english", content: 104, image: "dict/16.png" },
  { title: "- unit 2", language: "english", content: 105, image: "dict/17.png" },
  { title: "- unit 3", language: "english", content: 106, image: "dict/18.png" },
  { title: "- unit 4", language: "english", content: 107, image: "dict/19.png" },
  { title: "- unit 5", language: "english", content: 108, image: "dict/20.png" },
  { title: "- unit 6", language: "english", content: 109, image: "dict/21.png" },
  {
    title: "애니메이션 사전 시즌3 중국어",
    language: "chinese",
    all: true,
    contents: [104, 109],
    image: "dict/title3.png",
  },
  { title: "- unit 1", language: "chinese", content: 104, image: "dict/16.png" },
  { title: "- unit 2", language: "chinese", content: 105, image: "dict/17.png" },
  { title: "- unit 3", language: "chinese", content: 106, image: "dict/18.png" },
  { title: "- unit 4", language: "chinese", content: 107, image: "dict/19.png" },
  { title: "- unit 5", language: "chinese", content: 108, image: "dict/20.png" },
  { title: "- unit 6", language: "chinese", content: 109, image: "dict/21.png" },
  {
    title: "애니메이션 사전 시즌3 일본어",
    language: "japanese",
    all: true,
    contents: [104, 109],
    image: "dict/title3.png",
  },
  { title: "- unit 1", language: "japanese", content: 104, image: "dict/16.png" },
  { title: "- unit 2", language: "japanese", content: 105, image: "dict/17.png" },
  { title: "- unit 3", language: "japanese", content: 106, image: "dict/18.png" },
  { title: "- unit 4", language: "japanese", content: 107, image: "dict/19.png" },
  { title: "- unit 5", language: "japanese", content: 108, image: "dict/20.png" },
  { title: "- unit 6", language: "japanese", content: 109, image: "dict/21.png" },
  {
    title: "애니메이션 사전 시즌4 영어",
    language: "english",
    all: true,
    contents: [110, 115],
    image: "dict/title4.png",
  },
  { title: "- unit 1", language: "english", content: 110, image: "dict/22.png" },
  { title: "- unit 2", language: "english", content: 111, image: "dict/23.png" },
  { title: "- unit 3", language: "english", content: 112, image: "dict/24.png" },
  { title: "- unit 4", language: "english", content: 113, image: "dict/25.png" },
  { title: "- unit 5", language: "english", content: 114, image: "dict/26.png" },
  { title: "- unit 6", language: "english", content: 115, image: "dict/27.png" },
  {
    title: "애니메이션 사전 시즌4 중국어",
    language: "chinese",
    all: true,
    contents: [110, 115],
    image: "dict/title4.png",
  },
  { title: "- unit 1", language: "chinese", content: 110, image: "dict/22.png" },
  { title: "- unit 2", language: "chinese", content: 111, image: "dict/23.png" },
  { title: "- unit 3", language: "chinese", content: 112, image: "dict/24.png" },
  { title: "- unit 4", language: "chinese", content: 113, image: "dict/25.png" },
  { title: "- unit 5", language: "chinese", content: 114, image: "dict/26.png" },
  { title: "- unit 6", language: "chinese", content: 115, image: "dict/27.png" },
  {
    title: "애니메이션 사전 시즌4 일본어",
    language: "japanese",
    all: true,
    contents: [110, 115],
    image: "dict/title4.png",
  },
  { title: "- unit 1", language: "japanese", content: 110, image: "dict/22.png" },
  { title: "- unit 2", language: "japanese", content: 111, image: "dict/23.png" },
  { title: "- unit 3", language: "japanese", content: 112, image: "dict/24.png" },
  { title: "- unit 4", language: "japanese", content: 113, image: "dict/25.png" },
  { title: "- unit 5", language: "japanese", content: 114, image: "dict/26.png" },
  { title: "- unit 6", language: "japanese", content: 115, image: "dict/27.png" },
  {
    title: "애니메이션 사전 시즌5 영어",
    language: "english",
    all: true,
    contents: [116, 121],
    image: "dict/title5.png",
  },
  { title: "- unit 1", language: "english", content: 116, image: "dict/28.png" },
  { title: "- unit 2", language: "english", content: 117, image: "dict/29.png" },
  { title: "- unit 3", language: "english", content: 118, image: "dict/30.png" },
  { title: "- unit 4", language: "english", content: 119, image: "dict/31.png" },
  { title: "- unit 5", language: "english", content: 120, image: "dict/32.png" },
  { title: "- unit 6", language: "english", content: 121, image: "dict/33.png" },
  {
    title: "애니메이션 사전 시즌5 중국어",
    language: "chinese",
    all: true,
    contents: [116, 121],
    image: "dict/title5.png",
  },
  { title: "- unit 1", language: "chinese", content: 116, image: "dict/28.png" },
  { title: "- unit 2", language: "chinese", content: 117, image: "dict/29.png" },
  { title: "- unit 3", language: "chinese", content: 118, image: "dict/30.png" },
  { title: "- unit 4", language: "chinese", content: 119, image: "dict/31.png" },
  { title: "- unit 5", language: "chinese", content: 120, image: "dict/32.png" },
  { title: "- unit 6", language: "chinese", content: 121, image: "dict/33.png" },
  {
    title: "애니메이션 사전 시즌5 일본어",
    language: "japanese",
    all: true,
    contents: [116, 121],
    image: "dict/title5.png",
  },
  { title: "- unit 1", language: "japanese", content: 116, image: "dict/28.png" },
  { title: "- unit 2", language: "japanese", content: 117, image: "dict/29.png" },
  { title: "- unit 3", language: "japanese", content: 118, image: "dict/30.png" },
  { title: "- unit 4", language: "japanese", content: 119, image: "dict/31.png" },
  { title: "- unit 5", language: "japanese", content: 120, image: "dict/32.png" },
  { title: "- unit 6", language: "japanese", content: 121, image: "dict/33.png" },
  {
    title: "애니메이션 사전 시즌6 영어",
    language: "english",
    all: true,
    contents: [122, 127],
    image: "dict/title6.png",
  },
  { title: "- unit 1", language: "english", content: 122, image: "dict/34.png" },
  { title: "- unit 2", language: "english", content: 123, image: "dict/35.png" },
  { title: "- unit 3", language: "english", content: 124, image: "dict/36.png" },
  { title: "- unit 4", language: "english", content: 125, image: "dict/37.png" },
  { title: "- unit 5", language: "english", content: 126, image: "dict/38.png" },
  { title: "- unit 6", language: "english", content: 127, image: "dict/39.png" },
  {
    title: "애니메이션 사전 시즌6 중국어",
    language: "chinese",
    all: true,
    contents: [122, 127],
    image: "dict/title6.png",
  },
  { title: "- unit 1", language: "chinese", content: 122, image: "dict/34.png" },
  { title: "- unit 2", language: "chinese", content: 123, image: "dict/35.png" },
  { title: "- unit 3", language: "chinese", content: 124, image: "dict/36.png" },
  { title: "- unit 4", language: "chinese", content: 125, image: "dict/37.png" },
  { title: "- unit 5", language: "chinese", content: 126, image: "dict/38.png" },
  { title: "- unit 6", language: "chinese", content: 127, image: "dict/39.png" },
  {
    title: "애니메이션 사전 시즌6 일본어",
    language: "japanese",
    all: true,
    contents: [122, 127],
    image: "dict/title6.png",
  },
  { title: "- unit 1", language: "japanese", content: 122, image: "dict/34.png" },
  { title: "- unit 2", language: "japanese", content: 123, image: "dict/35.png" },
  { title: "- unit 3", language: "japanese", content: 124, image: "dict/36.png" },
  { title: "- unit 4", language: "japanese", content: 125, image: "dict/37.png" },
  { title: "- unit 5", language: "japanese", content: 126, image: "dict/38.png" },
  { title: "- unit 6", language: "japanese", content: 127, image: "dict/39.png" },
];
