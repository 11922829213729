import styled from "@emotion/styled";
import post from "../common/post";

export default function UserInfoContainer(user: any) {
  return (
    <Container>
      <SignFormContanier>
        <LabelContainer>
          <label>성함</label>
          <SignInput
            name="BuyerName"
            type="text"
            required
            autoComplete="off"
            defaultValue={user.name}
          />
        </LabelContainer>
        <LabelContainer>
          <label>전화번호</label>
          <SignInput
            name="BuyerTel"
            type="tel"
            required
            autoComplete="off"
            defaultValue={user.tel}
          />
        </LabelContainer>
        <LabelContainer>
          <label>주소</label>
          <SignInput name="postcode" type="text" id="postcode" defaultValue={user.postcode} />
          <button
            onClick={(e) => {
              e.preventDefault();
              post();
            }}>
            주소찾기
          </button>
          <input
            name="roadAddress"
            type="text"
            placeholder="도로명 주소"
            id="roadAddress"
            defaultValue={user.address1}
          />
          <input
            name="address"
            type="text"
            placeholder="나머지 주소를 입력해 주세요."
            defaultValue={user.address2}
          />
        </LabelContainer>
      </SignFormContanier>
    </Container>
  );
}

const Container = styled.div`
    width: 100%;
    max-width: 1024px;
    padding: 30px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: 0px 3px 9px rgb(0 0 0 / 5%);
    margin-top: 20px;
  `,
  SignFormContanier = styled.div`
    display: block;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.76px;
    color: #303030;
  `,
  LabelContainer = styled.div`
    position: relative;
    margin: 10px auto;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 3fr 1fr 2fr;
    grid-auto-rows: minmax(0, auto);
    grid-template-rows: 23px 46px;
    grid-template-areas:
      "a a a"
      "b b c";
    & > button {
      background: var(--color-main);
      color: white;
      border-radius: 20px;
      font-size: 18px;
      width: 70%;
      margin: 0 auto;
    }
    &:nth-of-type(3) {
      grid-template-areas:
        "a a a"
        "b b c"
        "d d d"
        "f f f";
      & > button {
        grid-area: c;
      }
      & > input {
        border-radius: 2px;
        border: 1px solid #d9dbe2;
        padding: 9px;
        letter-spacing: -0.88px;
      }
      & > input:first-of-type {
        grid-area: b;
      }
      & > input:nth-of-type(2) {
        grid-area: d;
      }
      & > input:last-of-type {
        grid-area: f;
      }
    }
    & > label {
      margin: auto 0;
      grid-area: a;
      & > span {
        color: var(--color-danger);
      }
      cursor: default;
    }
    & > div {
      margin-left: 10px;
      grid-area: c;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    & > select {
      grid-area: b;
      border-radius: 2px;
      border: 1px solid #d9dbe2;
      padding: 9px;
      letter-spacing: -0.88px;
      z-index: 2;
    }
  `,
  SignInput = styled.input`
    border-radius: 2px;
    border: 1px solid #d9dbe2;
    padding: 9px;
    letter-spacing: -0.88px;
    grid-area: b;
  `;
