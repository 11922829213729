import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useState } from "react";
import axios from "axios";
import MenuIcon from "@mui/icons-material/Menu";
import authState from "../state/auth";
import { useRecoilState } from "recoil";

export default function Mobile() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const style = {
    menu: {
      display: isMenuOpen ? "block" : "none",
    },
  };
  return (
    <>
      <MenuContainer>
        <Menu onClick={handleClick} />
      </MenuContainer>
      <NavMobileContainer style={style.menu}>
        <ul>
          {list.map((item, index) => {
            return (
              <li key={index} onClick={handleClick}>
                <NavMenu to={item.url}>{item.text}</NavMenu>
              </li>
            );
          })}
          <li onClick={handleClick}>
            <AuthComponent />
          </li>
        </ul>
      </NavMobileContainer>
    </>
  );
}

function AuthComponent() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(authState);

  return isLoggedIn.state ? (
    <LoginComponent>
      <TopsignMenu to="/lms/mypage">마이페이지</TopsignMenu>
      <TopLogout
        onClick={() => {
          axios
            .post("/api/auth/out")
            .then((res) => {
              setIsLoggedIn({
                id: "",
                state: false,
              });
              localStorage.setItem("userIndex", res.data.id);
              localStorage.setItem("userName", res.data.name);
            })
            .catch((err) => {
              console.log(err);
            });
        }}>
        로그아웃
      </TopLogout>
    </LoginComponent>
  ) : (
    <TopsignMenu to="/login">로그인</TopsignMenu>
  );
}

const list = [
  { text: "home", url: "/" },
  { text: "학습하기", url: "/lms/profile" },
  { text: "컨텐츠소개", url: "/introduce" },
  { text: "이용권/교재", url: "/shop" },
  { text: "고객센터", url: "/qna" },
];

const MenuContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: none;
    @media (max-width: 985px) {
      display: block;
    }
  `,
  Menu = styled(MenuIcon)`
    font-size: 3rem;
    color: var(--color-main);
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
  `,
  NavMenu = styled(Link)`
    display: block;
    padding: 15px;
    position: relative;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 50px;
    color: #000;
  `,
  NavMobileContainer = styled.nav`
    width: 100%;
    position: absolute;
    z-index: 999;
    background: #fff;
    & > ul > li {
      border-bottom: 1px solid #ccc;
      text-align: center;
      &:first-of-type {
        border-top: 1px solid #ccc;
      }
    }
    @media (min-width: 985px) {
      display: none;
    }
  `,
  LoginComponent = styled.div`
    display: flex;
    & > div {
      width: 50%;
      text-align: center;
    }
    & > a {
      width: 50%;
      text-align: center;
    }
  `,
  TopsignMenu = styled(Link)`
    display: block;
    padding: 15px;
    position: relative;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
  `,
  TopLogout = styled.button`
    width: 100%;
    background: none;
    font-size: 17px;
    height: 100%;
    padding: 15px;
    position: relative;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
  `;
