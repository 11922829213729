import { atom } from "recoil";

export default atom({
  key: "cartStatus", // unique ID (with respect to other atoms/selectors)
  default: [
    {
      name: "",
      price: 0,
    },
  ], // default value (aka initial value)
});
