import HeaderComponent from "../common/header";
import SeriesComponent from "./component";

export default function LmsComponent() {
  return (
    <>
      <HeaderComponent />
      <SeriesComponent />
    </>
  );
}
