import styled from "@emotion/styled";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import post from "../common/post";
import Menu from "../common/mypagemenu";
import { formatKoreanPhoneNumber } from "../../common/functions";

export default function MypageComponent() {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    id: "",
    name: "",
    postcode: "",
    tel: "",
    userId: "",
    mail: "",
    address1: "",
    address2: "",
  });

  useEffect(() => {
    axios
      .get("/api/user/get")
      .then((res) => {
        const user = res.data;
        setInfo({
          id: user.data,
          name: user.name,
          postcode: user.postcode,
          tel: user.tel,
          userId: user.userId,
          mail: user.mail,
          address1: user.address1,
          address2: user.address2,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClickEdit = (e: any) => {
    const [userId, name, postcode, address1, address2, tel, mail] = [
      e.target.userId.value,
      e.target.name.value,
      e.target.postcode.value,
      e.target.roadAddress.value,
      e.target.address2.value,
      e.target.tel.value,
      e.target.mail.value,
    ];
    axios
      .put("/api/user/update", {
        userId,
        name,
        postcode,
        address1,
        address2,
        tel,
        mail,
      })
      .then((res) => {
        if (res.data) {
          navigate("/lms/mypage");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    e.preventDefault();
  };

  return (
    <>
      <Menu />
      <Main>
        <MainContainer>
          <form onSubmit={handleClickEdit}>
            <InputContainer>
              <div>
                <label htmlFor="userId">아이디</label>
                <input disabled placeholder={info.userId} id="userId" defaultValue={info.userId} />
              </div>
              <div>
                <label htmlFor="name">이름</label>
                <input placeholder={info.name} id="name" defaultValue={info.name} />
              </div>
              <div>
                <div>
                  <label htmlFor="postcode">우편번호</label>
                  <input
                    name="address"
                    type="text"
                    disabled
                    id="postcode"
                    placeholder={info.postcode}
                    defaultValue={info.postcode}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      post();
                    }}>
                    주소찾기
                  </button>
                </div>
                <div>
                  <label htmlFor="roadAddress">주소</label>
                  <input
                    name="address"
                    type="text"
                    id="roadAddress"
                    disabled
                    placeholder={info.address1}
                    defaultValue={info.address1}
                  />
                </div>
                <div>
                  <label htmlFor="address2">주소</label>
                  <input
                    name="address"
                    type="text"
                    id="address2"
                    placeholder={info.address2}
                    defaultValue={info.address2}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="tel">전화번호</label>
                <input name="tel" id="tel" placeholder={info.tel} defaultValue={info.tel} />
              </div>
              <div>
                <label htmlFor="mail">이메일</label>
                <input
                  name="mail"
                  type="email"
                  id="mail"
                  placeholder={info.mail}
                  defaultValue={info.mail}
                />
              </div>
              <div>
                <button type="submit">수정</button>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/lms/pw");
                  }}>
                  비밀번호 변경
                </button>
              </div>
            </InputContainer>
          </form>
        </MainContainer>
      </Main>
    </>
  );
}

const Main = styled.div`
    height: calc(0.8375 * var(--app-height));
    background: #2581b3;
  `,
  MainContainer = styled.div`
    display: flex;
    justify-content: center;
  `,
  InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: calc(0.10063 * var(--app-height));
    width: calc(0.65 * var(--app-height));
    & > div {
      display: flex;
      font-family: Nanumsquare;
      align-items: center;
      justify-content: space-between;
      margin-top: calc(0.02604 * var(--app-height));
      & label {
        font-size: calc(0.02604 * var(--app-height));
        font-weight: 700;
        background: #ffe59d;
        color: #155175;
        border-radius: 23px;
        padding: 0 calc(0.02734 * var(--app-height)) 0 calc(0.02734 * var(--app-height));
      }
      & input {
        font-family: Nanumsquare;
        font-weight: 800;
        border-radius: 23px;
        width: calc(0.45833 * var(--app-height));
        height: calc(0.0612 * var(--app-height));
        padding: 0 calc(0.02734 * var(--app-height)) 0 calc(0.02734 * var(--app-height));
        font-size: calc(0.02604 * var(--app-height));
      }
    }
    & > :first-of-type {
      margin-top: 0;
      & > input {
        background: #e1eef3;
      }
    }
    & > :nth-of-type(3) {
      display: block;
      & > div {
        display: flex;
        font-family: Nanumsquare;
        align-items: center;
        justify-content: space-between;
        margin-top: calc(0.02604 * var(--app-height));
      }
      & > :first-of-type {
        margin-top: 0;
        & > input {
          width: calc(0.23033 * var(--app-height));
          background: #e1eef3;
        }
        & > button {
          font-size: calc(0.02604 * var(--app-height));
          font-weight: 700;
          background: #ffe59d;
          color: #155175;
          border-radius: 23px;
          padding: 5px calc(0.02734 * var(--app-height));
        }
      }
      & > :nth-of-type(2) {
        & > input {
          font-size: calc(0.01604 * var(--app-height));
          background: #e1eef3;
        }
      }
    }
    & > :last-of-type {
      & > button {
        width: 40%;
        font-size: calc(0.02604 * var(--app-height));
        background: var(--color-danger);
        color: #fff;
        border-radius: 23px;
        padding: 10px 0px;
      }
    }
  `;
