import styled from "@emotion/styled";
import axios from "axios";
import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";

export default function PrivateRoutes() {
  const [container, setContainer] = useState(<></>);

  useEffect(() => {
    axios
      .get("/api/auth/login")
      .then((res) => {
        if (res.data.user) {
          setContainer(
            <Background>
              <Outlet />
            </Background>
          );
        } else {
          setContainer(<Navigate to={"/login"} />);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return container;
}

const Background = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: var(--color-content1bg);
  flex-direction: column;
`;
