import styled from "@emotion/styled";
import SwiperComponent from "./slide";

export default function Land() {
  const videoSrc = "https://q5tztmsi917.edge.naverncp.com/video/sample/sample01.mp4";
  const images1 = [
    "../images/introduce/slide1.png",
    "../images/introduce/slide2.png",
    "../images/introduce/slide3.png",
  ];
  const images2 = [
    "../images/introduce/slide4.png",
    "../images/introduce/slide5.png",
    "../images/introduce/slide6.png",
    "../images/introduce/slide7.png",
  ];

  return (
    <div className="content">
      <Label>
        <Labelinner>
          <h1>Land시리즈</h1>
        </Labelinner>
      </Label>
      <Content>
        <HeadLine>
          외국어를 처음 공부하는 아이들을 위한 <br />
          <TextImportant>
            48단계
            <br />
          </TextImportant>
          Communicative Course
        </HeadLine>
        <VideoContainer>
          <VideoComponent>
            <video autoPlay muted loop controls width={"100%"}>
              <source src={videoSrc} type="video/mp4" />
              <strong>
                해당 브라우저에서는 영상을 볼 수 없습니다. 크롬 브라우저를 설치하세요.
              </strong>
            </video>
          </VideoComponent>
        </VideoContainer>
      </Content>
      <Content className="content_sub">
        <Container>
          <span>
            디지털 <span>학습</span>
          </span>
          <Subtitle1>
            <span>3개</span> 왕국이야기
          </Subtitle1>
          <Subtitle1>
            <span>48편</span>으로 만들어진 애니메이션
          </Subtitle1>
          <SwiperComponent
            images={images1}
            delay={2.5}
            autoplay={true}
            pagination={false}
            navigation={false}
          />
          <img src="../images/introduce/content1.png" alt="land" />
          <SwiperComponent
            images={images2}
            delay={3.5}
            autoplay={true}
            pagination={false}
            navigation={true}
          />
          <Subtitle1>
            <span>온/오프라인</span>으로 이어지는 학습 컨텐츠
          </Subtitle1>
          <img src="../images/introduce/content2.png" alt="land" />
        </Container>
      </Content>
    </div>
  );
}

const Label = styled.div`
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    background-image: url("../images/introduce/label1.png");
    background-color: var(--color-main);
    @media (max-width: 768px) {
      padding-top: 100px;
    }
  `,
  Labelinner = styled.div`
    width: 1080px;
    height: 34vw;
    max-height: 490px;
    margin: 0 auto;
    position: relative;
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    & > h1 {
      font-size: 4.4rem;
      @media (max-width: 768px) {
        font-size: 2.4rem;
      }
    }
    @media (max-width: 1080px) {
      width: 100%;
    }
  `,
  Content = styled.div`
    text-align: center;
    margin-top: 50px;
    &.content_sub {
      padding-top: 50px;
      background: #fffbe6 url("../images/introduce/sub.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:last-of-type {
      padding-bottom: 50px;
    }
  `,
  HeadLine = styled.p`
    font-family: ngsqr, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: #333;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  `,
  TextImportant = styled.span`
    color: #ff7800;
  `,
  VideoContainer = styled.div`
    background: url("../images/introduce/background.png");
    background-repeat: no-repeat;
    background-size: cover;
  `,
  VideoComponent = styled.div`
    margin: 50px auto 0;
    width: 880px;
    height: 494px;
    box-sizing: border-box;
    border: 1px solid #dedede;
    @media (max-width: 1080px) {
      width: 100%;
      height: 100%;
    }
  `,
  Container = styled.div`
    width: 50%;
    margin: 0 auto;
    background: #fff;
    padding: 30px 50px;
    border-radius: 15px;
    & > span {
      font-size: 2.4rem;
      color: #fff;
      position: relative;
      background-color: #42c3d2;
      border-radius: 15px;
      & > span {
        color: #ff7800;
      }
    }
    & > img {
      margin-top: 20px;
    }
    @media (max-width: 1080px) {
      width: 100%;
    }
  `,
  Subtitle1 = styled.h3`
    position: relative;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
    & > span {
      color: #ff7800;
    }
  `;
