import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
import { openWindowPop } from "../common/functions";

export default function VideoList() {
  return (
    <Component className="third">
      <div>
        <p>애프터스쿨은 무엇이 다른가요?</p>
        <VideoSwiper
          slidesPerView={"auto"}
          modules={[Autoplay]}
          spaceBetween={30}
          className="mySwiper"
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}>
          {["video1", "video2", "video3", "video4", "video5", "video6", "video7", "video8"].map(
            (item, index) => (
              <VideoSlider key={index}>
                <VideoComponent>
                  <img
                    src={"../images/home/" + item + ".jpg"}
                    alt=""
                    onClick={() => {
                      openWindowPop("/popup/index.html?url=" + item, "popup");
                    }}
                  />
                </VideoComponent>
              </VideoSlider>
            )
          )}
        </VideoSwiper>
        <p>
          <TextImportant>role-play</TextImportant> 학습 시스템으로 배우게 됩니다.
        </p>
      </div>
    </Component>
  );
}

const Component = styled.div`
    width: 100%;
    height: 70vh;
    box-sizing: border-box;
    &.third {
      background: url("../images/home/banner1.png");
      background-size: cover;
      background-repeat: no-repeat;
      & > div {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        & > p {
          font-family: ngsqr, sans-serif;
          font-size: 48px;
          font-weight: 600;
          line-height: 55px;
          color: #fff;
          text-align: center;
          &:first-of-type {
            margin-bottom: 20px;
          }
          &:last-of-type {
            margin-top: 40px;
          }
        }
        @media (max-width: 1024px) {
          & > p {
            font-size: 35px;
            line-height: 30px;
          }
        }
      }
    }
  `,
  VideoSwiper = styled(Swiper)`
    width: 100%;
    height: 100%;
  `,
  VideoSlider = styled(SwiperSlide)`
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    @media (max-width: 768px) {
      width: 100%;
    }
  `,
  VideoComponent = styled.div`
    margin: 20px auto;
    margin-bottom: 5px;
    box-sizing: border-box;
    & > img {
      border-radius: 15px;
    }
  `,
  TextImportant = styled.span`
    color: #ff7800;
  `;
