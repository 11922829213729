import styled from "@emotion/styled";
import { DecoratePrice } from "../common/functions";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";

export default function ProductComponent(cart: any, setCart: any) {
  const handleDelete = (index: number) => {
    let _productsArray: any = [...cart];
    axios
      .post("/api/shop/cart/delete", { productId: _productsArray[index].cartItem.productId })
      .then((res) => {
        if (res.data) {
          _productsArray.splice(index, 1);
          setCart(_productsArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CartContainer>
      {cart.map((element: any, index: number) => {
        return (
          <Container key={index}>
            <ProductContainer>
              <TextContainer>
                <TitleComponent>{element.name}</TitleComponent>
                <PriceComponent>{DecoratePrice(Number(element.price)) + "원"}</PriceComponent>
                <ButtonCancel
                  onClick={() => {
                    handleDelete(index);
                  }}
                />
              </TextContainer>
            </ProductContainer>
          </Container>
        );
      })}
    </CartContainer>
  );
}

const CartContainer = styled.div`
    width: 100%;
    max-width: 1024px;
    padding: 0 30px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: 0px 3px 9px rgb(0 0 0 / 5%);
  `,
  Container = styled.div`
    position: relative;
    border-color: #cacbd4;
    border-top: 1px solid #e9ecef;
    padding: 20px 0;
  `,
  ProductContainer = styled.div`
    position: relative;
    display: flex;
    border-color: #cacbd4;
  `,
  TextContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-left: 14px;
    padding-right: 26px;
    flex-grow: 1;
    min-width: 0;
    & > div {
      text-align: center;
      font-weight: bold;
      color: #222222;
      font-size: 14px;
    }
  `,
  TitleComponent = styled.div`
    margin: auto 0;
    margin-left: 20px;
    max-height: 44px;
    line-height: 22px;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
  `,
  PriceComponent = styled.div`
    margin: auto 0;
    margin-left: 40px;
    font-family: tahoma, 나눔고딕, "Nanum Gothic", 맑은고딕, "Malgun Gothic", 돋움, dotum, helvetica,
      "Apple SD Gothic Neo", sans-serif;
    font-size: 14px;
    line-height: 17px;
  `,
  ButtonCancel = styled(ClearIcon)`
    margin: auto 0;
    margin-left: 40px;
    line-height: 0;
    background: #fff;
    cursor: pointer;
  `;
