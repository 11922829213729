import styled from "@emotion/styled";
import Navbar from "./navber";
import SideBanner from "./SideBar";

export default function QNA() {
  //qna 페이지 시각화 함수
  const Section = styled.section`
    position: relative;
    width: 100%;
    background: var(--color-white);
    border-top: 1px solid var(--color-border1);
    min-height: 60vh;
    padding-bottom: 100px;
  `;

  return (
    <Section>
      <Navbar />
      <SideBanner></SideBanner>
    </Section>
  );
}
