import { Swiper, SwiperSlide } from "swiper/react";
import styled from "@emotion/styled";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper";

export default function SwiperComponent(props: any) {
  const images = props.images;
  const delay = props.delay;
  const autoplay = props.autoplay;
  const pagination = props.pagination;
  const navigation = props.navigation;
  const modules = [];

  if (autoplay) {
    modules.push(Autoplay);
  }
  if (pagination) {
    modules.push(Pagination);
  }
  if (navigation) {
    modules.push(Navigation);
  }

  return (
    <>
      <SwiperSlideComponent
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: delay * 1000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: pagination,
        }}
        navigation={navigation}
        modules={modules}
        className="mySwiper">
        {images.map((image: any, index: number) => {
          return (
            <SwiperSlide key={index}>
              <img src={image} alt="이미지" />
            </SwiperSlide>
          );
        })}
      </SwiperSlideComponent>
    </>
  );
}

const SwiperSlideComponent = styled(Swiper)`
  margin-top: 20px;
  border-radius: 15px;
`;
