import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import SideBanner from "./SideBar";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { DecoratePrice } from "../common/functions";

export default function PurchaseComponent(props: any) {
  //이용권/교구 페이지 시각화 함수
  const Section = styled.section`
    position: relative;
    display: flex;
    width: 100%;
    background: var(--color-white);
    border-top: 1px solid var(--color-border1);
  `;

  return (
    <Section>
      <CatalogueComponent />
      <SideBanner></SideBanner>
    </Section>
  );
}

function CatalogueComponent() {
  //카테고리 시각화 함수
  const [Itemlist, setItemlist] = useState([]);

  useEffect(() => {
    axios
      .get("/api/shop/all")
      .then((res: any) => {
        setItemlist(res.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <ItemComponent container spacing={2}>
      {Itemlist.map((element: any, index: number) => {
        if (element.discount !== null) {
          return (
            <ItemContainer item xs={4} key={index}>
              <ItemLink to={"./product?id=" + element.id}>
                <ItemImg src={element.img} alt=""></ItemImg>
                <ItemTitle>{element.name}</ItemTitle>
                <ItemPrice
                  style={{ textDecoration: "line-through", color: "gray", display: "block" }}>
                  {DecoratePrice(Number(element.price)) + element.unit}
                </ItemPrice>
                <ItemPrice style={{ display: "block" }}>
                  {DecoratePrice(Number(element.discount)) + element.unit}
                </ItemPrice>
                <ItemBtn style={{ bottom: "0" }}>구매하기</ItemBtn>
              </ItemLink>
            </ItemContainer>
          );
        } else {
          return (
            <ItemContainer item xs={4} key={index}>
              <ItemLink to={"./product?id=" + element.id}>
                <ItemImg src={element.img} alt=""></ItemImg>
                <ItemTitle>{element.name}</ItemTitle>
                <ItemPrice>{DecoratePrice(Number(element.price)) + element.unit}</ItemPrice>
                <ItemBtn>구매하기</ItemBtn>
              </ItemLink>
            </ItemContainer>
          );
        }
      })}
    </ItemComponent>
  );
}

const ItemComponent = styled(Grid)`
    padding: 5vh 0;
    position: relative;
    display: flex;
    width: var(--container-width-large);
    margin: 0 auto;
  `,
  ItemContainer = styled(Grid)`
    margin-bottom: 20px;
    & p {
      font-family: "S-CoreDream-3Light", "맑은 고딕", "Malgun Gothic", "돋움", Dotum, Helvetica,
        AppleGothic, Sans-serif;
    }
  `,
  ItemLink = styled(NavLink)`
    display: block;
    position: relative;
    height: 100%;
    color: var(--color-black);
    cursor: pointer;
    &:hover {
      & > img {
        zoom: 1.2;
      }
      & > button {
        color: var(--color-hover);
        border: 1px solid var(--color-hover);
      }
    }
  `,
  ItemImg = styled.img`
    position: relative;
    border: 1px solid var(--color-border1);
    border-radius: 20px;
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  `,
  ItemTitle = styled.p`
    color: var(--color-black);
    line-height: 1.5;
    font-size: 18px;
    margin-top: 10px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  `,
  ItemPrice = styled.span`
    font-family: "S-CoreDream-5Medium";
    letter-spacing: -1px;
    color: var(--color-black);
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 15px;
    }
  `,
  ItemBtn = styled.button`
    position: absolute;
    right: 0;
    font-size: 13px;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    line-height: 28px;
    border: 1px solid var(--color-border2);
    background: var(--color-white);
    color: var(--color-text3);
    text-align: center;
    transition: all 0.3s ease;
    @media (max-width: 1055px) {
      position: relative;
      display: block;
    }
  `;
