import styled from "@emotion/styled";
import SwiperComponent from "./slide";

export default function Aesop() {
  const videoSrc = "https://q5tztmsi917.edge.naverncp.com/video/sample/sample02.mp4";
  const images1 = [
    "../images/introduce/slide8.png",
    "../images/introduce/slide9.png",
    "../images/introduce/slide10.png",
    "../images/introduce/slide11.png",
    "../images/introduce/slide12.png",
    "../images/introduce/slide13.png",
    "../images/introduce/slide14.png",
    "../images/introduce/slide15.png",
  ];

  return (
    <div className="content">
      <Label>
        <Labelinner>
          <h1>이솝우화</h1>
        </Labelinner>
      </Label>
      <Content>
        <HeadLine>
          이솝우화를 <TextImportant>역활극</TextImportant>으로 재창작한 교육프로그램
        </HeadLine>
        <VideoContainer>
          <VideoComponent>
            <video autoPlay muted loop controls width={"100%"}>
              <source src={videoSrc} type="video/mp4" />
              <strong>
                해당 브라우저에서는 영상을 볼 수 없습니다. 크롬 브라우저를 설치하세요.
              </strong>
            </video>
          </VideoComponent>
        </VideoContainer>
      </Content>
      <Content className="content_sub">
        <Container>
          <span>
            디지털 <span>학습</span>
          </span>
          <Subtitle1>익숙한 이솝우화 이야기로 배우는 외국어</Subtitle1>
          <SwiperComponent
            images={images1}
            delay={2.5}
            autoplay={true}
            pagination={false}
            navigation={false}
          />
          <Subtitle1>추천대상</Subtitle1>
          <SubContentContainer>
            <p>
              <span>1</span>스토리를 sum up하여 retelling을 하는 연습을 하고 싶은 어린이.
            </p>
          </SubContentContainer>
          <SubContentContainer>
            <p>
              <span>2</span>짧은 회화에서 벗어나 이야기를 하는 아이로 이끌어주고 싶은 어린이.
            </p>
          </SubContentContainer>
          <SubContentContainer>
            <p>
              <span>3</span>이야기의 교훈을 파악하고 스토리를 기반으로 한 토론 연습을 시작하고 싶은
              어린이.
            </p>
          </SubContentContainer>
        </Container>
      </Content>
    </div>
  );
}

const Label = styled.div`
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    background-image: url("../images/introduce/label2.png");
    background-color: var(--color-main);
  `,
  Labelinner = styled.div`
    width: 1080px;
    height: 34vw;
    max-height: 490px;
    margin: 0 auto;
    position: relative;
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    & > h1 {
      font-size: 4.4rem;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 2.4rem;
      }
    }
    @media (max-width: 1080px) {
      width: 100%;
    }
  `,
  Content = styled.div`
    text-align: center;
    margin-top: 50px;
    &.content_sub {
      padding-top: 50px;
      background: #fffbe6 url("../images/introduce/sub.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:last-of-type {
      padding-bottom: 50px;
    }
  `,
  HeadLine = styled.p`
    font-family: ngsqr, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: #333;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  `,
  TextImportant = styled.span`
    color: #ff7800;
  `,
  VideoContainer = styled.div`
    background: url("../images/introduce/background.png");
    background-repeat: no-repeat;
    background-size: cover;
  `,
  VideoComponent = styled.div`
    margin: 50px auto 0;
    width: 880px;
    height: 494px;
    box-sizing: border-box;
    border: 1px solid #dedede;
    @media (max-width: 1080px) {
      width: 100%;
      height: 100%;
    }
  `,
  Container = styled.div`
    width: 50%;
    margin: 0 auto;
    background: #fff;
    padding: 30px 50px;
    border-radius: 15px;
    & > span {
      font-size: 2.4rem;
      color: #fff;
      position: relative;
      background-color: #42c3d2;
      border-radius: 15px;
      & > span {
        color: #ff7800;
      }
    }
    @media (max-width: 1080px) {
      width: 100%;
    }
  `,
  Subtitle1 = styled.h3`
    position: relative;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  `,
  SubContentContainer = styled.div`
    position: relative;
    padding: 10px 0;
    font-size: 1rem;
    font-weight: 600;
    span {
      color: #ff7800;
      font-size: 1.5rem;
      margin-right: 10px;
    }
  `;
