import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
import styled from "@emotion/styled";

export default function Banner() {
  const openWindowPop = () => {
    const width = window.screen.availWidth;
    const height = window.screen.availHeight;
    const options = `width=${width},height=${height}, status=no, menubar=no, toolbar=no, resizable=no`;
    window.open("../study/content/home", "_blank", options);
  };

  return (
    <Swiper
      pagination={true}
      modules={[Autoplay, Pagination]}
      loop={true}
      className="mySwiper"
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}>
      <SwiperSlide>
        <Component className="main_slide first">
          <ContentContainer>
            <TitleComponent>
              <TextComponent className="main_text with_animation left">
                언어는 학습이 아니라
                <br />
                습득이다.
              </TextComponent>
              <Link to="/lms/profile">학습컨텐츠 I 시작하기</Link>
              <button onClick={openWindowPop}>학습컨텐츠 II 시작하기</button>
            </TitleComponent>
          </ContentContainer>
        </Component>
      </SwiperSlide>
      <SwiperSlide>
        <Component className="main_slide second">
          <ContentContainer>
            <TitleComponent>
              <TextComponent className="main_text with_animation left">
                반디영어교실과
                <br />
                함께하는
                <br />
                화상영어
              </TextComponent>
              <EventComponent>이벤트1 - 전체 컨텐츠 무료제공</EventComponent>
              <EventComponent>이벤트2 - 영어 5회 무료 체험 수업</EventComponent>
              <a href="https://a.vandi.biz/">화상영어 입장하기</a>
            </TitleComponent>
          </ContentContainer>
        </Component>
      </SwiperSlide>
    </Swiper>
  );
}

const Component = styled.div`
    width: 100%;
    height: 70vh;
    box-sizing: border-box;
    &.first {
      background: url("../images/home/main1.jpg");
    }
    &.second {
      background: url("../images/home/main2.jpg");
    }
    &.main_slide {
      height: 60vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      & a {
        display: inline-block;
        position: relative;
        padding: 10px 34px;
        margin-top: 38px;
        font-weight: 700;
        font-size: 20px;
        border-radius: 28px;
        border: 1px solid #fff;
        background: #e6ddc4;
        color: black;
        @media (max-width: 768px) {
          font-size: 14px;
          padding: 12px 28px;
        }
      }
      & > div {
        @media (max-width: 768px) {
          width: 100%;
          & > div {
            width: 100%;
            padding: 0 20px;
          }
        }
      }
    }
    &.second {
      & a {
        margin-top: 10px;
      }
    }
  `,
  ContentContainer = styled.div`
    display: block;
    z-index: 1;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    text-align: right;
    position: relative;
  `,
  TitleComponent = styled.div`
    position: absolute;
    left: 0;
    width: 480px;
    text-align: left;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    & button {
      display: inline-block;
      position: relative;
      padding: 10px 34px;
      margin-top: 38px;
      font-weight: 700;
      font-size: 20px;
      border-radius: 28px;
      border: 1px solid #fff;
      background: #e6ddc4;
      color: black;
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 12px 28px;
      }
    }
  `,
  TextComponent = styled.p`
    font-family: ngsqr, sans-serif;
    position: relative;
    &.left {
      text-align: left;
    }
    &.main_text {
      font-size: 48px;
      font-weight: 600;
      line-height: 55px;
      color: #fff;
      @media (max-width: 768px) {
        font-size: 30px;
        line-height: 35px;
      }
    }
    &.with_animation {
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      animation: fadeIn 1s ease-in-out;
    }
  `,
  EventComponent = styled.p`
    margin-top: 10px;
    color: #f12020;
    &:last-of-type {
      margin-top: 0;
    }
  `;
