import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { IntroLinkComponent, SeriesComponent, ArrowComponent } from "../common/component";
import axios from "axios";

export default function IntroComponent() {
  const [list, setList] = useState([]);

  useEffect(() => {
    axios
      .get("/api/content/intro")
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Body>
      <Container id="scroll">
        <ItemlistComponent list={list} />
      </Container>
      <ArrowComponent />
    </Body>
  );
}

function ItemlistComponent(props: any) {
  const Components = props.list.map((element: any, index: number) => {
    if (element.id === 5 || element.id === 6) {
      return SeriesComponent(element, "1");
    } else {
      return IntroLinkComponent(element, "1");
    }
  });

  return <Group>{Components}</Group>;
}

const Body = styled.div`
    flex: 9;
    position: relative;
    overflow: hidden;
  `,
  Container = styled.div`
    height: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      background: hsla(0, 0%, 100%, 0.14);
    }
    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0.55);
    }
    &::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 100%, 0.55);
    }
    &::-webkit-scrollbar-track {
      background: #8c8c8c;
    }
  `,
  Group = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    width: fit-content;
    height: 100%;
    padding: 0 calc(0.09375 * var(--app-height));
  `;
