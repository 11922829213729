import styled from "@emotion/styled";
import { DecoratePrice } from "../common/functions";

/**
 * 임시 장바구니 컴포넌트
 * @param option 상품 옵션
 * @param fetchCart 선택된 상품
 * @returns
 */
export default function TotalComponent(option: [], fetchCart: any[]) {
  let TotalPrice = 0;

  // 선택된 상품의 가격을 더함
  fetchCart.forEach((item: any) => {
    option.forEach((optionItem: any) => {
      if (Number(item) === Number(optionItem.id)) {
        TotalPrice += optionItem.price;
      }
    });
  });

  return (
    <TotalContainer>
      <strong>총 상품 금액</strong>
      <TotalPricewrap>
        <em>{"총 수량" + fetchCart.length + "개"}</em>
        <strong>
          <span>{DecoratePrice(TotalPrice)}</span>원
        </strong>
      </TotalPricewrap>
    </TotalContainer>
  );
}

const TotalContainer = styled.div`
    font-size: 13px;
    line-height: 23px;
    margin-top: 26px;
    &:before,
    :after {
      display: table;
      content: "";
    }
    &:after {
      clear: both;
      margin-top: 5px;
    }
    & > strong {
      line-height: 16px;
    }
  `,
  TotalPricewrap = styled.div`
    float: right;
    & > em {
      position: relative;
      display: inline-block;
      padding-right: 18px;
      color: #999999;
      vertical-align: top;
      &:after {
        position: absolute;
        top: 50%;
        right: 8px;
        width: 1px;
        height: 12px;
        margin-top: -6px;
        background-color: #e9e9e9;
        content: "";
      }
    }
    & > strong {
      color: #a26f59;
      display: inline-block;
      margin-top: -1px;
      font-size: 18px;
      line-height: 20px;
      vertical-align: top;
      & > span {
        position: relative;
        top: 2px;
        font-size: 24px;
      }
    }
  `;
