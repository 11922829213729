import styled from "@emotion/styled";

/**
 * @param text 대표 텍스트
 * @returns
 */
export default function TitleTextComponent(text: string) {
  return (
    <TextContainer>
      <h3>{text}</h3>
    </TextContainer>
  );
}

const TextContainer = styled.div`
  margin-bottom: 15px;
  &:after {
    display: block;
    clear: both;
    content: "";
  }
`;
