import styled from "@emotion/styled";
import axios from "axios";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import SideBanner from "./SideBar";
import cartStatus from "../state/cart";
import OptionComponent from "./option";
import ImageComponent from "./image";
import TitleTextComponent from "./title";
import TotalComponent from "./total";
import fetchCartComponent from "./fetchCart";
import PayComponent from "./pay";
import describeComponent from "./describe";

export default function PurchaseComponent(props: any) {
  //이용권/교구 페이지 시각화 함수
  const Section = styled.section`
    position: relative;
    display: flex;
    width: 100%;
    background: var(--color-white);
    border-top: 1px solid var(--color-border1);
  `;

  return (
    <Section>
      <ProductComponent />
      <SideBanner></SideBanner>
    </Section>
  );
}

function ProductComponent() {
  //상품 시각화 함수
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const [product, setProduct] = useState<any>({
    product: {
      img: "",
      name: "",
      description: "",
    },
  });
  const [cart, setCart] = useRecoilState(cartStatus);
  const [fetchCart, setFetchCart] = useState<any>([]);
  const [option, setOption] = useState<any>([]);

  useEffect(() => {
    axios.all([axios.get("/api/shop/get?id=" + id), axios.get("/api/shop/option?id=" + id)]).then(
      axios.spread((res1, res2) => {
        setProduct(res1.data);
        setOption(res2.data);
      })
    );
  }, []);

  return (
    <Section>
      <Container>
        <div>{ImageComponent(product.img)}</div>
        <div>
          <ProductContainer>
            {TitleTextComponent(product.name)}
            {OptionComponent(option, fetchCart, setFetchCart)}
            {TotalComponent(option, fetchCart)}
            <div>{fetchCartComponent(option, fetchCart, setFetchCart)}</div>
            {PayComponent(cart, setCart, fetchCart, setFetchCart)}
          </ProductContainer>
        </div>
        <div>{describeComponent(option[0], product.description)}</div>
        <h3>*온라인 자료실에 추가 학습교재/교구가 있습니다.</h3>
      </Container>
    </Section>
  );
}

const Section = styled.div`
    padding: 5vh 0;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
  `,
  Container = styled.div`
    width: var(--container-width-large);
    position: relative;
    display: grid;
    margin: 0 auto;
    grid-template-areas:
      "a b"
      "c c";
    grid-template-columns: 1fr 1fr;
    @media (max-width: 768px) {
      width: var(--container-width-small);
      grid-template-areas:
        "a a"
        "b b"
        "c c";
    }
    & > div {
      width: 100%;
      grid-area: b;
      border: 1px solid #d9dbe2;
    }
    & > div:first-of-type {
      grid-area: a;
    }
    & > div:nth-of-type(2) {
      grid-area: b;
    }
    & > div:last-of-type {
      margin: 10px auto;
      width: 70%;
      grid-area: c;
      border: 0;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    //h3 position right
    & > h3 {
      position: absolute;
      bottom: -20px;
      right: 0;
      margin: 0;
      padding: 10px;
      font-size: 1.2rem;
      font-weight: 700;
      color: var(--color-white);
      background: var(--color-primary);
    }
  `,
  ProductContainer = styled.div`
    padding: 30px 30px 30px 40px;
  `;
