import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import axios from "axios";
import ProductComponent from "./product";
import { useNavigate } from "react-router-dom";
import UserInfoContainer from "./userinfo";
import TotalContainer from "./total";

export default function CartComponent() {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    name: "",
    tel: "",
    post: "",
    address1: "",
    address2: "",
  });
  const [cart, setCart] = useState([]);

  useEffect(() => {
    axios.get("/api/auth/login").then((res) => {
      if (!res.data.state) {
        navigate("/login");
      } else {
        axios
          .get("/api/shop/cart/get")
          .then((res) => {
            setCart(res.data.products); //장바구니 목록가져오기
            setUser(res.data.user);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }, []);

  return (
    <Container>
      <form method="post" action="/api/shop/authReq" acceptCharset="euc-kr" name="payForm">
        {ProductComponent(cart, setCart)}
        {UserInfoContainer(user)}
        {TotalContainer(cart)}
      </form>
    </Container>
  );
}

const Container = styled.div`
  min-height: 60vh;
  padding: 5vh 0;
`;
