import styled from "@emotion/styled";
import { useEffect, useRef } from "react";

export default function Main() {
  const banner: any = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (document.getElementById("sub_nav")) {
          if (!entry.isIntersecting) {
            document.getElementById("sub_nav")!.classList.add("fixed");
          } else {
            document.getElementById("sub_nav")!.classList.remove("fixed");
          }
        }
      });
    });

    observer.observe(banner.current);
  }, []);

  return (
    <Container id="banner" ref={banner}>
      <HeaderText>우리 아이 외국어 학습 프로그램</HeaderText>
      <ImageContainer>
        <ImageComponent src="../images/introduce/program.png" alt="main" />
      </ImageContainer>
    </Container>
  );
}

const Container = styled.div`
    padding: 80px 0 65px;
    background: #e4fafd url("../images/introduce/background.png") 110px -230px;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  HeaderText = styled.p`
    font-family: ngsqr, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: #333;
    text-align: center;
  `,
  ImageContainer = styled.p`
    margin-top: 47px;
    text-align: center;
  `,
  ImageComponent = styled.img`
    width: auto;
    margin: auto;
    @media (max-width: 810px) {
      width: 100%;
    }
  `;
