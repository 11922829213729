import styled from "@emotion/styled";
import axios from "axios";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { createRoot } from "react-dom/client";
import Menu from "../common/mypagemenu";

export default function MypageComponent() {
  const [ProfileList, setComponent] = useState([]);
  const [AddComponent, setAddComponent] = useState(<></>);

  useEffect(() => {
    let studentId: string;
    axios
      .get("/api/lms/student/check")
      .then((res) => {
        studentId = String(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/api/lms/student/all")
      .then((res) => {
        const students = res.data;
        const list = students.map((student: any, index: number) => {
          const date = dayjs(student.createdAt);
          let active: any = {
            background: "var(--color-content1bg)",
          };
          let button = <></>;
          if (Number(studentId) === Number(student.id)) {
            active = {
              background: "#9E7676",
              color: "#fff",
            };
          } else {
            button = (
              <button
                onClick={(e: any) => {
                  axios
                    .post("/api/lms/student/choice", {
                      id: student.id,
                    })
                    .then((res) => {
                      if (res.data) {
                        window.location.reload();
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}>
                사용하기
              </button>
            );
          }
          return (
            <ProfileComponent key={index} id={"profile" + index} style={active}>
              <div>{student.name}</div>
              <div>
                <span>생성일:</span>
                <span>{date.format("YYYY.MM.DD")}</span>
                <span>{button}</span>
              </div>
              <Icon>
                <EditIcon
                  onClick={() => {
                    const element = (
                      <ProfileComponent key={index}>
                        <form
                          onSubmit={(e) => {
                            handleClickEdit(e, student.id);
                          }}>
                          <input type="text" required placeholder="이름" id="name" name="name" />
                          <AddIconComponent type="submit">추가</AddIconComponent>
                        </form>
                      </ProfileComponent>
                    );
                    const container: any = document.getElementById("profile" + index);
                    const root = createRoot(container);
                    root.render(element);
                  }}
                />
              </Icon>
              <Icon>
                <ClearIcon
                  onClick={() => {
                    handleClickDelect(student.id);
                  }}
                />
              </Icon>
            </ProfileComponent>
          );
        });
        if (list.length < 3) {
          setAddComponent(
            <LastProfileComponent key="-1">
              <div>추가</div>
              <AddIconComponent
                onClick={() => {
                  setAddComponent(
                    <LastProfileComponent key="-1">
                      <form onSubmit={handleClickAdd}>
                        <input type="text" required placeholder="이름" id="name" name="name" />
                        <AddIconComponent type="submit">추가</AddIconComponent>
                      </form>
                    </LastProfileComponent>
                  );
                }}>
                <AddIcon />
              </AddIconComponent>
            </LastProfileComponent>
          );
        }
        setComponent(list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClickAdd = (e: any) => {
    const name = e.target.name.value;
    axios
      .post("/api/lms/student", {
        name,
      })
      .then((res) => {
        if (res.data) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickEdit = (e: any, id: number) => {
    e.preventDefault();
    const name = e.target.name.value;
    axios
      .put("/api/lms/student/" + id, {
        name,
      })
      .then((res) => {
        if (res.data) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickDelect = (id: number) => {
    axios
      .delete("/api/lms/student/" + id)
      .then((res) => {
        if (res.data) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Background>
      <Menu />
      <Main>
        <MainContainer>
          {ProfileList}
          {AddComponent}
        </MainContainer>
        <TextComponent>
          <p>- 학습시작 전 프로필관리에서 프로필 선택.</p>
          <p>- 한 개 아이디로 세 개 프로필 추가/삭제 가능.</p>
          <p>- 프로필은 가입자와 상관없는 학습자 정보.</p>
          <p>- 학습자 각자의 프로필로 학습시간 저장 됨.</p>
          <p>- 프로필 삭제시 기존 학습시간도 함께 삭제 됨.</p>
          <p>- 프로필 동시간대 이용 불가능.</p>
        </TextComponent>
      </Main>
    </Background>
  );
}

const Background = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background: var(--color-content1bg);
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  `,
  Main = styled.div`
    height: calc(0.8375 * var(--app-height));
    background: #2581b3;
  `,
  MainContainer = styled.div`
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-content: flex-start;
    gap: 15px;
    margin-top: 20px;
  `,
  ProfileComponent = styled.div`
    position: relative;
    border: 1px solid black;
    padding: 15px 30px;
    border-radius: 15px;
    background: var(--color-content1bg);
    font-size: 20px;
    & input {
      font-size: 20px;
      margin: auto;
      display: block;
    }
    & form {
      text-align: center;
    }
    & > div > span:last-of-type {
      position: absolute;
      right: 2%;
      & > button {
        padding: 10px;
        border-radius: 15px;
        background: var(--color-danger);
        color: #fff;
      }
    }
  `,
  Icon = styled.button`
    position: absolute;
    background: transparent;
    &:first-of-type {
      right: 9%;
      top: 10px;
    }
    &:last-of-type {
      right: 2%;
      top: 10px;
    }
  `,
  AddIconComponent = styled.button`
    background: transparent;
    font-size: 1.5rem;
  `,
  LastProfileComponent = styled.div`
    position: relative;
    padding: 15px 30px;
    border-radius: 15px;
    background: var(--color-content1bg);
    border: 1px dashed black;
    font-size: 20px;
    text-align: center;
    & input {
      font-size: 20px;
      margin: auto;
      display: block;
    }
    & form {
      text-align: center;
    }
  `,
  TextComponent = styled.div`
    width: 95%;
    margin: 40px auto;
    & > p {
      font-size: 25px;
    }
  `;
