import dayjs from "dayjs";

//숫자->금액
export function DecoratePrice(number: number) {
  return number.toLocaleString("ko-KR");
}

//날짜변환
export function ChangeDateformat(time: string) {
  return dayjs(time).format("YYYY-MM-DD");
}

export const CDN = "https://q5tztmsi917.edge.naverncp.com";
