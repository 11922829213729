import styled from "@emotion/styled";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useState } from "react";

export default function UpbtnComponent() {
  const [btn, setBtn] = useState(<></>);

  window.addEventListener("scroll", () => {
    if (!document.getElementsByTagName("header")[0]) return;
    if (window.scrollY >= document.getElementsByTagName("header")[0].scrollHeight) {
      setBtn(
        <Btn
          onClick={() => {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          }}></Btn>
      );
    } else {
      setBtn(<></>);
    }
  });

  return btn;
}

const Btn = styled(ArrowUpwardIcon)`
  width: 47px;
  height: 47px;
  border: none;
  border-radius: 50%;
  position: fixed;
  right: 6%;
  bottom: 180px;
  z-index: 99;
  cursor: pointer;
  color: #fff;
  background-color: #5682fe;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
`;
