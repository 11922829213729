import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CDN } from "./functions";

/**
 * 대카테고리 열기
 */
export function IntroLinkComponent(element: any, index: string) {
  return (
    <ContentContainer key={element.id}>
      <TitleContainer>
        <Title>{element.title}</Title>
      </TitleContainer>
      <ContentLink to={"/lms/series?mid=" + element.id}>
        <ContentButton className={"bg-c0" + index}>
          <ItemImg src={element.image}></ItemImg>
        </ContentButton>
      </ContentLink>
    </ContentContainer>
  );
}

/**
 * 시리즈 열기
 */
export function SeriesComponent(element: any, index: string) {
  const searchParams = new URLSearchParams(window.location.search);
  let mid = searchParams.get("mid");
  let sid = element.id;
  let url = "/lms/contents?mid=" + mid + "&sid=" + sid;
  if (mid === null) {
    url = "/lms/contents?mid=" + element.id;
  }
  return (
    <ContentContainer key={element.id}>
      <TitleContainer>
        <Title>{element.title}</Title>
      </TitleContainer>
      <ContentLink to={url}>
        <ContentButton className={"bg-c0" + index}>
          <ItemImg src={element.image}></ItemImg>
        </ContentButton>
      </ContentLink>
    </ContentContainer>
  );
}

/**
 * 컨텐츠 열기
 */
export function ContentComponent(element: any, index: string) {
  let LanguageComponent = <></>;
  if (element.language) {
    LanguageComponent = (
      <LanguageContainer id={"l" + element.id}>
        {element.language.map((language: any) => {
          return (
            <a
              href={
                "/learning/content/" +
                element.html +
                ".html?id=" +
                element.id +
                "&language=" +
                language
              }
              key={language}>
              <LanguageBtn>
                <LanguageImg src={CDN + "/images/intro/language/" + language + ".png"} />
              </LanguageBtn>
            </a>
          );
        })}
      </LanguageContainer>
    );
    return (
      <ContentContainer key={element.id}>
        <TitleContainer>
          <Title>{element.title}</Title>
        </TitleContainer>
        <ContentButton
          className={"bg-c0" + index}
          onClick={() => {
            const Component = document.getElementById("l" + element.id);
            if (!Component?.classList.contains("active")) {
              Component?.classList.add("active");
            } else {
              Component?.classList.remove("active");
            }
          }}>
          <ItemImg src={element.image}></ItemImg>
        </ContentButton>
        {LanguageComponent}
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer key={element.id}>
        <TitleContainer>
          <Title>{element.title}</Title>
        </TitleContainer>
        <a
          href={
            "/learning/content/" +
            element.html +
            ".html?mid=" +
            element.categoryMId +
            "&id=" +
            element.id
          }>
          <ContentButton className={"bg-c0" + index}>
            <ItemImg src={element.image}></ItemImg>
          </ContentButton>
        </a>
      </ContentContainer>
    );
  }
}

export function ArrowComponent() {
  const handleScroll = (position: boolean) => {
    // true: left, false: right
    const element: any = document.getElementById("scroll");
    element?.scrollTo({
      left: position ? element.scrollLeft - 600 : element.scrollLeft + 600,
      behavior: "smooth",
    });
  };
  return (
    <>
      <LeftArrow
        onClick={() => {
          handleScroll(true);
        }}
      />
      <RightArrow
        onClick={() => {
          handleScroll(false);
        }}
      />
    </>
  );
}

const ContentContainer = styled.div`
    margin-top: 100px;
    flex-direction: row;
    justify-content: center;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: calc(0.23125 * var(--app-height));
    &:last-of-type {
      margin-right: 0;
    }
  `,
  TitleContainer = styled.div`
    width: max-content;
    min-width: calc(0.2875 * var(--app-height));
    height: calc(0.1 * var(--app-height));
    border-radius: calc(0.045 * var(--app-height));
    padding: calc(0.03 * var(--app-height)) calc(0.025 * var(--app-height))
      calc(0.025 * var(--app-height));
    top: calc(-0.1575 * var(--app-height));
    flex-direction: column;
    background: #ffb9b9;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
  `,
  Title = styled.p`
    font-family: Recipekorea;
    color: #fff;
    font-size: calc(0.03075 * var(--app-height));
  `,
  ContentLink = styled(Link)``,
  ContentButton = styled.button`
    width: calc(0.45 * var(--app-height));
    height: calc(0.45 * var(--app-height));
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
  `,
  ItemImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
  `,
  LanguageContainer = styled.div`
    position: absolute;
    right: calc(-0.2 * var(--app-height));
    height: calc(0.45 * var(--app-height));
    display: none;
    &.active {
      display: block;
    }
  `,
  LanguageBtn = styled.button`
    width: calc(0.2 * var(--app-height));
    height: 25%;
    display: flex;
    background: var(--color-content1bg);
  `,
  LanguageImg = styled.img`
    height: 100%;
    object-fit: contain;
  `,
  LeftArrow = styled(ArrowBackIosIcon)`
    position: absolute;
    font-size: 3rem;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  `,
  RightArrow = styled(ArrowForwardIosIcon)`
    position: absolute;
    font-size: 3rem;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  `;
