import styled from "@emotion/styled";
import { DecoratePrice } from "../common/functions";

/**
 * 선택된 상품 시각화 컴포넌트
 * @param option 상품 옵션
 * @param fetchCart 선택된 상품
 * @param setFetchCart 선택된 상품 변경 함수
 * @returns
 */
export default function SelectedComponent(options: [], fetchCart: any[], setFetchCart: any) {
  //선택된 상품삭제
  const handleDelete = (index: number) => {
    return (event: React.MouseEvent) => {
      let _productsArray = [...fetchCart];
      _productsArray.splice(index, 1);
      setFetchCart(_productsArray);
    };
  };

  const productComponent = fetchCart.map((element: any, index: number) => {
    const product: any = options.find((option: any) => option.id === Number(element));
    return (
      <Selected key={index}>
        <div>
          <span>{product.name}</span>
          <ProductPrice>{DecoratePrice(product.price) + "원"}</ProductPrice>
        </div>
        <ButtonCancel onClick={handleDelete(index)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13">
            <path
              fill="#222"
              d="M8 8H7V7h1v1zm1 1H8V8h1v1zm1 1H9V9h1v1zm1 1h-1v-1h1v1zm1 1h-1v-1h1v1zm1 1h-1v-1h1v1zM6 7V6h1v1H6zM5 8V7h1v1H5zM4 9V8h1v1H4zm-1 1V9h1v1H3zm-1 1v-1h1v1H2zm-1 1v-1h1v1H1zm-1 1v-1h1v1H0zm5-8h1v1H5V5zM4 4h1v1H4V4zM3 3h1v1H3V3zM2 2h1v1H2V2zM1 1h1v1H1V1zM0 0h1v1H0V0zm8 5v1H7V5h1zm1-1v1H8V4h1zm1-1v1H9V3h1zm1-1v1h-1V2h1zm1-1v1h-1V1h1zm1-1v1h-1V0h1z"></path>
          </svg>
        </ButtonCancel>
      </Selected>
    );
  });

  return <ListProduct>{productComponent}</ListProduct>;
}

const ListProduct = styled.ul`
    margin-top: -1px;
    box-shadow: inset 0 1px 0 0 #c7c7c7, inset 0 -1px 0 0 #c7c7c7;
  `,
  Selected = styled.li`
    position: relative;
    padding: 15px 14px 15px 0;
    &:first-of-type {
      border-top: 0;
    }
  `,
  ButtonCancel = styled.button`
    width: 13px;
    height: 13px;
    position: absolute;
    right: 0;
    top: 2.5vh;
    bottom: 29px;
    line-height: 0;
    background: #fff;
  `,
  ProductPrice = styled.span`
    float: right;
  `;
