import styled from "@emotion/styled";
export function Service() {
  return (
    <Component>
      이용약관 제1장 총칙 제1조 목적 이 약관은 EPL（이피엘) 에서 제공하는 서비스 이용조건 및 절차에
      관한 사항과 기타 필요한 사항을 EPL（이피엘) 과 이용자의 권리, 의미 및 책임사항 등을 규정함을
      목적으로 합니다. 제2조 약관의 효력과 변경 (1) 이 약관은 이용자에게 공시함으로서 효력이
      발생합니다. (2) EPL（이피엘) 은 변경의 경우와 영업상 중요사유가 있을 때 약관을 변경할 수
      있으며, 변경된 약관은 전항과 같은 방법으로 효력이 발생합니다. 제3조 약관 외 준칙 이 약관에
      명시되지 않은 사항이 관계법령에 규정되어 있을 경우에는 그 규정에 따릅니다. 제2장 회원 가입과
      서비스 이용 제1조 회원의 정의 회원이란 EPL（이피엘) 에서 회원으로 적합하다고 인정하는 일반
      개인으로 본 약관에 동의하고 서비스의 회원가입 양식을 작성하고 'ID'와 '비밀번호'를 발급받은
      사람을 말합니다. 제2조 서비스 가입의 성립 (1) 서비스 가입은 이용자의 이용신청에 대한
      EPL（이피엘) 의 이용승낙과 이용자의 약관내용에 대한 동의로 성립됩니다. (2) 회원으로 가입하여
      서비스를 이용하고자 하는 희망자는 EPL（이피엘) 에서 요청하는 간단한 개인 정보를 제공해야
      합니다. (3) 이용자의 가입신청에 대하여 EPL（이피엘) 에서 승낙한 경우, EPL（이피엘) 은 회원
      ID와 기타 EPL EDU 에서 필요하다고 인정하는 내용을 이용자에게 통지합니다. (4) 가입할 때 입력한
      ID는 변경할 수 없습니다. (5) EPL（이피엘) 은 다음 각 호에 해당하는 가입신청에 대하여는
      승낙하지 않습니다. 가. 다른 사람의 명의를 사용하여 신청하였을 때 나. 본인의 실명으로 신청하지
      않았을 때 다. 가입 신청서의 내용을 허위로 기재하였을 때 라. 사회의 안녕과 질서 혹은 미풍양속을
      저해할 목적으로 신청하였을 때 제3조 서비스 이용 및 제한 (1) 서비스 이용은 회사의 업무상 또는
      기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다. (2) 전항의 서비스
      이용시간은 시스템 정기점검 등 EPL（이피엘) 에서 필요한 경우, 회원에게 사전 통지한 후 제한할 수
      있습니다. (3) 서비스 내용 중 온라인상담은 답변하는 담당자의 개인사정에 따라 1일 24시간
      서비스가 불가능 할 수도 있습니다. 제4조 서비스의 사용료 (1) 서비스는 회원으로 등록한
      일반회원중 결제를 한 정회원만이 컨텐츠를 이용하실수 있습니다. (2) 일반회원은 모든 컨텐츠를
      이용하실수 없으며, 서비스에 제한이 있습니다. 제5조 서비스의 저작권 (1) 유료 회원이 콘텐츠를
      사용하는 것은 콘텐츠 자체에 대한 저작권을 소유하는 것이 아니라 용도에 따른 사용료를 지불하고
      EPL（이피엘)로 부터 타인에게 양도할 수없는 컨텐츠 사용권을 허가 받는 것을 뜻합니다. (2) 유료
      회원은 EPL（이피엘)로 부터 허가 받은 용도를 제외하고는 콘텐츠를 복제 또는 저장할 수 없습니다.
      이로 인한 분쟁이 발생할 경우 회원이 모든 책임을 부담해야 합니다. 회원은 콘텐츠의 사용이 허가된
      제작물을 판매 또는 양도 할 수 없습니다. 제 3자에게 재차 허가 하여 사용할 수 없습니다. 제3장
      서비스 탈퇴, 재가입 및 이용 제한 제1조 서비스 탈퇴 (1) 회원이 서비스의 탈퇴를 원하면 회원
      본인이 직접 전자메일을 통해 운영자에게 해지 신청을 요청해야 합니다. 단 탈퇴시 결재된 서비스
      비용은 환불이 불가능 합니다. (2) 탈퇴 신청시 본인임을 알 수 있는 이름, 주소, ID, 전화번호,
      해지사유를 알려주면, 가입기록과 일치 여부를 확인한 후 가입을 해지합니다. (3) 탈퇴 여부는
      기존의 ID와 비밀번호로 로그인이 되지 않으면 해지된 것입니다. 제2조 서비스 재가입 (1) 제1조에
      의하여 서비스에서 탈퇴한 사용자가 재가입을 원할 경우, 회원 본인이 직접 전자메일을 통해
      운영자에게 재가입을 요청하면 됩니다. (2) 운영자에게 재가입 요청이 완료 되면, 회원이 신규로
      회원가입을 신청 하시면 됩니다. (3) 신규 ID와 비밀번호로 로그인이 되면 재가입이 이루어진
      것입니다. 제3조 서비스 이용제한 EPL（이피엘) 은 회원이 다음 사항에 해당하는 행위를 하였을
      경우, 사전통지 없이 이용계약을 해지하거나 기간을 정하여 서비스 이용을 중지할 수 있습니다. 가.
      공공 질서 및 미풍 양속에 반하는 경우 나. 범죄적 행위에 관련되는 경우 다. 국익 또는 사회적
      공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우 라. 타인의 ID 및 비밀번호를 도용한
      경우 마. 타인의 명예를 손상시키거나 불이익을 주는 경우 바. 서비스에 위해를 가하는 등 건전한
      이용을 저해하는 경우 사. 기타 관련 법령이나 EPL（이피엘) 에서 정한 이용조건에 위배되는 경우
      제4장 서비스에 관한 책임의 제한 제1조 온라인상담 (1) EPL（이피엘) 은 서비스의 회원 혹은
      사용자들의 상담내용이 상담의사와 서비스 관리자를 제외한 제3자에게 유출되지 않도록 최선을 다해
      보안을 유지하려고 노력합니다. 그러나 다음과 같은 경우에는 상담 내용 공개 및 상실에 대하여
      EPL（이피엘) 의 책임이 없습니다. 가. 사용자의 부주의로 암호가 유출되어 상담내용이 공개되는
      경우 나. 사용자가 '삭제' 기능을 사용하여 상담을 삭제하였을 경우 다. 천재지변이나 그 밖의
      EPL（이피엘) 에서 통제할 수 없는 상황에 의하여 상담내용이 공개되거나 상담내용이 상실되었을
      경우 (2) 회원이 신청한 상담에 대한 종합적이고 적절한 답변을 위하여 담당자들은 상담내용과
      답변을 참고할 수 있습니다. (3) 아래와 같은 상담을 신청하는 경우에는 온라인상담 전체 또는 일부
      제공하지 않을 수 있습니다. 가. 같은 내용의 상담을 반복하여 신청하는 경우 나. 상식에 어긋나는
      표현을 사용하여 상담을 신청하는 경우 제2조 정보 서비스 (1) EPL（이피엘) 은 서비스에서 제공된
      어떠한 문서나 상담의 내용에 대해서도 책임을 지지 않습니다. (2) EPL（이피엘) 에서는 회원에게
      제공된 어떠한 서비스의 활용, 정보, 아이디어 혹은 지시로부터 비롯하는 어떠한 손해, 상해 혹은 그
      밖의 불이익에 대한 책임을 지지 않습니다. 제5장 의무 제1조 회사의 의무 (1) EPL（이피엘) 은
      특별한 사정이 없는 한 회원이 서비스를 이용할 수 있도록 합니다. (2) EPL（이피엘) 은 이 약관에서
      정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다. (3) EPL（이피엘) 은
      회원으로부터 소정의 절차에 의해 제기되는 의견에 대해서 적절한 절차를 거쳐 처리하며, 처리시
      일정기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야 합니다. 제2조 회원정보 보안의
      의무 (1) 회원의 ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다. (2) 회원은 서비스의
      일부로 보내지는 서비스의 전자우편을 받는 것에 동의합니다. (3) 자신의 ID가 부정하게 사용된
      경우, 회원은 반드시 EPL（이피엘) 에 그 사실을 통보해야 합니다. (4) EPL EDU 은 개인의 신분
      확인이 가능한 정보를 회원 혹은 사용자의 사전허락 없이 EPL（이피엘) 과 관계가 없는 제3자에게
      팔거나 제공하지 않습니다. 그러나 EPL（이피엘) 은 자발적으로 등록된 정보를 다음과 같은 경우에
      활용할 수 있습니다. 가. 회원들에게 유용한 새 기능, 정보, 서비스 개발에 필요한 정보를
      개발자들에게 제공하는 경우 나. 광고주들에게 서비스 회원과 사용자 집단에 대한 통계적(결코 회원
      개개인의 신분이 드러나지 않는) 정보를 제공하는 경우 다. 회원과 사용자 선호에 따른 광고 또는
      서비스를 실시하기 위하여 회사에서 사용하는 경우 (5) 게시판 등의 커뮤니케이션 공간(이하
      커뮤니케이션 공간)에 개인신분 확인이 가능한 정보(사용자 이름, ID, e-mail 주소 등)가 자발적으로
      공개될 수 있습니다. 이런 경우 공개된 정보가 제3자에 의해 수집되고, 연관되어, 사용될 수 있으며
      제3자로부터 원하지 않는 메시지를 받을 수도 있습니다. 제3자의 그러한 행위는 EPL EDU 에서 통제할
      수 없습니다. 따라서 EPL EDU 은 EPL EDU 에서 통제할 수 없는 방법에 의한 회원정보의 발견
      가능성에 대해 아무런 보장을 하지 않습니다. (6) EPL（이피엘) 은 서비스의 사용의 편의를 위하여
      Cookie 기술을 사용할 수 있습니다. Cookie란 다시 방문하는 사용자를 파악하고 그 사용자의 계속된
      접속과 개인화된 서비스 제공을 돕기 위해 웹사이트가 사용하는 작은 텍스트 파일입니다. 일반적으로
      Cookie는 Cookie를 부여한 사이트 밖에서는 의미가 없는 유일한 번호를 사용자에게 부여하는
      방식으로 작동합니다. Cookie는 사용자의 시스템 내부로 침입하지 않으며 사용자의 파일에 위험하지
      않습니다. EPL EDU 은 서비스의 광고주나 관련있는 제3자가 Cookie를 사용하는 것을 막을 수
      없습니다. 회원 혹은 사용자가 Cookie를 사용한 정보수집을 원하지 않는 경우에는 웹 브라우저에서
      Cookie를 받아들일지 여부를 조절할 수 있습니다. 하지만 서비스(특히, 개인화된 정보)가 제대로
      작동하기 위해서는 Cookie의 사용이 필요할 수 있습니다. (7) EPL（이피엘) 은 회원의 정보를 서비스
      또는 회사와 업무제휴 업체간에 상호 제공/활용할 수 있습니다. 제6장 분쟁조정 (1) 본 이용약관에
      규정된 것을 제외하고 발생하는 서비스 이용에 관한 제반문제에 관한 분쟁은 최대한 쌍방합의에 의해
      해결하도록 한다. (2) 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 EPL（이피엘) 의
      소재지를 관할하는 법원을 관할법원으로 합니다. 부칙 이 약관은 2017년 08월 01일 시행합니다.
    </Component>
  );
}

export function Privacy() {
  return (
    <Component>
      개인정보취급방침 "EPL（이피엘)"은 (이하 '회사'는) 고객님의 개인정보를 중요시하며, "정보통신망
      이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여
      고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보취급방침을 개정하는 경우 웹사이트
      공지사항(또는 개별공지)을 통하여 공지할 것입니다. ο 본 방침은 : 2017년 08월 01일 부터
      시행됩니다. ■ 수집하는 개인정보 항목 회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와
      같은 개인정보를 수집하고 있습니다. ο 수집항목 : 이름 , 주소, 휴대전화번호 , 이메일 , 전화번호
      ,서비스 이용기록 , 접속 로그 , 접속 IP 정보 , 결제기록 ο 개인정보 수집방법 :
      홈페이지(회원가입) ■ 개인정보의 수집 및 이용목적 회사는 수집한 개인정보를 다음의 목적을 위해
      활용합니다. ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공 , 구매
      및 요금 결제 , 금융거래 본인 인증 및 금융 서비스 ο 회원 관리 회원제 서비스 이용에 따른
      본인확인 , 개인 식별 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 불만처리 등 민원처리 ,
      고지사항 전달 ο 마케팅 및 광고에 활용 신규 서비스(제품) 개발 및 특화 ■ 개인정보의 보유 및
      이용기간 회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이
      파기합니다. 단 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이
      관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. -보존 항목: 이름, 로그인ID,
      비밀번호, 연락처, 주소 -보존 근거: 전자상거래등에서의 소비자보호에 관한 법률 -보존 기간: 5년
      -계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래등에서의 소비자보호에 관한 법률) -대금결제
      및 재화 등의 공급에 관한 기록: 5년 (전자상거래등에서의 소비자보호에 관한 법률) -소비자의 불만
      또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률) ■ 개인정보의
      파기절차 및 방법 회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다. ο 파기절차 회원님이 회원가입 등을
      위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침
      및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후
      파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의
      다른 목적으로 이용되지 않습니다. ο 파기방법 - 전자적 파일형태로 저장된 개인정보는 기록을
      재생할 수 없는 기술적 방법을 사용하여 삭제합니다. - 종이에 출력된 개인정보는 분쇄기로
      분쇄하거나 소각을 통하여 파기합니다. ■ 개인정보 제공 회사는 이용자의 개인정보를 원칙적으로
      외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다. - 이용자들이 사전에 동의한 경우
      - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가
      있는 경우 ■ 수집한 개인정보의 위탁 회사는 고객님의 동의없이 고객님의 정보를 외부 업체에
      위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해
      고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다. ■ 이용자 및 법정대리인의 권리와
      그 행사방법 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의
      개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다. 이용자의 개인정보 조회
      수정을 위해서는 ‘개인정보변경’(또는 "회원정보수정" 등)을 가입해지(동의철회)를 위해서는
      담당자에게 본인 확인 절차를 거치신 후 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면,
      전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다. 귀하가 개인정보의 오류에 대한 정정을
      요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한
      잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여
      정정이 이루어지도록 하겠습니다. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
      개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의
      용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. ■ 개인정보 자동수집 장치의 설치, 운영 및
      그 거부에 관한 사항 쿠키 등 인터넷 서비스 이용 시 자동 생성되는 개인정보를 수집하는 장치를
      운영하지 않습니다. ■ 개인정보에 관한 민원서비스 회사는 고객의 개인정보를 보호하고 개인정보와
      관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고
      있습니다.. 개인정보관리책임자 성명 : 박한수대표 전화번호 : 031-711-1726 이메일 :
      ieafter@naver.com 귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
      개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해
      신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는
      아래 기관에 문의하시기 바랍니다. 1.개인정보침해신고센터 (www.1336.or.kr/국번없이 118)
      2.정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4) 3.대검찰청 인터넷범죄수사센터
      (http://icic.sppo.go.kr/02-3480-3600) 4.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  white-space: pre-wrap;
`;
