/* eslint-disable no-undef */
export default function nicepayStart() {
  goPay(document.payForm);
}

//chack pc or mobile
function isMobile() {
  if (navigator.userAgent.match(/Android/i)) {
    return true;
  } else if (navigator.userAgent.match(/iPhone/i)) {
    return true;
  } else if (navigator.userAgent.match(/iPad/i)) {
    return true;
  } else if (navigator.userAgent.match(/iPod/i)) {
    return true;
  } else if (navigator.userAgent.match(/BlackBerry/i)) {
    return true;
  } else if (navigator.userAgent.match(/Windows Phone/i)) {
    return true;
  } else {
    return false;
  }
}
