import styled from "@emotion/styled";
import { useState } from "react";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(["", "", ""]);

  const scrollYContent = () => {
    // if (
    //   window.scrollY >=
    //   window.pageYOffset +
    //     document.getElementsByClassName("content")![3].getBoundingClientRect().top
    // ) {
    //   setIsMenuOpen(["", "", "", "select"]);
    // } else
    if (
      window.scrollY >=
      window.pageYOffset +
        document.getElementsByClassName("content")![2].getBoundingClientRect().top
    ) {
      setIsMenuOpen(["", "", "select"]);
    } else if (
      window.scrollY >=
      window.pageYOffset +
        document.getElementsByClassName("content")![1].getBoundingClientRect().top
    ) {
      setIsMenuOpen(["", "select", ""]);
    } else if (
      window.scrollY >=
      window.pageYOffset +
        document.getElementsByClassName("content")![0].getBoundingClientRect().top
    ) {
      setIsMenuOpen(["select", "", ""]);
    } else {
      setIsMenuOpen(["", "", ""]);
    }
  };

  window.addEventListener("scroll", () => {
    if (document.getElementById("banner")) {
      scrollYContent();
    }
  });

  const handleMenuClick = (i: number) => {
    document.body.scrollTop =
      window.pageYOffset +
      document.getElementsByClassName("content")![i].getBoundingClientRect().top; // For Safari
    document.documentElement.scrollTop =
      window.pageYOffset +
      document.getElementsByClassName("content")![i].getBoundingClientRect().top; // For Chrome, Firefox, IE and Opera
  };

  return (
    <Container id="sub_nav">
      <List
        className={isMenuOpen[0]}
        onClick={() => {
          handleMenuClick(0);
        }}>
        <span>랜드시리즈</span>
      </List>
      <List
        className={isMenuOpen[1]}
        onClick={() => {
          handleMenuClick(1);
        }}>
        <span>이솝우화</span>
      </List>
      <List
        className={isMenuOpen[2]}
        onClick={() => {
          handleMenuClick(2);
        }}>
        <span>애니메이션사전</span>
      </List>
    </Container>
  );
}

const Container = styled.ul`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    background: #ff7900;
    text-align: center;
    &.fixed {
      position: fixed;
      z-index: 11;
    }
  `,
  List = styled.li`
    display: inline-block;
    position: relative;
    height: 50px;
    line-height: 50px;
    padding: 0 29px;
    background: #ff7900;
    text-align: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    &.select {
      background: #41c3d2;
    }
  `;
