import styled from "@emotion/styled";
import SideBar from "../common/SideBar";
import Banner from "./banner";
import VideoList from "./video";
import With from "./who";
import Parter from "./partner";
import How from "./how";
import Advantage from "./advantage";

export default function Main() {
  return (
    <>
      <Container>
        <Banner />
        <VideoList />
        <How />
        <Advantage />
        <With />
        <Parter />
      </Container>
      <SideBar />
    </>
  );
}

const Container = styled.div`
  height: 100%;
  & {
    background: #fff;
  }
`;
