import styled from "@emotion/styled";
import Land from "./land";
import Navbar from "./navbar";
import Main from "./main";
import Aesop from "./aesop";
import Dict from "./dict";
import SideBar from "../common/SideBar";

export default function Component() {
  return (
    <>
      <Main />
      <Container>
        <Navbar />
        <Land />
        <Aesop />
        <Dict />
      </Container>
      <SideBar />
    </>
  );
}

const Container = styled.div`
  position: relative;
`;
