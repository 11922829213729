import styled from "@emotion/styled";

export default function Component(option: any, img?: string) {
  if (option === undefined) {
    return <div></div>;
  } else if (option.productId === 1) {
    return (
      <TextComponent>
        <h2>이용권 구성(40,000원/개월)</h2>
        <h2>
          4개월: 160,000원-<span>40,000원</span>=120,000원
        </h2>
        <h2>
          8개월: 320,000원-<span>80,000원</span>=240,000원
        </h2>
        <h2>
          12개월: 480,000원-<span>120,000원</span>=360,000원
        </h2>
      </TextComponent>
    );
  } else if (option.productId === 2) {
    return (
      <TextComponent>
        <h2>이용권 구성(30,000원/개월)</h2>
        <h2>
          4개월: 120,000원-<span>40,000원</span>=80,000원
        </h2>
      </TextComponent>
    );
  } else {
    return (
      <TextComponent>
        <img src={img} alt="" />
      </TextComponent>
    );
  }
}

const TextComponent = styled.div`
  text-align: center;
  padding: 20px 0;
  & > h2 {
    & > span {
      color: var(--color-danger);
    }
  }
`;
