import styled from "@emotion/styled";
import HowComponent from "./howComponent";

export default function How() {
  return (
    <HowContainer>
      <h1>애니메이션 수업은 어떻게 해야 효과적일까요?</h1>
      <PageContainer>
        <PageList>
          {text.map((item, index) => (
            <HowComponent item={item} index={index} key={index} />
          ))}
        </PageList>
      </PageContainer>
    </HowContainer>
  );
}

const HowContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 50px 0;
    box-sizing: border-box;
    & > h1 {
      text-align: center;
      font-size: 30px;
    }
    @media (max-width: 768px) {
      padding: 25px 0;
    }
  `,
  PageContainer = styled.div`
    width: 80%;
    margin: auto;
    margin-top: 30px;
  `,
  PageList = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    justify-content: space-around;
    &:last-of-type {
      margin-top: 30px;
    }
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    & > div {
      height: 200px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      position: relative;
      cursor: pointer;
      & > p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 35px;
        font-weight: 600;
      }
      & > img {
        position: absolute;
        width: max-content;
      }
      &.left {
        text-align: left;
        background: #e5b973a0;
        & > div {
          float: right;
        }
        & > p {
          left: 40%;
        }
        & > img {
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.right {
        text-align: right;
        background: #d09cfac5;
        & > div {
          float: left;
        }
        & > p {
          left: 40%;
        }
        & > img {
          top: 50%;
          transform: translateY(-50%);
        }
        &:nth-of-type(2) {
          & > img {
            left: 10%;
          }
        }
      }
      &.on {
        height: auto;
        & > p,
        & > img {
          display: none;
        }
        & > div {
          display: block;
        }
      }
    }
  `,
  TextComponent = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;
    display: none;
    & > p {
      text-align: left;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
    }
  `;

const text = [
  {
    content: (
      <p>
        무자막 영상과 음성(영어&중국어)을 보여주세요.{" "}
        <span style={{ color: "#2146C7" }}>
          (보여준 영상에 “어떤 스토리”가 있는지 학습자에게 물어보세요.)
        </span>
      </p>
    ),
    img: "../images/home/chara1.png",
  },
  {
    content: (
      <p>
        학습자가 원어(영어&중국어)는 알아듣지 못하지만 스토리 내용을 얼마나 이해하고 있는지 관심
        있게 들어주세요.
        <br />
        <span style={{ color: "#db3f1c" }}>
          (학습자가 스토리 내용과 다른 이야기를 말해도 바로 수정하지 말아 주세요)
        </span>
      </p>
    ),
    img: "../images/home/chara2.png",
  },
  {
    content: <p>한 번 더 무자막 영상을 보여준 후 스토리 내용을 다시 물어보세요.</p>,
    img: "../images/home/chara3.png",
  },
  {
    content: (
      <p>
        영상 내용을 어느 정도 이해했으면 한글 자막을 보여주세요. <br />
        <span style={{ color: "#db3f1c" }}>
          (한글 자막을 통해서 정확한 스토리 내용을 이해할 수 있습니다)
        </span>
      </p>
    ),
    img: "../images/home/chara4.png",
  },
  {
    content: <p>한글을 읽을 줄 모르는 유아는 한국어 듣기를 하면 됩니다.</p>,
    img: "../images/home/chara5.png",
  },
  {
    content: (
      <p>
        이제 한 문장 씩 따라 하기를 하십시오. 단락보기로 가면 한 문장 씩 따라 할 수 있게 돼있고,
        녹음 기능으로 녹음해서 더빙 기능을 사용하면 케릭터에서 녹음한 목소리가 재생됩니다.
        <br />{" "}
        <span style={{ color: "#db3f1c" }}>
          (Role Play[캐릭터 활용]를 통해서 한 문장씩 따라 연습하면 더욱 효과적입니다.)
        </span>
      </p>
    ),
    img: "../images/home/chara6.png",
  },
  {
    content: (
      <p>
        Role Play는 주인공의 발음, 억양, 리듬, 감정 심지어 행동까지도 그대로 따라 하면 오감을
        자극하여 학습효과를 더욱 높일 수 있습니다.
        <br />{" "}
        <span style={{ color: "#2146C7" }}>
          (Role Play는 학습자에게 협동심과 사회성을 길러주는 좋은 학습방법입니다)
        </span>
      </p>
    ),
    img: "../images/home/chara7.png",
  },
  {
    content: (
      <p>
        따라하기 학습을 충분히 했다면 이제 컴퓨터 소리를 무음으로 해놓고 따라 해보세요.
        <br />{" "}
        <span style={{ color: "#db3f1c" }}>
          (영어&중국어 자막보고 따라 하기, 한국어 자막보고 따라 하기, 무자막으로 따라 하기 순으로
          진행하시면 됩니다.)
        </span>
      </p>
    ),
    img: "../images/home/chara8.png",
  },
];
