import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CDN } from "../common/functions";
import DownloadIcon from "@mui/icons-material/Download";

export default function MypageComponent() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  useEffect(() => {
    axios
      .get("/api/user/membership/get")
      .then((res) => {
        const user = res.data;
        const component = (element: any, index: number) => {
          return (
            <GridComponent item xs={3} key={index}>
              <div>
                <DownloadIcon />
                <a href={element.url} download>
                  {element.name}
                </a>
              </div>
            </GridComponent>
          );
        };
        axios.get("/api/reference/all").then((res: any) => {
          const array = res.data
            .map((element: any, index: number) => {
              if (user.Membership.membership === 0) return "";
              else if (user.Membership.membership === 10) {
                if (element.categoryMId === 2) {
                  return component(element, index);
                } else return "";
              } else if (user.Membership.membership === 20) {
                if (element.categoryMId === 3) {
                  return component(element, index);
                } else return "";
              } else if (user.Membership.membership === 30) {
                if (element.categoryMId === 4) {
                  return component(element, index);
                } else return "";
              } else {
                return component(element, index);
              }
            })
            .filter((element: any) => element !== "");
          array.push(
            <GridComponent item xs={3} key={-1}>
              <div>
                <DownloadIcon />
                <a
                  href="https://q5tztmsi917.edge.naverncp.com/reference/%EA%B8%B0%ED%83%80.zip"
                  download>
                  기타
                </a>
              </div>
            </GridComponent>
          );
          setList(array);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Header>
        <MenuContainer>
          <MenuButton
            onClick={() => {
              navigate("/lms/record");
            }}>
            자료실
          </MenuButton>
        </MenuContainer>
        <Close
          onClick={() => {
            navigate("/lms/intro");
          }}>
          <img src={CDN + "/images/intro/assets/close.png"} alt="menu" />
        </Close>
      </Header>
      <Main>
        <MainContainer>
          <Grid container spacing={2}>
            {list}
          </Grid>
        </MainContainer>
      </Main>
    </>
  );
}

const Header = styled.div`
    width: 100%;
    height: calc(0.1625 * var(--app-height));
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  MenuContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin-left: calc(0.0275 * var(--app-height));
    height: 100%;
  `,
  MenuButton = styled.div`
    background: #2581b3;
    color: #fff;
    border-top-left-radius: calc(0.04625 * var(--app-height));
    border-top-right-radius: calc(0.04625 * var(--app-height));
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(0.0925 * var(--app-height));
    padding: calc(0.0375 * var(--app-height));
    font-size: calc(0.02963 * var(--app-height));
    margin-right: calc(0.0125 * var(--app-height));
    font-family: NanumSquareRound;
    font-weight: 800;
    cursor: pointer;
  `,
  Close = styled.button`
    width: calc(0.11875 * var(--app-height));
    height: calc(0.11875 * var(--app-height));
    margin-right: calc(0.025 * var(--app-height));
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 2;
    display: flex;
    flex-flow: column;
    font-size: calc(0.02375 * var(--app-height));
    font-family: Nanumsquare;
    background: none;
    color: var(--color-text2);
    align-items: center;
    text-transform: uppercase;
  `,
  Main = styled.div`
    height: calc(0.8375 * var(--app-height));
    background: #2581b3;
  `,
  MainContainer = styled.div`
    width: 100%;
  `,
  GridComponent = styled(Grid)`
    text-align: center;
    margin-top: 40px;
    & > div {
      width: 60%;
      margin: 0 auto;
      background: var(--color-content1bg);
      padding: 12px 23px;
      font-size: 20px;
      border-radius: 15px;
      & > a {
        color: var(--color-text1);
      }
    }
  `;
