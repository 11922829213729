import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkMembership();
  }, []);

  const checkMembership = async () => {
    try {
      const response = await axios.get(`/api/content/membership2`);
      if (response.data.status === 0) {
        alert("로그인이 필요합니다.");
        window.close();
      } else if (response.data.status === 1) {
        alert("회원정보가 일치하지 않습니다. 다시 로그인해주세요.");
        window.close();
      } else if (response.data.status === 2) {
        alert("회원권이 없습니다.");
        navigate("/shop");
      } else if (response.data.status === 3) {
        return;
      } else if (response.data.status === 4) {
        return;
      } else if (response.data.status === 5) {
        alert("해당 회원권으로는 이용할 수 없습니다.");
        navigate("/shop");
      } else if (response.data.status === 6) {
        alert("멤버십이 만료되었습니다. 멤버십을 갱신해주세요.");
        navigate("/shop");
      } else {
        alert("오류가 발생했습니다. 로그인을 다시해주세요.");
        window.close();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Outlet />
    </>
  );
};

export default PrivateRoutes;
