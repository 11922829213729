import styled from "@emotion/styled";

/**
 * @param url 대표이미지 url
 * @returns
 */
export default function ImageComponent(url: string) {
  return (
    <ImgContainer>
      <img src={url} alt="" />
    </ImgContainer>
  );
}

const ImgContainer = styled.div`
  width: 80%;
  margin: auto;
`;
