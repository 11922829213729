import styled from "@emotion/styled";

export default function FooterComponent(props: any) {
  return (
    <Footer>
      <div style={{ background: "#89CCFF", width: "100%" }}>
        <ContainerComponent style={{ display: "block", paddingBottom: "20px" }}>
          <Info>EPL EDU | 대표 박한수</Info>
          <Info>사업자번호: 129-95-08143</Info>
          <Info>
            출판신고: 제 2006-000083호 <span>통신판매업신고: 2017-성남분당-1012</span>
          </Info>
          <Info>
            주소 : 경기도 성남시 분당구 돌마로80 현대벤처빌 713호
            <span> 대표번호 : 031-711-1726</span>
          </Info>
          <Info>copyrightⓒ2014~ EPL EDU. All Rights Reserved.</Info>
        </ContainerComponent>
      </div>
    </Footer>
  );
}

const Footer = styled.footer`
    position: relative;
    min-width: 320px;
    z-index: 12;
  `,
  ContainerComponent = styled.div`
    display: flex;
    padding: 40px 0;
    justify-content: space-between;
    width: var(--container-width-large);
    margin: 0 auto;
  `,
  Info = styled.p``;
