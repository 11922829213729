import styled from "@emotion/styled";
import SwiperComponent from "./slide";

export default function Dict() {
  const videoSrc = "https://q5tztmsi917.edge.naverncp.com/video/sample/sample03.mp4";
  const images1 = [
    "../images/introduce/slide16.png",
    "../images/introduce/slide17.png",
    "../images/introduce/slide18.png",
  ];

  return (
    <div className="content">
      <Label>
        <Labelinner>
          <h1>애니메이션 사전</h1>
        </Labelinner>
      </Label>
      <Content>
        <HeadLine>
          혹시 아이가 회화에 필요한
          <br />
          <TextImportant>1800개</TextImportant>수준의 단어를 알고 있나요?
        </HeadLine>
        <VideoContainer>
          <VideoComponent>
            <video autoPlay muted loop controls width={"100%"}>
              <source src={videoSrc} type="video/mp4" />
              <strong>
                해당 브라우저에서는 영상을 볼 수 없습니다. 크롬 브라우저를 설치하세요.
              </strong>
            </video>
          </VideoComponent>
        </VideoContainer>
      </Content>
      <Content className="content_sub">
        <Container>
          <span>
            디지털 <span>학습</span>
          </span>
          <Subtitle1>
            <span>37개</span>의 주제/상황
            <br />
            <span>1159개</span> 기본 단어와 <span>5000개</span> 확장 단어
          </Subtitle1>
          <SwiperComponent
            images={images1}
            delay={2.5}
            autoplay={true}
            pagination={false}
            navigation={false}
          />
          <Subtitle1>특징</Subtitle1>
          <SubContentContainer>
            <p>
              <span>1</span>일상생활에서 쉽게 접할 수 있는 단어를 주제별로 배웁니다.
            </p>
          </SubContentContainer>
          <SubContentContainer>
            <p>
              <span>2</span>1159개 단어와 문장을 캐릭터들과 함께 배워보세요.
            </p>
          </SubContentContainer>
        </Container>
      </Content>
    </div>
  );
}

const Label = styled.div`
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    background-image: url("../images/introduce/label3.png");
    background-color: var(--color-main);
  `,
  Labelinner = styled.div`
    width: 1080px;
    height: 34vw;
    max-height: 490px;
    margin: 0 auto;
    position: relative;
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    & > h1 {
      font-size: 4.4rem;
      text-align: right;
      @media (max-width: 768px) {
        font-size: 2.4rem;
      }
    }
    @media (max-width: 1080px) {
      width: 100%;
    }
  `,
  Content = styled.div`
    text-align: center;
    margin-top: 50px;
    &.content_sub {
      padding-top: 50px;
      background: #fffbe6 url("../images/introduce/sub.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:last-of-type {
      padding-bottom: 50px;
    }
  `,
  HeadLine = styled.p`
    font-family: ngsqr, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: #333;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  `,
  TextImportant = styled.span`
    color: #ff7800;
  `,
  VideoContainer = styled.div`
    background: url("../images/introduce/background.png");
    background-repeat: no-repeat;
    background-size: cover;
  `,
  VideoComponent = styled.div`
    margin: 50px auto 0;
    width: 880px;
    height: 494px;
    box-sizing: border-box;
    border: 1px solid #dedede;
    @media (max-width: 1080px) {
      width: 100%;
      height: 100%;
    }
  `,
  Container = styled.div`
    width: 50%;
    margin: 0 auto;
    background: #fff;
    padding: 30px 50px;
    border-radius: 15px;
    & > span {
      font-size: 2.4rem;
      color: #fff;
      position: relative;
      background-color: #42c3d2;
      border-radius: 15px;
      & > span {
        color: #ff7800;
      }
    }
    @media (max-width: 1080px) {
      width: 100%;
    }
  `,
  Subtitle1 = styled.h3`
    position: relative;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
    & > span {
      color: #ff7800;
    }
  `,
  SubContentContainer = styled.div`
    position: relative;
    padding: 10px 0;
    font-size: 1rem;
    font-weight: 600;
    span {
      color: #ff7800;
      font-size: 1.5rem;
      margin-right: 10px;
    }
  `;
